import styled, { css } from "styled-components";
import { devices } from "../../../Theme/Devices";
import Radio from "rsuite/Radio";

export const StepTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  @media ${devices.laptop} {
    padding-top: 0px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #111439;
    text-align: center;
    @media ${devices.tablet} {
      font-size: 24px;
    }
  }

  ${(props) =>
    props.classtitle &&
    css`
      justify-content: flex-start;
    `}

  ${(props) =>
    props.addpackage &&
    css`
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      span {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 50%;
      }
      @media ${devices.tablet} {
        flex-direction: row;
        justify-content: space-between;
      }
    `}
`;

//------------------------ Begin of step 1------------------------//

export const Step1AdminOnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 95%;
  margin: 0 auto;
  height: 100%;
  color: #000000;
  // border: 2px solid black;

  //tablet and desktop display
  @media ${devices.laptop} {
    width: 90%;
  }
`;

export const WelcomeCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 24px;
  padding: 16px;
  overflow: scroll;

  //tablet and desktop display
  @media ${devices.laptop} {
    width: 70%;
    gap: 32px;
    padding: 40px 16px;
  }
`;

export const WelcomeCoverTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 24px;

  //tablet and desktop display
  @media ${devices.laptop} {
    gap: 32px;
  }
`;

export const WelcomeImage = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background: ${(props) => props.background};
  background-size: contain;
  background-repeat: repeat;
  background-position: 0px;

  //tablet and desktop display
  @media ${devices.laptop} {
    width: 30%;
    display: flex;
  }
`;

export const WelcomeHeaderCover = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 24px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #111439;
    text-align: center;

    //tablet and desktop display
    @media ${devices.laptop} {
      font-size: 42px;
    }
  }
`;

export const WelcomeDescriptionCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #111439;
    //tablet and desktop display
    @media ${devices.laptop} {
      font-size: 16px;
    }
  }

  @media ${devices.laptop} {
    width: 80%;
  }

  ${(props) =>
    props.description &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      span {
        text-align: justify;
        flex-direction: column;
        @media ${devices.tablet} {
          flex-direction: row;
        }
      }
    `}
  ${(props) =>
    props.buttondescription &&
    css`
      width: 100%;
      @media ${devices.laptop} {
        width: 60%;
      }
      span {
        font-size: 12px;
      }
    `}
    button {
      background-color: transparent;
      color: #007efc;
      font-weight: 500;
    }
`;

export const WelcomeButtonCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  @media ${devices.laptop} {
    width: fit-content;
  }

  ${(props) =>
    props.welcomebuttonplay &&
    css`
      align-items: center;
      justify-content: flex-end;

      @media ${devices.laptop} {
        width: fit-content;
      }
    `}
`;

//----------------------------- end of step 1--------------------------------//

//---------------------------- Begin of step 2----------------------------//

export const Step2AdminOnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  margin: 0 auto;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 24px;
  overflow: hidden;
  overflow-y: auto;
  @media ${devices.laptop} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 28px;
    /*max-width: 80%;*/
    ${(props) =>
      props.Step8AdminOnboardingContainer &&
      css`
        align-items: center;
        justify-content: center;
      `}
  }
`;

export const RadioGroupCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  gap: 24px;
  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const CardCheckbox = styled.label`
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 0px;
  width: 300px;
  height: 130px;
  background: ${(props) => props.background};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--surface-25);
  overflow: hidden;

  @media ${devices.laptop} {
    width: 355px;
    height: 155px;
  }
`;

export const CardTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  /* border: 1px solid red; */
  height: 100%;
  padding-top: 8px;
  padding-left: 16px;
`;

export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
  width: 150px;
  height: 100%;
  position: relative;
  border-radius: 8px 70px 70px 8px;
  background: ${(props) => props.background};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -16px 0px;

  svg {
    position: relative;
    width: 60px;
    height: 60px;

    @media ${devices.laptop} {
      width: 80px;
      height: 80px;
    }
  }

  @media ${devices.laptop} {
    background-position: -35px 0px;
  }
`;

export const TutorRadioboxs = styled.input`
  border: 2px solid #ccd4dc !important;
  width: 18px;
  height: 18px;
  border-radius: 100px !important;

  .rs-radio-checker {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    min-height: 17px !important;
    min-width: 17px;
    position: relative;
    left: -1px;
    top: -1px;
    border-radius: 100px !important;
  }

  .rs-radio-wrapper {
    position: absolute;
    width: 18px;
    height: 18px;
    display: inline-block;
    left: -2px;
    top: -2px;

    border-radius: 100px !important;
  }

  .rs-radio-wrapper [type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .rs-radio-inner:before {
    width: 18px;
    height: 18px;
    border-radius: 100px;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 1px 2px;
    left: 1px;
    top: 1px;
  }

  .rs-radio-inner::after {
    opacity: 0;
  }
`;

export const TutorRadioboxsCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 4px;
`;

export const OrgRadioboxsInfoCover = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 8px;

  padding: 8px;
  /* border: 3px solid red; */

  .orgcardtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;

    color: #111439;
  }

  .orgcarddescription {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;

    color: #60627b;
  }
`;

export const Step2buttom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  /* height: 117px; */
  background: #eaeff2;
  border-radius: 12px;
`;
//---------------------------- End of step 2----------------------------//
//---------------------------- Begin of step 3----------------------------//
export const Step4AdminOnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 32px;
  ${(props) =>
    props.Step4AdminOnboardingContainerUploade &&
    css`
      gap: 16px;
      width: 100%;
      @media ${devices.tablet} {
        width: 60%;
        margin: 0 auto;
        padding: 28px;
      }
    `}
`;

export const Step4AdminOnboardingCardWrap = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const OrgSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: var(--gap-4px);
  width: var(--width-100);
`;

export const StepBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: ${(props) => props.width};
`;

//---------------------------- End of step 3----------------------------//

//---------------------------- Begin of step 4----------------------------//

export const AddClassContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

export const AddClassLeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${devices.laptop} {
    width: 55%;
  }
`;

export const AddClassRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 23, 47, 0.16);
  border-radius: 12px;
  margin-top: 20px;
  @media ${devices.laptop} {
    width: 40%;
  }
`;

export const AddClassLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 23, 47, 0.16);
  border-radius: 12px;

  ${(props) =>
    props.AddClassleftbottom &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media ${devices.laptop} {
        flex-direction: row;
      }
    `}
`;

export const ColorContainer = styled.div`
  width: 100%;
  @media ${devices.laptop} {
    width: 45%;
  }
`;

export const AddclassUnderline = styled.div`
  width: 100%;
  /* height: 150px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media ${devices.laptop} {
    flex-direction: column;
    width: 10%;
    height: 150px;
  }

  .line {
    width: 100%;
    /* height: 100%; */
    border: 1px solid var(--input-border);
    @media ${devices.laptop} {
      height: 100%;
      width: 2px;
    }
  }

  .text_line {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: var(--primary-white);
    width: var(--width-30_27px);
    height: var(--height-22px);
  }

  .text {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: var(--body-regular-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-17px);
    display: flex;
    align-items: center;
    color: var(--subText);
  }
`;

export const CheckbxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #111439;
`;

export const GradeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

//---------------------------- End of step 4----------------------------//

//---------------------------- Begin of step 7----------------------------//

export const AddPackageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  /* height: 343px; */
  border-radius: 16px;
  gap: 8px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  @media ${devices.laptop} {
    width: 40%;
  }
`;

export const CostLabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  .cost {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #111439;
  }
  .costlabel {
    font-weight: 500;
  }
`;
//---------------------------- End of step 7----------------------------//

//---------------------------- Begin of step 8----------------------------//
export const MethodPaiymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  @media ${devices.laptop} {
    width: 50%;
  }
`;
//---------------------------- End of step 8----------------------------//

//---------------------------- Begin step 9----------------------------

export const MonthlyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #111439;
  border-radius: 8px;
  width: 80%;
  @media ${devices.laptop} {
    width: 20%;
  }
`;

//---------------------------- End step 9----------------------------

//---------------------------- Begin of step 10----------------------------//

export const MemberEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  @media ${devices.laptop} {
    flex-direction: row;
    align-items: flex-end;
    width: 50%;
  }
`;

export const ButtonInvite = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  background: #f2f4f6;
  border-radius: 8px;
  span {
    font-weight: 500;
  }
`;
