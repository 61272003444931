import styled, { css } from "styled-components";


export const ButtonStyles = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font-family);
  font-weight: var(--body-semi-bold-weight);
  line-height: var(--line-height-130);
  cursor: pointer;
  gap: 8px;
  border-radius: var(--border-radius-md);
  width: ${(props) => props.width};

  // Primary Button
  ${(props) =>
    props.primarybuttom &&
    css`
      background: var(--primary-color);
      color: var(--primary-bottom-text-color);
      border: 2px solid var(--primary-color);

      &:hover {
        background-color: var(--brand-50S);
        border: 2px solid var(--brand-50S);
      }
      /* Primary Button Disableb */
      ${(props) =>
        props.primarydisabled &&
        css`
          background-color: var(--input);
          border: 2px solid var(--input);
          &:hover {
            background-color: var(--input);
            border: 2px solid var(--input);
          }
        `}
      /* Primary Medium Button */
            ${(props) =>
        props.primarymediumbuttom &&
        css`
          padding: var(--padding-button-md);
          border-radius: var(--border-radius-md);
          font-size: var(--body-md);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-16px);
            `}
        `}
                /* Primary Small Button */
            ${(props) =>
        props.primarysmallbuttom &&
        css`
          padding: var(--padding-button-sm);
          border-radius: var(--border-radius-sm);
          font-size: var(--body-sm);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-8px);
            `}
        `}
                /* Primary Large Button */
            ${(props) =>
        props.primarylargebuttom &&
        css`
          padding: var(--padding-button-lg);
          border-radius: var(--border-radius-md);
          font-size: var(--body-lg);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-16px);
            `}
        `}
                /* Primary Extra Small Button */
            ${(props) =>
        props.primaryextrasmallbuttom &&
        css`
          padding: var(--padding-button-xm);
          border-radius: var(--border-radius-sm);
          font-size: var(--body-xs);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-8px);
            `}
        `}
    `}

  // Secondary Button
  ${(props) =>
    props.secondarybuttom &&
    css`
      color: var(--primary-color);
      border: 2px solid var(--primary-color);
      background-color: var(--transparent-background);

      &:hover {
        border: 2px solid var(--brand-50S);
        color: var(--brand-50S);
      }
      /* Secondary Button Disableb */
      ${(props) =>
        props.secondarydisabled &&
        css`
          background-color: var(--input);
          border: 2px solid var(--input-border);
          color: var(--brand-50S);
          &:hover {
            background-color: var(--input);
            border: 2px solid var(--input-border);
          }
        `}
      /* Secondary Medium Button */
            ${(props) =>
        props.secondarymediumbuttom &&
        css`
          padding: var(--padding-button-md);
          border-radius: var(--border-radius-md);
          font-size: var(--body-md);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-16px);
            `}
        `}
                /* Secondary Small Button */
            ${(props) =>
        props.secondarysmallbuttom &&
        css`
          padding: var(--padding-button-sm);
          border-radius: var(--border-radius-sm);
          font-size: var(--body-sm);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-8px);
            `}
        `}
                /* Secondary Large Button */
            ${(props) =>
        props.secondarylargebuttom &&
        css`
          padding: var(--padding-button-lg);
          border-radius: var(--border-radius-md);
          font-size: var(--body-lg);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-16px);
            `}
        `}
                /* Secondary Extra Small Button */
            ${(props) =>
        props.secondaryextrasmallbuttom &&
        css`
          padding: var(--padding-button-xm);
          border-radius: var(--border-radius-sm);
          font-size: var(--body-xs);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-8px);
            `}
        `}
    `}

     // Tertiary Button
  ${(props) =>
    props.tertiarybuttom &&
    css`
      color: var(--primary-color);
      border: 2px solid var(--surface-100);
      background-color: var(--transparent-background);

      &:hover {
        color: var(--brand-50S);
      }
      /* Tertiary Button Disableb */
      ${(props) =>
        props.tertiarydisabled &&
        css`
          border: 2px solid var(--surface-100);
          color: var(--brand-50S);
          &:hover {
            background-color: var(--input);
          }
        `}
      /* Tertiary Medium Button */
            ${(props) =>
        props.tertiarymediumbuttom &&
        css`
          padding: var(--padding-button-md);

          border-radius: var(--border-radius-md);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-16px);
            `}
        `}
                /* Tertiary Small Button */
            ${(props) =>
        props.tertiarysmallbuttom &&
        css`
          padding: var(--padding-button-sm);
          border-radius: var(--border-radius-sm);
          font-size: var(--body-sm);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-8px);
            `}
        `}
                /* Tertiary Large Button */
            ${(props) =>
        props.tertiarylargebuttom &&
        css`
          padding: var(--padding-button-lg);
          border-radius: var(--border-radius-md);
          font-size: var(--body-lg);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-16px);
            `}
        `}
                /* Tertiary Extra Small Button */
            ${(props) =>
        props.tertiaryextrasmallbuttom &&
        css`
          padding: var(--padding-button-xm);
          border-radius: var(--border-radius-sm);
          font-size: var(--body-xs);
          /* Icon Only */
          ${(props) =>
            props.icononly &&
            css`
              padding: var(--spacing-8px);
            `}
        `}
    `}
`;
