import React from 'react'
import styled from 'styled-components';//Images
import hoverBackground from "./suivi.jpg";

export const CardService = (props) => {
    const { Icon, disc, title } = props;
    return (
        <Container>
            <div className='green'>
                <div className='icon'>
                    <Icon style={{ fontSize: "4rem"}}/>
                </div>
            </div>
            <h1>{title}</h1>
            <p>{disc}</p>
        </Container>
    )
}


const Container = styled.div`
    width: 100%;
    cursor: pointer;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-item: center;
    //gap: 1rem;
    //border: 2px solid red;
    text-align: center;
    padding: 1rem;
    transition: transform 500ms $ease-in-out-back, background-position 800ms $ease-in-out-back, box-shadow 500ms linear;

    .green {
        //border: 2px solid red;
        display: flex;
        justify-content: center;
        align-item: center;
    }

    .icon {
        border: 2px solid gray;
        border-radius: 100%;
        padding: 1.5rem;
    }
    
    h1{
        font-size: 1.2rem;
        //padding-bottom: 1rem;
    }

    p {
        color: gray;
    }

    :hover {
        border-radius: 20px;
        transform: scale(1.1);
        background-position: -40px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        transition: transform 500ms ease-in-out, background-position 800ms ease-in-out, box-shadow 500ms linear;
    }
    :active { 
      transform: scale(1);
      background-position: 500px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: transform 500ms ease-in-out, background-position 800ms ease-in-out, box-shadow 500ms linear;
    }

    @media (min-width: 600px) {
        width: 300px;
    }


        
`