import React from "react"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// THEME
import { GloblaStyles } from './Theme/GlobalStyle';
import ThemeProvider from "./Theme/theme";

// STYLE CSS
//import './App.css';

// SCREEN
import Error from "./Pages/Error/Error";
import Home from "./Pages/Home/Home";
import Auth from './Pages/Auth/Auth';
import Passwords from "./Pages/Auth/Passwords";
import InfoSignUp from "./Pages/Auth/InfoSignUp";
import ConfirmCode from "./Pages/Auth/ForgetPassword/ConfirmCode";
import UpdatePassword from "./Pages/Auth/ForgetPassword/UpdatePassword";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Activation from "./Pages/Dashboard/AccountActivation/Activation";
import OrganizationCreation from "./Pages/OrgCreation/OrganizationCreation"
import OrganizationListe from "./Pages/OrgCreation/SwitchOrganization/OrganizationListe";
import OrganizationCreationEtatOff from "./Pages/OrgCreation/SwitchOrganization/OrganizationCreationEtatOff";
import Profile from "./Pages/Profile/Profile";

function App() {
  const token = localStorage.getItem('token');
  return (
    <div className="cont">
      <GloblaStyles />
      <BrowserRouter >
        <ThemeProvider>
          <Routes>
            <Route exact path="*" element={<Error />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/auth" element={<Auth />} />
            <Route exact path="/Passwords" element={<Passwords />} />
            <Route exact path="/signUp" element={<InfoSignUp />} />
            <Route exact path="/forgetPassword" element={<ConfirmCode />} />
            <Route exact path="/updatePassword" element={<UpdatePassword />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/activation" element={<Activation />} />
            <Route exact path="/orgCreation" element={<OrganizationCreation />} />
            <Route exact path="/orgListe" element={<OrganizationListe />} />
            <Route exact path="/orgCreationOff" element={<OrganizationCreationEtatOff />} />
            <Route exact path="/profile" element={<Profile />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter >
    </div>
  );
}

export default App;
