import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Components
import { ButtonStyles } from "../../Components/Buttons/ButtonStyles";
import {
  InputContainer,
  InputLabel,
  Inputs,
  HintContainer,
  HintDescription,
} from "../../Components/Inputs/InputStyles";
import { Links } from "../../Components/Links/LinksStyle";
import { InputPassword } from "../../Components/InputPassword/InputPassword";

// Styles
import "./StylesAuth.css";
import {
  BodyContainer,
  LoginCard,
  BrandLogo,
  LoginHeadWrap,
  LoginHeadTitle,
  LoginHeadDescription,
  EmailLoginWrap,
  LoginFooterWrap,
  LoginFooterTop,
  LoginFooterTopTitle,
  LegalContainer,
  HalfEmailWrap,
  Spacing,
  LoginFooterTopWrap,
  SubBodyContainer
} from "./AuthStyle";

//Images
import HomeBackgroundImage from "../../assets/images/1.jpg";

//Svg
import BrandDarkSvg from "../../assets/Svg/BrandSvg";

// API
import { entete, notify, apiSignUp } from "../../Components/API/Api";

const InfoSignUp = () => {
  const navigate = useNavigate();
  const [userInfoc, setuserInfoc] = useState('');
  const [msg, setMsg] = useState('');
  const [show, setShow] = useState(false);

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  
  const handleChange = (value) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp(`(?=.*[!@#\$%\^&\*])`);
    const length = new RegExp("(?=.{8,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  const onChangeValue = (e) => {
    setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
  };

  const onChangeValuePassword = (e) => {
    setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
    handleChange(e.target.value);
  };

  const signUp = async (e) => {
    e.preventDefault();
    setShow(true)

    if(userInfoc.nom != "" && userInfoc.prenom != "" && userInfoc.email != ""  && userInfoc.password != "" ) {
      try {
        await apiSignUp.post('/', {
          nom : userInfoc.nom,
          prenom : userInfoc.prenom,
          adresse : userInfoc.adresse,
          email : userInfoc.email,
          telephone : userInfoc.telephone,
          type : 'admin',
          password : userInfoc.password
        }).then((res) => {
          notify('success', `${res.data.message}`);
          navigate('/auth', { replace: true });
          setShow(false)
        }).catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.message === "L'email existe déjà pour un utilisateur.") {
            notify('warning', "L'email existe déjà pour un utilisateur.");
          } else {
            notify('warning', "Vos informations sont incorrectes");
          }
          setShow(false)
        });
      } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (error.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(error);
            }
        } else {
            // Gérez les autres erreurs qui n'ont pas de réponse associée
            console.log(error);
        }
        setShow(false)
      }
    } else {
      notify('warning', "Les champs sont vides");
      setMsg('Ces champs sont obligatoires')
      setShow(false)
    }
  }
  return (
    <SubBodyContainer background={`url(${HomeBackgroundImage})`}>
      <BodyContainer>
        <ToastContainer 
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <LoginCard onSubmit={signUp}>
          {/* ********************Header Login Start******************** */}
          <BrandLogo>
            <BrandDarkSvg />
          </BrandLogo>
          <LoginHeadWrap>
            <LoginHeadTitle>Créer un compte BUSINESS PRO 9</LoginHeadTitle>
            <LoginHeadDescription>
              À un pas du démarrage de votre entreprise
            </LoginHeadDescription>
          </LoginHeadWrap>
          {/* ********************Header Login End******************** */}
          {/* ********************Input and Button Login Start******************** */}
          <EmailLoginWrap>
            <InputContainer>
              <InputLabel>Email</InputLabel>
              <Inputs
                width={"100%"}
                type="email"
                placeholder="Entez votre email"
                name="email"
                value={userInfoc.email}
                onChange={onChangeValue}
              />
            </InputContainer>
            <EmailLoginWrap>
              <div style={{width:"100%"}}>
                <HalfEmailWrap>
                  <InputContainer>
                    <InputLabel>Prénom</InputLabel>
                    <Inputs
                      width={"100%"}
                      type="prenom"
                      placeholder="Entez votre prénom"
                      name="prenom"
                      value={userInfoc.prenom}
                      onChange={onChangeValue}
                    />
                  </InputContainer>
                  <Spacing />
                  <InputContainer>
                    <InputLabel>Nom</InputLabel>
                    <Inputs
                      width={"100%"}
                      type="nom"
                      placeholder="Entez votre nom"
                      name="nom"
                      value={userInfoc.nom}
                      onChange={onChangeValue}
                    />
                  </InputContainer>
                </HalfEmailWrap>
              </div>
            </EmailLoginWrap>

            <InputContainer>
              <InputLabel>Téléphone</InputLabel>
              <Inputs
                width={"100%"}
                type="number"
                placeholder="Entez votre numero de téléphone"
                name="telephone"
                value={userInfoc.telephone}
                onChange={onChangeValue}
              />
            </InputContainer>

            <InputContainer>
              <InputLabel>Adresse</InputLabel>
              <Inputs
                width={"100%"}
                type="texte"
                placeholder="Entez votre adresse"
                name="adresse"
                value={userInfoc.adresse}
                onChange={onChangeValue}
              />
            </InputContainer>

            <EmailLoginWrap>
              <InputPassword
                name = "password"
                value={userInfoc.password}
                handle={onChangeValuePassword}
                lowerValidated={lowerValidated}
                upperValidated={upperValidated}
                specialValidated={specialValidated}
                lengthValidated={lengthValidated}
                numberValidated={numberValidated}
              />
            </EmailLoginWrap>
            {
              show != true ? (
                <ButtonStyles
                  width={"100%"}
                  type="submit"
                  primarybuttom
                  primarymediumbuttom
                  numberValidated
                >
                  Créer votre compte BUSINESS PRO 9
                </ButtonStyles>
              ) : (
                <ButtonStyles
                  width={"100%"}
                  primarybuttom
                  primarymediumbuttom
                  numberValidated
                  disabled
                >
                  Inscription en cours...
                </ButtonStyles>
              )
            }
          </EmailLoginWrap>
          {/* ********************Input and Button Login End******************** */}
          {/* ********************Link Get Started Login Start******************** */}
          <LoginFooterWrap>
          <LoginFooterTopWrap>
            <LoginFooterTop>
              <LoginFooterTopTitle>
                Vous avez déjà un compte BUSINESS PRO 9 ?
                <Links href="/auth">Connexion</Links>
              </LoginFooterTopTitle>
            </LoginFooterTop>
            </LoginFooterTopWrap>
          </LoginFooterWrap>
          {/* ********************Footer Information Login End******************** */}
        </LoginCard>
      </BodyContainer>
    </SubBodyContainer>
  );
};
export default InfoSignUp;
