import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Components
import { ButtonStyles } from "../../Components/Buttons/ButtonStyles";
import {
  InputContainer,
  InputLabel,
  Inputs,
  HintContainer,
  HintDescription,
} from "../../Components/Inputs/InputStyles";
import { Links, StyledNavLink } from "../../Components/Links/LinksStyle";

//Icons
import { AiFillApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";

// Styles
import "./StylesAuth.css";
import {
  BodyContainer,
  LoginCard,
  BrandLogo,
  LoginHeadWrap,
  LoginHeadTitle,
  LoginHeadDescription,
  EmailLoginWrap,
  LoginDivider,
  SocialLoginContainer,
  LoginFooterWrap,
  LoginFooterTop,
  LoginFooterTopTitle,
  LegalContainer,
  LoginFooterTopWrap,
  SubBodyContainer
} from "./AuthStyle";

//Svg
import BrandDarkSvg from "../../assets/Svg/BrandSvg";

//Images
import HomeBackgroundImage from "../../assets/images/1.jpg";

// API
import { apiAuthEmail, entete, notify } from "../../Components/API/Api";

const Auth = () => {
  const navigate = useNavigate();
  const [userInfoc, setuserInfoc] = useState('');
  const [msg, setMsg] = useState('');
  const [msgSuccess, setMsgSuccess] = useState('');
  const [show, setShow] = useState(false);

  const onChangeValue = (e) => {
    setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
  };

  const submitUserEmail = async (e) => {
    e.preventDefault();
    setShow(true);

    await apiAuthEmail.post('/', {
      entete,
      email: userInfoc.email
    }).then((res) => {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('email', res.data.data.email);
      localStorage.setItem('nom', res.data.data.nom);
      localStorage.setItem('prenom', res.data.data.prenom);
      localStorage.setItem('id', res.data.data.id);
      localStorage.setItem('type', res.data.data.type);
      localStorage.setItem('statut', res.data.data.statut);
      setShow(false);
      setMsgSuccess("L'Email authentifier. Renseignez votre mot de passe pour vous connecter")
      navigate('/Passwords', { replace: true });
    }).catch((err) => {
      setShow(false);
      console.log(err);
      setMsg("L'Email que vous avez renseigné n'existe pas")
      notify('warning', "L'email que vous avez renseigné n'existe pas. Réessayez avec un autre email qui existe.");
    });
  }


  return (
    <SubBodyContainer background={`url(${HomeBackgroundImage})`}>
      <BodyContainer>
        <ToastContainer 
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <LoginCard onSubmit={submitUserEmail}>
          {/* ----------------Header Login Start----------------- */}
          <BrandLogo>
            <BrandDarkSvg />
          </BrandLogo>
          <br />
          <LoginHeadWrap>
            <LoginHeadTitle>Connexion</LoginHeadTitle>
            <LoginHeadDescription>Salut, bon retour 👋</LoginHeadDescription>
          </LoginHeadWrap>
          {/* ----------------Header Login End---------------- */}

          {/* ----------------Input and Button Login Start---------------- */}
          <EmailLoginWrap>
            <InputContainer>
              <InputLabel>Email</InputLabel>
              <Inputs
                width={"100%"}
                type="email"
                placeholder="Entrez votre email"
                name="email"
                value={userInfoc.email}
                onChange={onChangeValue}
              />
              {msg != "" &&
                <HintContainer>
                  <HintDescription hintdescriptionerror>
                    {msg}
                  </HintDescription>
                </HintContainer>
              }
              {msgSuccess != "" &&
                <HintContainer>
                  <HintDescription hintdescriptionsuccess>
                    {msg}
                  </HintDescription>
                </HintContainer>
              }
            </InputContainer>
            {

              show != true ? (
                <ButtonStyles
                  width={"100%"}
                  primarybuttom
                  primarymediumbuttom
                  type="submit"
                >
                  Continuer avec l'Email
                </ButtonStyles>
              ) : (
                <ButtonStyles
                  width={"100%"}
                  primarybuttom
                  primarymediumbuttom
                  disabled
                >
                  Connexion en cours...
                </ButtonStyles>
              )
            }
          </EmailLoginWrap>
          {/* ----------------Input and Button Login End---------------- */}

          {/* ----------------Underline Login Start---------------- */}
          <LoginDivider>
            <div className="line"></div>
            <div className="text_line">
              <p className="text">Or</p>
            </div>
          </LoginDivider>

          {/* ----------------Underline Login End---------------- */}

          {/* ----------------Footer Information Login Start---------------- */}
          <LoginFooterWrap>
          <LoginFooterTopWrap>
            <LoginFooterTop>
              <LoginFooterTopTitle>Nouveau sur BUSINESS PRO 9?</LoginFooterTopTitle>

              <StyledNavLink to="/signUp">S'inscrire</StyledNavLink>
            </LoginFooterTop>
            </LoginFooterTopWrap>
          </LoginFooterWrap>
          {/* ----------------Footer Information Login End---------------- */}
        </LoginCard>
      </BodyContainer>
    </SubBodyContainer>
  );
};
export default Auth;
