import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import './styleprofile.css';

//Components
import Headers from '../../Components/Header/Headers';
import Footers from "../../Components/Footers/Footers";
import Iconify from '../../Components/iconify/Iconify';


//RSUITE & MATERIEL UI 
import {
  Card, Table, Stack, Paper, Avatar, Popover, Checkbox,
  TableRow, MenuItem, TableBody, TableCell, Container, Typography,
  IconButton, TableContainer, TablePagination, Box, TextField,
  Select, InputLabel, FormControl, FormControlLabel, Divider, Switch, 
  InputAdornment, CardContent, CardMedia, Button
} from '@mui/material';
import { Modal, Form } from 'rsuite';

//Images
import HomeBackgroundImage from "../../assets/images/01.jpg";
import HomeBackgroundTransparent from "../../assets/images/bgTransparent.png";
import Logo from "../../assets/images/welcomeImage.png";

//Styles
import {
  HomeContainer,
  HomeContainerLayer,
  HomeBodyContainer,
  HomeBodyContainerGreating,
  HomeBodyAppContainer,
  HomeBodyAppWrap,
  HomeBodyAppIcon,
} from "../Dashboard/HomeStyles";
import {
  EntrepriseContainer,
  contrainerListeOrg
}
  from "../Auth/AuthStyle";
import {
  StepContainerOrg,
  SubstepContainerOrg
} from '../OrgCreation/OrganizationCreationStyles';
import {
  WelcomeDescriptionCover,
  Step2AdminOnboardingContainer
} from '../../Components/Oboarding/Admin/AdminOnboardingStyle';

// API
import { entete, notify, url, apiPersonnel, apiSignUp, apiMenu } from '../../Components/API/Api';

const Profile = () => {
  const navigate = useNavigate();
  const lastname = localStorage.getItem('nom');
  const firstname = localStorage.getItem('prenom');
  const emailUser = localStorage.getItem('email');
  const id = localStorage.getItem('id');
  const typeLocal = localStorage.getItem('type');
  const [statut, setStatut] = useState(false);

  // Data Users
  const [user, setUser] = useState([]);
  const [id_upd, setId_upd] = useState('');
  const [ids, setIds] = useState(0);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [adresse, setAdresse] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const [permissionID, setPermissionID] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  // Permission
  const [permission, setPermission] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    //setOpenAdd(!openAdd)
    setNom("")
    setPrenom("")
    setAdresse("")
    setEmail("")
    setTelephone("")
    setPassword("")
    setType("")
    setShow(false)
  };
  const handleShow = () => {
    setShow(true);
  };

  // ALL MENUS
  const allMenu = async () => {
    const token = localStorage.getItem('token');
    try {
      apiMenu.get('/', {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setPermission(res.data.data);
      })
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
          console.log("Erreur 404 : Page non trouvée");
        } else if (error.response.status === 401) {
          // Gérez spécifiquement l'erreur 401
          // Affichez un message d'erreur ou effectuez d'autres actions
          console.log("Erreur 401 : Requête non autorisée");
        } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
          console.log(error);
        }
      } else {
        // Gérez les autres erreurs qui n'ont pas de réponse associée
        console.log(error);
      }
    }
  }

  const getDataById = async () => {
    const token = localStorage.getItem('token');
    try {
      if (typeLocal != "admin") {
        const { data } = await apiPersonnel.get(`/personnel/${id}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data[0]);
        setId_upd(data.data[0].user.id)
        setNom(data.data[0].user.nom)
        setPrenom(data.data[0].user.prenom)
        setAdresse(data.data[0].user.adresse)
        setEmail(data.data[0].user.email)
        setTelephone(data.data[0].user.telephone)
        setPassword(data.data[0].user.password)
        setType(data.data[0].user.type)
        setPermissionID(data.data[0].menus);
      } else {
        const { data } = await apiSignUp.get(`/${id}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setId_upd(data.data.id)
        setNom(data.data.nom)
        setPrenom(data.data.prenom)
        setAdresse(data.data.adresse)
        setEmail(data.data.email)
        setTelephone(data.data.telephone)
        setPassword(data.data.password)
        setType(data.data.type)
        //setPermissionID(data.data[0].menus); 
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  }

  const updateUser = async () => {
    // alert(ids)
    // e.preventDefault();
    const token = localStorage.getItem('token');
    setStatut(true)

    try {
      await apiPersonnel.patch(`/profile/${id}`, 
      { 
        nom,
        prenom,
        adresse,
        email,
        telephone,
        password
      },
      {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        setStatut(false)
        setNom("")
        setPrenom("")
        setAdresse("")
        setEmail("")
        setTelephone("")
        setPassword("")
        allMenu()
        getDataById()
        handleClose()
        notify('success', `${res.data.message}`);
      })
    } catch (error) { 
      setStatut(false)
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  useEffect(() => {
    allMenu();
  }, []);

  useEffect(() => {
    getDataById();
  }, [id]);


  return (
    <HomeContainer background={`url(${HomeBackgroundImage})`}>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <HomeContainerLayer background={`url(${HomeBackgroundTransparent})`}>
        <Headers />

        <EntrepriseContainer logincardtextcolor >
          <Button onClick={() => navigate('/dashboard', { replace: true })} size="small" color="primary">
            Retour
          </Button>
            <Container>
              <Card className="card">
                <CardMedia
                  className="media"
                  image={Logo}
                >
                  <div className="avatarContainer">
                    <img
                      className="avatar"
                      src={`${Logo}`}
                      alt="User Avatar"
                    />
                  </div>
                </CardMedia>
                <CardContent>
                  <div className="userDetails">
                    <Typography variant="h5" component="h2">
                      {lastname} {firstname}
                    </Typography>
                    <Typography variant="subtitle2">
                      {emailUser}
                    </Typography>
                  </div>
                </CardContent>
              </Card>

              <br />

              <Card className='detailProfile'>
                <div className='bodyprofile' style={{ width: "100%" }}>
                  <div className='information'>
                    Vos informations personnelles
                  </div>
                  <div className='containerPersonnel'>
                    <div className='containerIdentity'>
                      <div className='identity'>
                        <span style={{ fontWeight: 'bold' }}>Nom</span>
                        <span>{nom}</span>
                      </div>
                      <div className='identity'>
                        <span style={{ fontWeight: 'bold' }}>Prenom</span>
                        <span>{prenom}</span>
                      </div>
                      <div className='identity'>
                        <span style={{ fontWeight: 'bold' }}>E-mail</span>
                        <span>{email}</span>
                      </div>
                      <div className='identity'>
                        <span style={{ fontWeight: 'bold' }}>Adresse</span>
                        <span>{adresse}</span>
                      </div>
                      <div className='identity'>
                        <span style={{ fontWeight: 'bold' }}>Tel</span>
                        <span>{telephone}</span>
                      </div>
                      <div className='identity'>
                        <span style={{ fontWeight: 'bold' }}>Statut</span>
                        <span>{type}</span>
                      </div>
                      {
                        permissionID.length != 0 ? (
                          <div className='identity'>
                            {
                              permissionID.map(item => (
                                <li>{item.designation}</li>
                              ))
                            }
                          </div>
                        ) : (
                          <div className='identity'>
                            {
                              permission.map(item => (
                                <li>{item.designation}</li>
                              ))
                            }
                          </div>
                        )
                      }
                    </div>
                    <div className='containerIdentity'>
                      <NavLink onClick={handleShow} id='updProfile'>Modifier</NavLink>
                    </div>
                  </div>
                </div>
              </Card>
            </Container>
        </EntrepriseContainer>

        <Footers />
      </HomeContainerLayer>

      {/* MODAL ADD USER */}
      <Modal
        open={show}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Modifier vos informations</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form fluid onSubmit={updateUser} style={{width: "100%", backgroundColor: "#FFFFFF", color: "#000000"}}>
            <TextField
              name="nom"
              type='text'
              label="Nom"
              variant="standard"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="prenom"
              type='text'
              label="Prenom"
              variant="standard"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="adresse"
              type='text'
              label="Adresse"
              variant="standard"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="email"
              type='email'
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="telephone"
              type='number'
              label="Téléphone"
              variant="standard"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="Mot de passe"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{width: "90%", marginBottom: "20px"}}
            />

            <Modal.Footer>
              {
                statut != true ? (
                  <Button type='submit' appearance="primary">
                    Modifier
                  </Button>
                ) : (
                  <Button type='submit' appearance="primary">
                    Modification en cours...
                  </Button>
                )
              }
              <Button onClick={handleClose} appearance="subtle">
                Fermer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </HomeContainer>
  )
}
export default Profile;
