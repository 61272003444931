import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";
import SelectPicker from "rsuite/SelectPicker";
import Popover from "rsuite/Popover";
import Modal from "rsuite/Modal";
import Drawer from "rsuite/Drawer";
import { NavLink } from "react-router-dom";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  /* border: 2px solid white; */
  //desktop display
  @media ${devices.laptop} {
    padding: 16px 24px;
  }
`;

export const HeaderContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const HeaderContainerLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const OrgBrand = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  span {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    color: #3B3B3B;
    text-transform: capitalize;
  }

  @media ${devices.tablet} {
    display: flex;
    font-size: 24px;
    line-height: 32px;
  }

  @media ${devices.laptop} {
    display: flex;
    font-size: 32px;
  }
`;

export const NameOrg = styled.span`
  display: flex;
  color: #050206;
  @media ${devices.tablet} {
    display: none;
  }
`

export const OrgBrandModalBottom = styled.label`
  display: flex;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  color: #111439;
  text-transform: capitalize;
  @media ${devices.tablet} {
    font-size: 24px;
  }

  @media ${devices.laptop} {
    font-size: 32px;
  }
`;

export const OrgMobileMenu = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  background: transparent;

  svg {
    path {
      fill: #ffffff;
    }
  }

  @media ${devices.tablet} {
    display: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 4px;
  }
`;

export const OrgSelect = styled(NavLink)`
    display: flex;
    border: 1px solid #ffffff !important;
    border-radius: 4px !important;
    background: rgba(255, 255, 255, 0.3) !important;
    min-width: 24px !important;
    padding: 5px !important;
 
    font-family: var(--primary-font-family) !important;
    font-style: normal !important;
    font-weight: var(--body-regular-weight) !important;
    font-size: var(--body-xs) !important;
    color: #000000; !important;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
`;

export const SetupGuide = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px;
  cursor: pointer;
  background: transparent;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #111439;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 4px;
  }
`;

export const SetupLink = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px;
  cursor: pointer;
  background: transparent;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #111439;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 4px;
  }
`;

export const NotificationButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  cursor: pointer;
  background: transparent;
  padding: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 4px;
  }
`;

export const UserProfile = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8px);
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 5px;
  
  div {
    width: 30px;
    height: 30px;
    border: 1px solid var(--profile-background);
    @media ${devices.laptop} {
      width: 35px;
      height: 35px;
    }
  }
  svg {
    //desktop display
    @media ${devices.laptop} {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    div {
      border: 1px solid white;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-8px);
  width: var(--width-24px);
  height: var(--height-24px);
  background: var(--profile-background);
  color: var(--primary-white);
  border-radius: var(--border-radius-profile);
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: var(--body-semi-bold-weight);
  font-size: var(--label-sm);
  line-height: var(--line-height-130);

  ${(props) =>
    props.profile &&
    css`
      background: none;
      width: 45px;
      height: 45px;
      border: 1px solid #1f1d59;
      overflow: hidden;
      //desktop display
      @media ${devices.laptop} {
        width: 50px;
        height: 50px;
        font-size: var(--body-md);
      }
    `}

  //desktop display
  @media ${devices.laptop} {
    width: var(--width-40px);
    height: var(--height-40px);
    font-size: var(--body-md);
  }
`;

export const ProfileDropDownContainer = styled(Popover)`
  padding: 0px !important;
`;

export const UserProfileDropdownContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid #ccd4dc;
  background-color: transparent;
`;
export const UserProfileDropdownContainerData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
`;
export const UserProfileDropdownName = styled.p`
  font-weight: var(--body-semi-bold-weight);
  font-size: var(--body-md);
  line-height: var(--line-height-130);
  color: var(--header-text-color);
  text-transform: capitalize;
  margin: 0;
`;
export const UserProfileDropdownEmail = styled.p`
  font-weight: var(--body-regular-weight);
  font-size: var(--body-md);
  line-height: var(--line-height-130);
  color: var(--body-text-color);
  text-transform: lowercase;
  margin: 0;
`;

export const UserProfileDropdownMenuContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: #eaeff2;
    text-decoration: none;
  }
`;
export const UserProfileDropdownMenu = styled.p`
  font-weight: var(--body-regular-weight);
  font-size: var(--body-md);
  line-height: var(--line-height-130);
  color: var(--header-text-color);
  margin: 0;
`;

export const OnboardingDrawer = styled(Drawer)`
  .rs-drawer-content {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .rs-drawer-header {
    border-bottom: 1px solid #90A3B9;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row !important;
    align-items: center;
    width: 100%;
    padding: 4px 8px;

    @media ${devices.tablet} {
      padding: 2px 15px;
    }

    button{
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      color: #007efc;

      &:hover{
        color: #0041b8;
      }
    }
  }
  .rs-drawer-header .rs-drawer-header-close {
    display: none;
  }

  .rs-btn-icon.rs-btn-sm {
    display: none !important;
  }

  .rs-drawer-header:after,
  .rs-drawer-header:before {
    display: none;
  }
  .rs-progress,
  .rs-progress-icon-fail,
  .rs-progress-icon-success,
  .rs-progress-info-status {
    width: fit-content;
  }

  .rs-drawer-body {
    height: 100%;
    overflow: auto;
    padding: 0px;
    width: 100%;
    /* margin: 0 10px; */
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    // border: 3px solid blue;
    padding-top: 10px;
  }
  .rs-drawer-header + .rs-drawer-body {
    // height: calc(100% - 172px);
    height: 100%
  }

  /*.dr-footer {
    border: 1px solid green;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:1050px;
    padding: 16px;
    /* margin-top: 24px; 
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }

    .backarrow {
      svg {
        fill: #111439;
        transform: rotateY(180deg);
      }
    }

    .forwardarrow {
      svg {
        fill: white;
      }
    }
  }*/
`;

export const HelpfullTips = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #007efc;
  gap: 8px;
  background: #f2f4f6;

  svg {
    width: 16px;
    height: 16px;
    fill: #007efc;
  }
`;
