import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";

export const PersonnelForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  //border: 2px solid black
`;

export const PersonnelFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  gap: 8px;
  //border: 2px solid black;
  box-shadow: var(--cards-elevation);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(10px);
  @media ${devices.laptop} {
    width: 50%;
  }
`;