import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//Components
import Headers from '../../../Components/Header/Headers';
import Footers from "../../../Components/Footers/Footers";
import { InputContainer, Inputs, InputLabel, HintContainer, HintDescription } from '../../../Components/Inputs/InputStyles';
import { 
  CardCheckbox, 
  CheckContainer,
  RadioGroupCover, 
  OrgRadioboxsInfoCover,
  TutorRadioboxs,
  TutorRadioboxsCover,
  CardTextContainer
} from '../../../Components/Oboarding/Admin/AdminOnboardingStyle';
import { TextField, Box } from '@mui/material';

//RSUITE
import { Steps, Panel, ButtonGroup, Button, Uploader, Message, Loader, useToaster } from 'rsuite';

//Svg
import InfoCircleSvg from "../../../assets/Svg/InfoCircleSvg";
import PublicBg from "../../../assets/images/publicbg.svg";
import PrivateBg from "../../../assets/images/privatebg.svg";
import PrivateUserSvg from '../../../assets/Svg/PrivateUserSvg';
import MultipleUserSvg from '../../../assets/Svg/MultipleUsersSvg';
import DogSvg from '../../../assets/Svg/DogSvg';


//Images
import HomeBackgroundImage from "../../../assets/images/defaultHome.png";
import HomeBackgroundTransparent from "../../../assets/images/bgTransparent.png";

//Styles
import {
  HomeContainer,
  HomeContainerLayer,
  HomeBodyContainer,
  HomeBodyContainerGreating,
  HomeBodyAppContainer,
  HomeBodyAppWrap,
  HomeBodyAppIcon,
} from "../../Dashboard/HomeStyles";
import {
  EntrepriseContainer,
  EmailLoginWrap
} 
from "../../Auth/AuthStyle";
import { 
  StepContainerOrg,
  SubstepContainerOrg
} from '../OrganizationCreationStyles';
import { 
  WelcomeDescriptionCover,
  Step2AdminOnboardingContainer
} from '../../../Components/Oboarding/Admin/AdminOnboardingStyle';

// API
import { apiCreateEntreprise, entetes, notify } from '../../../Components/API/Api';

const OrganizationCreationEtatOff = () => {
  const toaster = useToaster();
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const token = localStorage.getItem('token');
  const email = localStorage.getItem('email');
  const userID = localStorage.getItem('id');
  const navigate = useNavigate();
  const [userInfoc, setuserInfoc] = useState('');
  const [msg, setMsg] = useState('');

  
  const [logo, setLogo] = useState(null);
  const [userInfo, setuserInfo] = useState({
    filepreview: null,
  });
  
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [step, setStep] = useState(0);
  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const onChangeValue = (e) => {
    setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
  };

  const createEntreprise = async (e) => {
    e.preventDefault();
    const etat = false;

    if (userInfoc.nomEntreprise) {
      const imgSize = logo && logo.size;
      if (imgSize && imgSize > 1048576) { // Vérification de la taille de l'image
        notify('warning', "La taille de l'image doit être inférieure ou égale à 1 Mo");
        return;
      }

      try {
        const formData = new FormData();
        formData.append("nomEntreprise", userInfoc.nomEntreprise);
        formData.append("secteurEntreprise", value);
        formData.append("emailEntreprise", userInfoc.emailEntreprise);
        formData.append("ifu", userInfoc.ifu);
        formData.append("rccm", userInfoc.rccm);
        formData.append("telephoneEntreprise", userInfoc.telephoneEntreprise);
        formData.append("logo", logo);
        formData.append("userID", userID);
        formData.append("etat", etat);

        await apiCreateEntreprise.post('/', formData, {
          headers: {
            entetes,
            Authorization: `Bearer ${token}`
          }
        }).then (res => {
          console.log(res.data);
          notify('success', `${res.data.message}`);
          navigate('/dashboard', { replace: true });
        }).catch((err) => {
          console.log(err);
          notify('warning', "Une erreur s'est produite lors de la création de l'entreprise. Assurez-vous que les champs sont bien renseignés");
        })
        
      } catch (error) {
        console.error(error);
        notify('', `Echec lors de la création de l'entreprise. Veuillez rééssayer plutard`)
      }
    } else {
      notify('warning', "Assurez-vous que tous le champ est bien renseigné");
      setMsg('Veuillez à bien renseigner se champs')
    }
  }
  

  return (
    <HomeContainer background={`url(${HomeBackgroundImage})`}>
      <ToastContainer 
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <HomeContainerLayer background={`url(${HomeBackgroundTransparent})`}>
        <Headers />

        <EntrepriseContainer logincardtextcolor>
          <StepContainerOrg>
            <SubstepContainerOrg substephead>
              <Steps current={step} currentStatus="error">
                <Steps.Item />
                <Steps.Item />
                <Steps.Item />
                <Steps.Item />
              </Steps>
            </SubstepContainerOrg>
            <SubstepContainerOrg substepScroll>
              <hr/>
              <Panel header={`Creer une entrepise`}>
                {
                  step === 0 && 
                  <Step2AdminOnboardingContainer>
                    <WelcomeDescriptionCover description>
                      <span>
                        Cette entreprise sera connecté en tant 
                        que <strong>{email}</strong>
                      </span>
                    </WelcomeDescriptionCover>
                    <EmailLoginWrap>
                      <InputContainer>
                        <InputLabel>Nom de l'entreprise</InputLabel>
                        <Inputs
                          width={"100%"}
                          type="texte"
                          placeholder="Entrez le nom de votre entreprise"
                          name="nomEntreprise"
                          value={userInfoc.nomEntreprise}
                          onChange={onChangeValue}
                        />
                        {msg != "" && 
                          <HintContainer>
                            <HintDescription>
                              <InfoCircleSvg/>
                              { msg }
                            </HintDescription>
                          </HintContainer>
                        }
                      </InputContainer>
                    </EmailLoginWrap>
                  </Step2AdminOnboardingContainer>
                }
                {
                  step === 1 && 
                  <Step2AdminOnboardingContainer>
                    <EmailLoginWrap>
                      <InputContainer>
                        <InputLabel>Quel est le type de votre entreprise</InputLabel>
                        <RadioGroupCover>
                          <CardCheckbox htmlFor="check1" background={"#FFD3F8"}>
                            <CheckContainer background={`url(${PublicBg})`}>
                              <MultipleUserSvg />
                            </CheckContainer>
                            <OrgRadioboxsInfoCover>
                              <TutorRadioboxsCover>
                                <TutorRadioboxs 
                                  type="radio"
                                  id="check1" 
                                  name="radioGroup"
                                  value="public"
                                  onChange={handleChange}
                                />
                              </TutorRadioboxsCover>
                              <CardTextContainer>
                                <span className="orgcardtitle">Entreprise Public</span>
                                <span className="orgcarddescription">
                                  Gérée par l'Etat ou une entité gouvernementale.
                                </span>
                              </CardTextContainer>
                            </OrgRadioboxsInfoCover>
                          </CardCheckbox>
                  
                          <CardCheckbox htmlFor="check2" background={"#DCEEFF"}>
                            <CheckContainer background={`url(${PrivateBg})`}>
                              <PrivateUserSvg />
                            </CheckContainer>
                            <OrgRadioboxsInfoCover>
                              <TutorRadioboxsCover>
                                <TutorRadioboxs 
                                  type="radio"
                                  id="check2" 
                                  name="radioGroup"
                                  value="private"
                                  onChange={handleChange}
                                />
                              </TutorRadioboxsCover>
                              <CardTextContainer>
                                <span className="orgcardtitle">Entreprise Privée</span>
                                <span className="orgcarddescription">
                                  Dont la propriété et la gestion appartiennent à 
                                  des entitées privées.
                                </span>
                              </CardTextContainer>
                            </OrgRadioboxsInfoCover>
                          </CardCheckbox>
                        </RadioGroupCover>
                      </InputContainer>
                    </EmailLoginWrap>
                  </Step2AdminOnboardingContainer>
                }
                {
                  step === 2 && 
                  <Step2AdminOnboardingContainer>
                    <WelcomeDescriptionCover description>
                      <span>
                        Nous vous aiderons à creer un profile
                        pour votre entreprise
                      </span>
                    </WelcomeDescriptionCover>
                    <EmailLoginWrap>
                      <InputContainer>
                        <InputLabel>Email de votre entreprise</InputLabel>
                        <Inputs
                          width={"100%"}
                          type="email"
                          placeholder="Entrez l'email ici"
                          name="emailEntreprise"
                          value={userInfoc.emailEntreprise}
                          onChange={onChangeValue}
                        />
                      </InputContainer>
                      <InputContainer>
                        <InputLabel>IFU</InputLabel>
                        <Inputs
                          width={"100%"}
                          type="texte"
                          placeholder="Entrez votre ifu"
                          name="ifu"
                          value={userInfoc.ifu}
                          onChange={onChangeValue}
                        />
                      </InputContainer>
                      <InputContainer>
                        <InputLabel>RCCM</InputLabel>
                        <Inputs
                          width={"100%"}
                          type="texte"
                          placeholder="Entrez votre rccm"
                          name="rccm"
                          value={userInfoc.rccm}
                          onChange={onChangeValue}
                        />
                      </InputContainer>
                      <InputContainer>
                        <InputLabel>Téléphone</InputLabel>
                        <Inputs
                          width={"100%"}
                          type="number"
                          placeholder="Entrez votre téléphone"
                          name="telephoneEntreprise"
                          value={userInfoc.telephoneEntreprise}
                          onChange={onChangeValue}
                        />
                      </InputContainer>
                    </EmailLoginWrap>
                  </Step2AdminOnboardingContainer>
                }
                {
                  step === 3 && 
                  <Step2AdminOnboardingContainer>
                    <WelcomeDescriptionCover description>
                      <span>
                        Vous avez presque terminer
                      </span>
                    </WelcomeDescriptionCover>
                    <EmailLoginWrap>
                      <InputContainer>
                        <InputLabel>Ajouter une image pour votre entreprise</InputLabel>
                        <TextField
                          name="logo"
                          type="file"
                          label="Image article"
                          variant="standard"
                          onChange={(e) => {
                            setLogo(e.target.files[0]);
                            setuserInfo({ 
                              ...userInfo, 
                              filepreview: URL.createObjectURL(e.target.files[0]) 
                            });
                          }} 
                          required
                        />
                      </InputContainer>
                    </EmailLoginWrap>
                      <Box sx={{width: "100%"}}>
                        {userInfo.filepreview !== null ? (
                          <div style={{width: "100%"}}>
                            <div style={{width: "340px", height: "80%", borderRadius: "10px", overflow: 'hidden', display: "flex", justifyContent: "center"}}>
                              <img src={userInfo.filepreview} alt='img' width="100%" height="100%"/>
                            </div>
                          </div>
                        ) : (
                          <div style={{width: "100%"}}>
                            <div style={{width: "340px", height: "80%", borderRadius: "10px", overflow: 'hidden', display: "flex", justifyContent: "center"}}>
                              <DogSvg/>
                            </div>
                          </div>
                        )}
                      </Box>
                  </Step2AdminOnboardingContainer>
                }
              </Panel>
              <hr />
              <ButtonGroup style={{display: 'flex', gap: "8px", justifyContent: 'center'}}>
                <Button onClick={onPrevious} disabled={step === 0}>
                  Retour
                </Button>
                {
                  step < 3 ? 
                  <Button onClick={onNext}>
                    Suivant
                  </Button>
                  :
                  <Button onClick={createEntreprise}>
                    Creer votre entreprise
                  </Button>
                }
              </ButtonGroup>
            </SubstepContainerOrg>
          </StepContainerOrg>
        </EntrepriseContainer>

        <Footers/>
      </HomeContainerLayer>
    </HomeContainer>
  )
}
export default OrganizationCreationEtatOff;
