import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

//Components
import Headers from '../../Components/Header/Headers';
import Clock from "../../Components/Clock/Clock";
import Greeting from "../../Components/Greeting/Greating";
import Footers from "../../Components/Footers/Footers";
import { OrgSelect, OnboardingDrawer } from '../../Components/Header/HeaderStyle';
import { OrgBrandModalBottom } from '../../Components/Header/HeaderStyle';
import { GreetingContainer } from '../../Components/Greeting/GreatingStyle';

// RSIUTE
import { Drawer } from 'rsuite';

//Styles
import {
    HomeContainer,
    HomeContainerLayer,
    HomeBodyContainer,
    HomeBodyContainerGreating,
    HomeBodyAppContainer,
    HomeBodyAppWrap,
    HomeBodyAppIcon,
} from "./HomeStyles";

//Svg
import DropInSvg from "../../assets/Svg/DropInSvg";
import TutorPortalSvg from "../../assets/Svg/TutorPortalSvg";
import DashboardSvg from "../../assets/Svg/DashboardSvg";
import ScheduleSvg from "../../assets/Svg/ScheduleSvg";
import AppsSvg from "../../assets/Svg/AppsSvg";
import ApprovisionementSvg from '../../assets/Svg/ApprovisionementSvg';
import GestionVenteSvg from '../../assets/Svg/GestionVenteSvg';
//Images
import HomeBackgroundImage from "../../assets/images/01.jpg";
import HomeBackgroundTransparent from "../../assets/images/bgTransparent.png";

// @mui 
import { Modal, Box, Skeleton } from '@mui/material';

//Screen
import TableauDeBord from '../TableauDeBord/TableauDeBord';
import Approvisionement from '../Approvisionement/Approvisionement';
import PointJournalier from '../PointJournalier/PointJournalier';
import Historique from '../Historique/Historique';
import Vente from '../Vente/Vente';
import Personnel from '../Personnel/Personnel';

// API
import { apiMenu, entete, notify, apiSignUp, apiCreateEntrepriseActif, apiPersonnel } from '../../Components/API/Api';

//----------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#eaeaea',
    border: '2px solid #000',
    borderRadius: '20%',
    boxShadow: 24,
    p: 4,
};
//----------------------------------------------------
//    background-position: center;
const Dashboard = () => {
    const token = localStorage.getItem('token');
    const typeLocal = localStorage.getItem('type');
    const idUser = localStorage.getItem('id');
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [statut, setStatut] = useState("");
    const [etat, setEtat] = useState("");
    const [menu, setMenu] = useState([]);
    const [nomEntreprise, setNomEntreprise] = useState("");

    // Modale Menu
    const [openModal, setOpenModal] = useState(null);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    // Tableau de Bord
    const [open, setOpen] = useState(false);
    const [openAppro, setOpenAppro] = useState(false);
    const [openPoint, setOpenPoint] = useState(false);
    const [openHistorique, setOpenHistorique] = useState(false);
    const [openVente, setOpenVente] = useState(false);
    const [openPersonnel, setOpenPersonnel] = useState(false);

    // Recuperer id de l'user
    const getUserById = async () => {
        try {
            if (typeLocal != "admin") {
                const { data } = await apiPersonnel.get(`/personnel/${idUser}`, {
                    headers: {
                        entete,
                        Authorization: `Bearer ${token}`
                    }
                })
                console.log(data);
                console.log(data.data);
                setStatut(data.data[0].user.statut)
                setMenu(data.data[0].menus)
                setEtat(data.data[0].entreprises[0].etat)
                setNomEntreprise(data.data[0].entreprises[0].nomEntreprise)
            } else {
                const { data } = await apiSignUp.get(`/${idUser}`, {
                    headers: {
                        entete,
                        Authorization: `Bearer ${token}`
                    }
                })
                console.log(data);
                console.log(data.data);
                setStatut(data.data.statut)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
        }
    }

    // ALL MENUS
    const allMenu = async () => {
        try {
            apiMenu.get('/', {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res.data.data)
                setMenu(res.data.data);
            })
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
        }
    }

    // Recuperer id de l'entreprise actif en fonction de l'id de l'user
    const getActifUserById = async () => {
        try {
            if (typeLocal != "admin") {
                console.log(typeLocal)
            } else {
                const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
                    headers: {
                        entete,
                        Authorization: `Bearer ${token}`
                    }
                })
                console.log(data);
                console.log(data.data);
                setEtat(data.data[0].etat)
                setNomEntreprise(data.data[0].nomEntreprise)
            }
        } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (error.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(error);
            }
        } else {
            // Gérez les autres erreurs qui n'ont pas de réponse associée
            console.log(error);
        }
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowSkeleton(false);
        }, 5000);
    
        return () => {
          clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        allMenu();
    }, []);

    useEffect(() => {
        getUserById();
        getActifUserById();
    }, [idUser]);
    
    const renderIcon = (designation) => {
        switch (designation) {
          case "Tableau De Bord":
            return <DashboardSvg />;
          case "Approvisionement":
            return <ApprovisionementSvg />;
          case "Point Journalier":
            return <ScheduleSvg />;
          case "Historique de Ventes":
            return <TutorPortalSvg />;
          case "Gestion des Ventes":
            return <GestionVenteSvg />;
          case "Gestion du personnel":
            return <DropInSvg />;
          default:
            return <DashboardSvg />;
        }
    };

    return (
        <HomeContainer background={`url(${HomeBackgroundImage})`}>
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HomeContainerLayer background={`url(${HomeBackgroundTransparent})`}>
                
                <Headers />

                <HomeBodyContainer>
                    <HomeBodyContainerGreating>
                        <Clock />
                        <Greeting />
                    </HomeBodyContainerGreating>

                    {
                        statut != true ? (
                            <HomeBodyAppContainer>
                                {
                                    showSkeleton != true ? (
                                        <OrgSelect to='/activation'>
                                            Activer votre compte et bénéficier des differentes fonctionnalité
                                        </OrgSelect>
                                    ) : (
                                        <>
                                            <Skeleton variant="circular" width={80} height={80} />
                                            <Skeleton animation="wave" style={{width: "50%"}} />
                                        </>
                                    )
                                }
                            </HomeBodyAppContainer>
                        ) : (
                            <>
                                {
                                    etat != true ? (
                                        <HomeBodyAppContainer>
                                            <HomeBodyAppWrap to="/orgCreation" dropin>
                                                <HomeBodyAppIcon >
                                                    <DropInSvg />
                                                </HomeBodyAppIcon>
                                                <span>Creer un profile pour votre entreprise</span>
                                            </HomeBodyAppWrap>
                                        </HomeBodyAppContainer>
                                    ) : (
                                        <HomeBodyAppContainer>
                                            {
                                                menu.map(item => (
                                                    <HomeBodyAppWrap 
                                                        onClick={() => {
                                                            // Déterminer quel modal doit être ouvert
                                                            const modalName = item.designation;
                                                    
                                                            // Ouvrir le modal correspondant
                                                            switch (modalName) {
                                                                case "Tableau De Bord":
                                                                    setOpen(true);
                                                                    break;
                                                                case "Approvisionement":
                                                                    setOpenAppro(true);
                                                                    break;
                                                                case "Point Journalier":
                                                                    setOpenPoint(true);
                                                                    break;
                                                                case "Historique de Ventes":
                                                                    setOpenHistorique(true);
                                                                    break;
                                                                case "Gestion des Ventes":
                                                                    setOpenVente(true);
                                                                    break;
                                                                case "Gestion du personnel":
                                                                    setOpenPersonnel(true);
                                                                    break;
                                                                }
                                                            }
                                                        } 
                                                        desktopappdisplay 
                                                        menustyleforme = {item.designation}
                                                        
                                                    >
                                                        <HomeBodyAppIcon>
                                                            {renderIcon(item.designation)}
                                                        </HomeBodyAppIcon>
                                                        <span>{item.designation}</span>
                                                    </HomeBodyAppWrap>
                                                ))
                                            }
                                            <HomeBodyAppWrap onClick={handleOpenModal} mobileappdisplay workspace>
                                                <HomeBodyAppIcon>
                                                    <AppsSvg />
                                                </HomeBodyAppIcon>
                                                <span>Menus</span>
                                            </HomeBodyAppWrap>
                                        </HomeBodyAppContainer>
                                    )
                                }
                            </>
                        )
                    }
                </HomeBodyContainer>

                <Footers/>
            </HomeContainerLayer>
            {/* MODAL MENU */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                {/*<HomeBodyAppContainer>
                        <OrgSelect to='/activation'>
                            Activer votre compte et bénéficier des differentes fonctionnalité
                        </OrgSelect>
                    </HomeBodyAppContainer>*/}

                    {/*<HomeBodyAppContainer>
                        <HomeBodyAppWrap to="/orgCreation" mobileappdisplay dropin>
                            <HomeBodyAppIcon >
                                <DropInSvg />
                            </HomeBodyAppIcon>
                            <span>Creer un profile pour votre entreprise</span>
                        </HomeBodyAppWrap>
                    </HomeBodyAppContainer>*/}
                    <HomeBodyAppContainer>
                        {
                            menu.map(item => (
                                <HomeBodyAppWrap 
                                    onClick={() => {
                                        // Déterminer quel modal doit être ouvert
                                        const modalName = item.designation;
                                
                                        // Ouvrir le modal correspondant
                                        switch (modalName) {
                                            case "Tableau De Bord":
                                                setOpen(true);
                                                handleCloseModal();
                                                break;
                                            case "Approvisionement":
                                                setOpenAppro(true);
                                                handleCloseModal();
                                                break;
                                            case "Point Journalier":
                                                setOpenPoint(true);
                                                handleCloseModal();
                                                break;
                                            case "Historique de Ventes":
                                                setOpenHistorique(true);
                                                handleCloseModal();
                                                break;
                                            case "Gestion des Ventes":
                                                setOpenVente(true);
                                                handleCloseModal();
                                                break;
                                            case "Gestion du personnel":
                                                setOpenPersonnel(true);
                                                handleCloseModal();
                                                break;
                                            }
                                        }
                                    } 
                                    mobileappdisplay 
                                    menustyleforme = {item.designation}
                                    
                                >
                                    <HomeBodyAppIcon>
                                        {renderIcon(item.designation)}
                                    </HomeBodyAppIcon>
                                    <span style={{color: "#000000"}}>{item.designation}</span>
                                </HomeBodyAppWrap>
                            ))
                        }
                    </HomeBodyAppContainer>
                </Box>
            </Modal>

            {/* ---------------------- Dashboard Onboarding Modal Start --------------------------------------------------------*/}
            <OnboardingDrawer
                open={open}
                onClose={() => setOpen(false)}
                backdrop="static"
                size="full"
                placement="bottom"
            >
                <Drawer.Header>
                    <div>
                        <OrgBrandModalBottom>
                            <label>
                                {nomEntreprise}
                            </label>
                        </OrgBrandModalBottom>
                    </div>
                    <button onClick={() => setOpen(false)}>
                        Fermer
                    </button>
                </Drawer.Header>
                <Drawer.Body>
                    <TableauDeBord/>
                </Drawer.Body>
            </OnboardingDrawer>
            {/* ---------------------- Dashboard Onboarding Modal End --------------------------------------------------------*/}
            
            {/* ---------------------- Approvisionement Onboarding Modal Start --------------------------------------------------------*/}
            <OnboardingDrawer
                open={openAppro}
                onClose={() => setOpenAppro(false)}
                backdrop="static"
                size="full"
                placement="bottom"
            >
                <Drawer.Header>
                    <div>
                        <OrgBrandModalBottom>
                            <label>
                                {nomEntreprise}
                            </label>
                        </OrgBrandModalBottom>
                    </div>
                    <button onClick={() => setOpenAppro(false)}>
                        Fermer
                    </button>
                </Drawer.Header>
                <Drawer.Body>
                    <Approvisionement/>
                </Drawer.Body>
            </OnboardingDrawer>
            {/* ---------------------- Approvisionement Onboarding Modal End --------------------------------------------------------*/}
        
            
            {/* ---------------------- Point Journalier Onboarding Modal Start --------------------------------------------------------*/}
            <OnboardingDrawer
                open={openPoint}
                onClose={() => setOpenPoint(false)}
                backdrop="static"
                size="full"
                placement="bottom"
            >
                <Drawer.Header>
                    <div>
                        <OrgBrandModalBottom>
                            <label>
                                {nomEntreprise}
                            </label>
                        </OrgBrandModalBottom>
                    </div>
                    <button onClick={() => setOpenPoint(false)}>
                        Fermer
                    </button>
                </Drawer.Header>
                <Drawer.Body>
                    <PointJournalier/>
                </Drawer.Body>
            </OnboardingDrawer>
            {/* ---------------------- Point Journalier Onboarding Modal End --------------------------------------------------------*/}

            
            {/* ---------------------- Historique de Ventes Onboarding Modal Start --------------------------------------------------------*/}
            <OnboardingDrawer
                open={openHistorique}
                onClose={() => setOpenHistorique(false)}
                backdrop="static"
                size="full"
                placement="bottom"
            >
                <Drawer.Header>
                    <div>
                        <OrgBrandModalBottom>
                            <label>
                                {nomEntreprise}
                            </label>
                        </OrgBrandModalBottom>
                    </div>
                    <button onClick={() => setOpenHistorique(false)}>
                        Fermer
                    </button>
                </Drawer.Header>
                <Drawer.Body>
                    <Historique/>
                </Drawer.Body>
            </OnboardingDrawer>
            {/* ---------------------- Historique de Ventes Onboarding Modal End --------------------------------------------------------*/}

            
            {/* ---------------------- Gestion de Ventes Onboarding Modal Start --------------------------------------------------------*/}
            <OnboardingDrawer
                open={openVente}
                onClose={() => setOpenVente(false)}
                backdrop="static"
                size="full"
                placement="bottom"
            >
                <Drawer.Header>
                    <div>
                        <OrgBrandModalBottom>
                            <label>
                                {nomEntreprise}
                            </label>
                        </OrgBrandModalBottom>
                    </div>
                    <button onClick={() => setOpenVente(false)}>
                        Fermer
                    </button>
                </Drawer.Header>
                <Drawer.Body>
                    <Vente/>
                </Drawer.Body>
            </OnboardingDrawer>
            {/* ---------------------- Gestion de Ventes Onboarding Modal End --------------------------------------------------------*/}

            
            {/* ---------------------- Dashboard Onboarding Modal Start --------------------------------------------------------*/}
            <OnboardingDrawer
                open={openPersonnel}
                onClose={() => setOpenPersonnel(false)}
                backdrop="static"
                size="full"
                placement="bottom"
            >
                <Drawer.Header>
                    <div>
                        <OrgBrandModalBottom>
                            <label>
                                {nomEntreprise}
                            </label>
                        </OrgBrandModalBottom>
                    </div>
                    <button onClick={() => setOpenPersonnel(false)}>
                        Fermer
                    </button>
                </Drawer.Header>
                <Drawer.Body>
                    <Personnel/>
                </Drawer.Body>
            </OnboardingDrawer>
            {/* ---------------------- Dashboard Onboarding Modal End --------------------------------------------------------*/}

        </HomeContainer>
    )
}
export default Dashboard;