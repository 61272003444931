import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  /* border: 2px solid white; */
  //background: #9A8174;
  background: #d5bdaf;

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //desktop display
  @media ${devices.laptop} {
    padding: 10px 24px;
  }
`;

export const HomeSupportWrap = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  gap: 8px;
  background: transparent;
  border: none;
  outline: none;

  &:hover {
        div {
          background: #111439;
          svg {
            fill: #ffffff;
          }
        }
    }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
  }

  //tablet and desktop display
  @media ${devices.tablet} {
    display: flex;
  }

  
`;

export const HomeSupportIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 48px;
  height: 48px;
  background: #ffffff;
  border-radius: 100px;

  svg {
    width: 32px;
    height: 32px;
    fill: #111439;
  }
`;

export const HomeFooterStatsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: fit-content;
`;

export const HomeFooterStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  user-select: none;

  .stats-number {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 130%;
    text-align: right;
    color: #ffffff;
   
  }

  .stats-label{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: right;
    color: #FFFFFF;
    width: 80px;
  }
`;
