import styled, { css } from "styled-components";
import { devices } from '../../Theme/Devices';
import { TextField } from "@mui/material";


export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--gap-4px);
    width: 100%;
`

export const InputLabel = styled.label`
    font-weight: var(--body-semi-bold-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-130);
    color: var(--brand-500S);
    width: var(--width-100);
`

export const Inputs = styled.input`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-16px);
    border-radius: var(--border-radius-md);
    font-weight: var(--body-semi-bold-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-130);
    border: 1px solid var(--input-border);
    color: var(--header-text-color);
    background-color: var(--transparent-background);
    width: ${props => props.width};

    &:hover {
        border: 1px solid var(--brand-hover);
    }
    &::placeholder {
        color: var(--palceholder);
        font-weight: var(--body-semi-bold-weight);
    }
    &:focus {
        border: 1px solid var(--brand-hover);
    }
    &:focus-visible {
        outline: var(--brand-hover) auto 1px;
        outline-color: var(--brand-hover);
        outline-style: auto;
        outline-width: 1px;
    }
    &:disabled {
        background-color: var(--surface-50);
        cursor: none;
        &:hover {
            border: 1px solid var(--input-border);
        }
    }

    
    /* Input Error */
    ${(props) =>
        props.inputerror && css`
            border: 1px solid var(--error-color);
            background-color: var(--system-Error-Surface);
            &:hover {
                border: 1px solid var(--error-color);
            }
            &:focus {
                border: 1px solid var(--error-color);
            }
            &:focus-visible {
                outline: var(--error-color) auto 1px;
                outline-color: var(--error-color);
                outline-style: auto;
                outline-width: 1px;
            }
            &::placeholder {
             color: transparent;
            }
        `
    }
`

export const HintContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: var(--width-100);
`

export const HintDescription = styled.p`
font-family: var(--primary-font-family);
font-style: normal;
font-weight: var(--body-regular-weight);
font-size: var(--body-xs);
line-height: var(--line-height-130);
text-align: left;
color: var(--subText);
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 4px;


 /* Hint Description Error */
 ${(props) =>
    props.hintdescriptionerror && css`
        color: var(--error-color);
    `
 }

 /* Hint Description Success */
 ${(props) =>
    props.hintdescriptionsuccess && css`
        color: green;
    `
 }
`








export const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-4px);
    width: var(--width-100);
    margin-top: 10px;
    @media ${devices.tablet} { 
        width: var(--width-100);
        /* border: 1px solid */
        ${(props) =>
            props.inputemail && css`
                width: var(--width-100);
            `
        }
    }
    /* @media ${devices.mobileS} { 
        width: var(--width-45);
        border: 1px solid
        ${(props) =>
            props.inputemail && css`
                width: var(--width-100);
            `
        }
    } */
`
export const ContainerLabel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: var(--width-100);
`



export const SelectHint = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-8px);
    margin-top: var(--spacing-5px);
`
export const SelectHintSpan = styled.span`
    font-weight: var(--body-regular-weight);
    font-size: var(--body-xs);
    line-height: var(--line-height-130);
    text-align: center;
    color: var(--brand-500S);
    /* Input Error */
    ${(props) =>
        props.inputerrorhint && css`
            color: var(--error-color);
        `
    }
`