import React from "react";

const BorderSvgO = () => {
    const svgStyle = {
        transform: 'scaleY(-1)',
        height: '50px',
        marginBottom: '-2px'
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="wd-dividerO" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" width="100%" height="50px" preserveAspectRatio="none" style={svgStyle}>
            <path className="elementor-shape-fill" display="none" d="M100,25.8V100H0V52.5c-4.9-6.8-9.5-13.8-15-19.7V101h125V20.9C106.3,22,103,23.7,100,25.8z" fill="#0B1A46"></path>
            <path className="elementor-shape-fill" d="M0,0v99c22-20.6,28.7-69.7,61.4-69.7c18.8,0,29.1,16.2,38.6,34V0L0,0z" fill="#0B1A46"></path>
        </svg>
    );
};

export default BorderSvgO;
