import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";


export const ContainerTopDashboard = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: flex-start;
  width: 100%;
  flex-wrap: wrap;
  // border: 2px solid black
`;

export const ContainerCenterDashboard = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  width: 100%;
  flex-wrap: wrap;
  // border: 2px solid red;
  padding: 4px 0px;

  .title {
    color: var(--colors-brands-brand-500-s, #111439);
    font-family: Abel;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 41.6px */
    @media ${devices.laptop} {
      font-size: 32px;
    }
  }

  .btnGenerate {
    display: flex;
    justify-content: center;
    align-item: center;
  }
`;

export const ButtonDashboard = styled.button`
  text-align: center;
  border-radius: 20px;
  background: #D0F2FF;
  font-family: Aclonica;
  font-weight: 400;
  padding: 4px 20px;
  font-size: 20px;
  font-style: normal;
  transition: 0.5s;
  //border: 2px solid black;

  &:hover {
    background-color: #D1E9FC;
  }

  //tablet and desktop display
  @media ${devices.tablet} {
    padding: 4px 10px;
  }
`;