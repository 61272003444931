import React from 'react';
import styled, { keyframes } from 'styled-components';
//HEADER
import Header from '../../Components/homeComponents/Banner/Header';
import ProfComponent from '../../Components/homeComponents/Banner/ProfComponent';
import SectionOne from '../../Components/homeComponents/Banner/SectionOne';

//SVG
import BorderSvg from '../../assets/Svg/BoderSvg';
import BorderSvgO from '../../assets/Svg/BoderSvgO';

//ICON
import { FaArrowRight } from 'react-icons/fa';

//A PROPOS
import Apropos from '../../Components/Apropos/Apropos';

//SERVICE
import Services from '../../Components/homeComponents/Service/Services';

//FOOTER
import Footer from '../../Components/homeComponents/Footer/Footer';

//Images
import HomeBackgroundImage from "../../assets/images/1.jpg";


const Home = () => {
  const token = localStorage.getItem('token');
  return (
    <Container>
      <Banner background={`url(${HomeBackgroundImage})`}>
        <SubBanner>
          <Header />
          <SectionOne/>
        </SubBanner>
      </Banner>

      <Apropos/>
      <BorderSvg/>

      <Services />

      
      <LightColor>
        <BorderSvgO/>
        <div className="footerHeard">
          <p className="h3">Créé par des professionnels. Pour les professionnels</p>
          <button className="btnn">
            {
              token ? (
                <a 
                  style={{textDecoration: "none", color: "#fff"}} 
                  href="/dashboard"
                >
                  Tableau de bord
                </a>
              ) : (
                <a 
                  style={{textDecoration: "none", color: "#fff"}} 
                  href="/signUp"
                >
                  Ouvrir un compte
                </a>
              )
            }
            <FaArrowRight />
          </button>
        </div>
        <Footer />
      </LightColor>
    </Container>
  )
}
export default Home;

const Container = styled.div`
  //background: rgb(235, 247, 255);
  background: #FFFFFF;

  svg {
    height: 50px;
  }


`;
const Banner = styled.div`
  width: 100%;
  height: auto;
  background: ${(props) => props.background};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SubBanner = styled.div`
  //background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
  background: #0B1A46;
  opacity: 0.8;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  //z-index: 1;
`;


const LightColor = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  .footerHeard {
    background: #0B1A46;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    
    .h3 {
      //border: 2px solid yellow;
      font-weight: bolder;
      font-size: 2rem;
    }

    .btnn {
      padding: 0.2rem 2rem;
      cursor: pointer;
      background-color: #01be96;
      border-radius: 4px;
      color: #fff;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      :hover {
        filter: drop-shadow(0px 10px 10px #01be9570);
      }
    }

    .wd-dividerO {
      height: 20px;
      margin-bottom: -2px;
    }
  }

`;