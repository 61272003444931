import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
// @mui
import { Box, Card, Link, Typography, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrencyDot } from '../../../Theme/utils/formatNumber';
// components
import Label from '../../../Components/label';
import { ColorPreview } from '../../../Components/color-utils';
import { url } from '../../../Components/API/Api';
import Iconify from '../../../Components/iconify/Iconify';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  article: PropTypes.object,
};

export default function ShopProductCard({ article, onClickUpdate, onClickDelete, onClickStock, onClickView }) {
  const { id_art, nomarticle, img_art, prix, description, etat, prix_promo } = article;

  return (
    <Card key={id_art}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={nomarticle} src={`${url}/imageArticle/${img_art}`} />
      </Box>

      <Stack spacing={1} sx={{ p: 2 }}>
        <Link color="inherit" underline="none" display="flex" flexWrap="wrap-reverse" alignItems="center" justifyContent="space-between" >
          <Typography variant="subtitle2" noWrap>
            {nomarticle}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            <Label color={(article.Stock_Art.quantiteStock <= 10 && 'error') || 'success'}>{sentenceCase(article.Stock_Art.quantiteStock)} Stock</Label>
          </Typography>
        </Link> 
        
        { prix_promo > "0" ?
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            
              <Typography variant="subtitle1">
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    color: 'text.disabled',
                    textDecoration: 'line-through',
                  }}
                >
                  {prix && fCurrencyDot(prix)} F CFA
                </Typography>
                &nbsp;
                {fCurrencyDot(prix_promo)} F CFA
              </Typography>
          </Stack>
          :
          <Typography style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", color: "#000000"}}>
            {fCurrencyDot(prix)} F CFA
          </Typography>
        }
        <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
          <ColorPreview descriptions={description} />
        </div>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <IconButton aria-label="update" onClick={() => onClickUpdate(article.id_art)}>
            <Iconify icon={'eva:edit-fill'} />
          </IconButton>
          <IconButton onClick={() => onClickDelete(article.id_art)} style={{display: 'flex', alignItems: "center", justifyContent: "center"}} sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} />
          </IconButton>
          <IconButton onClick={() => onClickView(article.id_art)} aria-label="stock">
            <Iconify icon="eva:eye-fill" />
          </IconButton>
          <IconButton onClick={() => onClickStock(article.id_art)} aria-label="stock">
            <Iconify icon="eva:plus-fill" />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
}
