import React from 'react';

const Guide = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.04913 15.9865L4.34369 15.9041C4.62703 15.825 4.79252 15.5311 4.71335 15.2477L1.34554 3.19565C1.29802 3.02557 1.18487 2.88133 1.031 2.79467C0.877126 2.70801 0.695128 2.68603 0.525045 2.73356L0.487004 2.74418C0.132804 2.84314 -0.0741074 3.2105 0.024852 3.5647L3.39274 15.6168C3.47191 15.9001 3.76579 16.0656 4.04913 15.9865Z" fill="#007EFC" />
            <path d="M15.9091 7.56415C14.841 7.03161 13.853 6.35189 12.9739 5.54484C12.9 5.47719 12.8711 5.37335 12.8994 5.27725C13.335 3.6293 13.6585 1.95375 13.8677 0.26208C13.9008 -0.0192994 13.7491 -0.102058 13.5891 0.0910454C10.4497 3.84553 5.01802 -0.595851 1.78491 2.81656C1.82373 2.88924 1.85426 2.96604 1.87595 3.04553L4.06077 10.869C7.2994 7.49794 12.7118 11.9117 15.8456 8.16553C16.0056 7.97242 16.0359 7.61932 15.9091 7.56415Z" fill="#007EFC" />
        </svg>

    );
};

export default Guide;