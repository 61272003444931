import React from "react";

export default function DropInSvg() {
  return (
    <svg
      height="512"
      viewBox="0 0 24 24"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      fill="#009C5B"
    >
      <g id="Layer_2" data-name="Layer 2">
        <path d="m14 18v1a2.00583 2.00583 0 0 1 -2 2h-8a2.00583 2.00583 0 0 1 -2-2v-1a6.00287 6.00287 0 0 1 2.42993-4.82 5.48777 5.48777 0 0 0 7.14014 0 6.00287 6.00287 0 0 1 2.42993 4.82z" />
        <path d="m22 13.04v.47a1.69432 1.69432 0 0 1 -1.68994 1.69h-5.62012a1.35825 1.35825 0 0 1 -.29-.03 6.97362 6.97362 0 0 0 -1.39994-2.05v-.08a4.45165 4.45165 0 0 1 1.27-3.11 4.49258 4.49258 0 0 0 6.46 0 4.45165 4.45165 0 0 1 1.27 3.11z" />
        <circle cx="8" cy="9" r="4.5" />
        <circle cx="17.5" cy="6.8" r="3.5" />
      </g>
    </svg>
  );
}
