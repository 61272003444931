import React from "react";

export default function ScheduleSvg() {
  return (
    <svg
      id="Icon_1_"
      enableBackground="new 0 0 100 100"
      height="512"
      viewBox="0 0 100 100"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      fill="#FF8E26"
    >
      <path
        id="Calendar"
        d="m21 38v-3c0-4.963 4.037-9 9-9h3v-6h6v6h8v-6h6v6h8v-6h6v6h3c4.963 0 9 4.037 9 9v3zm58 6v27c0 4.963-4.037 9-9 9h-40c-4.963 0-9-4.037-9-9v-27zm-40 20h-6v6h6zm0-12h-6v6h6zm14 12h-6v6h6zm0-12h-6v6h6zm14 12h-6v6h6zm0-12h-6v6h6z"
      />
    </svg>
  );
}
