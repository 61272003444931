import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";


export const TimeContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 1px;
border: 1px solid #0041b8;
border-radius: 8px;
background: #f2f4f6;
`;

export const TimeSvgContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 16px;
gap: 8px;
width: 20%;
height: 100%;
border-radius: 8px 0px 0px 8px;
`;

export const TimesLabel = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 16px;
gap: 8px;
width: 70%;
height: 100%;
border-radius: 0px 8px 8px 0px;
span {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  color: #111439;
  @media ${devices.tablet} {
    font-size: 14px;
  }
}
@media ${devices.tablet} {
  width: 60%;
}
`;