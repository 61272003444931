import React from "react";

const PrivateUserSvg = () => {
  return (
    <svg
      id="_x30_9.user_x5F_lock"
      enableBackground="new 0 0 195 195"
      height="80"
      viewBox="0 0 195 195"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
      fill="#ffffff"
    >
      <g id="icon_8_">
        <path d="m90.39 114.28v37.61c0 3.19.96 6.16 2.62 8.63h-59.49c-6.64 0-12.02-5.38-12.02-12.01 0-13.75 5.57-26.19 14.58-35.2s21.46-14.58 35.2-14.58h8.31c6.03 0 11.8 1.07 17.15 3.03-3.84 2.82-6.35 7.38-6.35 12.52z" />
        <circle cx="75.44" cy="61.16" r="26.97" />
        <path d="m164.58 105.36h-3.67v-15.43c0-7.08-2.87-13.49-7.51-18.13s-11.04-7.51-18.13-7.51c-14.15 0-25.63 11.48-25.63 25.64v15.44h-3.67c-4.93 0-8.92 3.99-8.92 8.92v37.61c0 4.93 3.99 8.92 8.92 8.92h58.62c4.93 0 8.92-3.99 8.92-8.92v-37.61c-.01-4.94-4-8.93-8.93-8.93zm-42.27-15.43c0-7.14 5.81-12.96 12.96-12.96 7.14 0 12.95 5.82 12.95 12.96v15.44h-25.91z" />
      </g>
    </svg>
  );
};

export default PrivateUserSvg;
