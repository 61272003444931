import React from "react";


//Style
import { GreetingContainer } from "./GreatingStyle";

const Greeting = () => {
  const prenom = localStorage.getItem('prenom');

  return (
    <GreetingContainer>
        <span style={{textAlign: "center", color: "#000000"}}>
          Bienvenu dans votre espace, { prenom }.
        </span>
        <div style={{textAlign: "justify"}}>
          La platforme idéal pour optimiser vos gestions 
          de stock, à réduire les coûts et à améliorer 
          votre efficacité opérationnelle.
        </div>
        {/* <span className="fourth-text1">Welcome to, Bilingual Bridges.</span> */}
    </GreetingContainer>
  );
};
export default Greeting;
