import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';


// Components
import { Step1AdminOnboardingContainer } from '../../Components/Oboarding/Admin/AdminOnboardingStyle';
import { AppWidgetSummary } from './Section';
import { AppWidgetSummaryTotalVendu } from './Section';
import { Grid } from '@mui/material';
import Scrollbar from '../../Components/scrollbar/Scrollbar';
import { UserListHead, UserListToolbar } from './Section/user';
import Iconify from '../../Components/iconify/Iconify';
import Label from '../../Components/label';


import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  TextField,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
// Style
import { 
  ContainerTopDashboard,
  ContainerCenterDashboard,
  ButtonDashboard
} from './TableauDeBordStyles';

// API 
import { entete, apiSousCat, apiCategorie, apiPersonnel, 
  apiCreateEntrepriseActif, apiArticle, apiVente
} from '../../Components/API/Api';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'ref_vente', label: 'Reference Vente', alignRight: false },
  { id: 'ref_article', label: 'Reference Article', alignRight: false },
  { id: 'nomVendeur', label: 'Nom du Vendeur', alignRight: false },
  { id: 'montantVente', label: 'Prix', alignRight: false },
  { id: 'etat', label: 'Status', alignRight: false },
  { id: 'dateVente', label: 'Date & Heure', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nomVendeur.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------

const TableauDeBord = () => {
  const idUser = localStorage.getItem('id');
  const typeLocal = localStorage.getItem('type');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [entrepriseID, setEntrepriseID] = useState(2);
  const [vente, setVente] = useState([]);

  //***************************************************************************************************** */

  const datePartielle = new Date().toLocaleDateString('en-GB');
  const dateActuelle = new Date().toISOString().slice(0, 10);

  //***************************************************************************************************** */

  // Data Rapport User
  const [data, setData] = useState([]);
  // Data Users
  const [user, setUser] = useState([]);
  // Data Article
  const [article, setArticle] = useState([]);
  // Data categorie
  const [categorie, setCategorie] = useState([]);
  // Data Sous categorie
  const [sousCat, setSousCat] = useState([]);

  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getActifUserById = async () => {
    const token = localStorage.getItem('token');
    try {
      if (typeLocal != "admin") {
        const { data } = await apiPersonnel.get(`/personnel/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].entreprises[0].id)
      } else {
        const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].id)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  // Afficher tous les utilisateurs
  const alluser = async () => {
    const token = localStorage.getItem('token');
    try {
      apiPersonnel.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        console.log(res.data.data)
        setUser(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  };

  // All les catégories
  const allcategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiCategorie.get('/', {
        headers: {
            entete,
            Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setCategorie(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) {
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All sous Categories
  const allsouscategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiSousCat.get('/', {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
      }).then(res => {
        console.log(res.data.data)
        setSousCat(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  };

  // All article
  const allarticle = async () => {
    const token = localStorage.getItem('token');
    try {
      apiArticle.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setArticle(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  };

  // All Vente
  const allVente = async () => {
    const token = localStorage.getItem('token');
    try {
      apiVente.get(`/entreprise/${entrepriseID}/date/${dateActuelle}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setVente(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  };

  //***************************************************************************************************** */

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vente.map((n) => n.nomcat);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vente.length) : 0;

  const filteredUsers = applySortFilter(vente, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const rowsPerPageOptions = [5, 10, 25];

  for (let i = 25; i <= vente.length; i += 50) {
    rowsPerPageOptions.push(i);
  }
  //***************************************************************************************************** */

  useEffect(() => {
    allVente();
    allcategorie();
    allsouscategorie();
  }, []);

  useEffect(() => {
    getActifUserById();
  }, [idUser]);

  useEffect(() => {
    alluser();
    allarticle()
  }, [entrepriseID]);

  return (
    <Step1AdminOnboardingContainer>
      <ContainerTopDashboard>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={2}>
            <NavLink style={{textDecoration: "none"}}>
              <AppWidgetSummary title="Utilisateur" total={user.length? user.length : "0"} icon={'bxs:user'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <NavLink style={{textDecoration: "none"}}>
              <AppWidgetSummary title="Article" total={article.length? article.length : "0"} color="info" icon={'bxs:cart'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <NavLink style={{textDecoration: "none"}}>
              <AppWidgetSummary title="Catégorie" total={categorie.length? categorie.length : "0"} color="warning" icon={'bxs:grid-alt'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <NavLink  style={{textDecoration: "none"}}>
              <AppWidgetSummary title="Sous Catégorie" total={sousCat.length? sousCat.length : "0"} color="error" icon={'bxs:grid'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <NavLink  style={{textDecoration: "none"}}>
              <AppWidgetSummaryTotalVendu title="Total Vendu" total={sousCat.length? sousCat.length : "0"} color="error" icon={'bxs:grid'} />
            </NavLink>
          </Grid>
        </Grid>
      </ContainerTopDashboard>

      <ContainerCenterDashboard>
        <div className='title'>
          Historique des ventes du jours <strong>{datePartielle}</strong>
        </div>
      </ContainerCenterDashboard>

      <div>
        <Scrollbar>
          <TableContainer sx={{ minWidth: "100%" }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={vente.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { id, ref_vente, ref_article, nomVendeur, etat, dateVente, montantVente } = row;
                  const selectedUser = selected.indexOf(nomVendeur) !== -1;
                  
                  return (
                    <>
                      {
                        vente.length != 0 ? (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Box height={16} />
                            </TableCell>
    
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {ref_vente}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {ref_article}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {nomVendeur}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {montantVente}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  <Label color={(etat === 'vendu' && 'secondary') || 'error'}>{sentenceCase(etat)}</Label>
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  Ce {new Date(dateVente).toISOString().replace('T', ' à ').replace(/\.000Z$/, '')}
                                </Typography>
                              </Stack>
                            </TableCell>
    
                            <TableCell align="right">
                              <IconButton size="large" color="inherit">
                                {/*<Iconify icon={'eva:more-vertical-fill'} />*/}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                              <TableCell padding="checkbox">
                                <Box height={16} />
                              </TableCell>
      
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={4}>
                                  <Typography variant="subtitle2" noWrap>
                                    Pas de vente effectuer ce jour {datePartielle}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              
      
                              <TableCell align="right">
                                <IconButton size="large" color="inherit">
                                  {/*<Iconify icon={'eva:more-vertical-fill'} />*/}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      }
                    </>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Pas trouvé
                        </Typography>

                        <Typography variant="body2">
                          Aucun résultat trouvé pour &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </div>

    </Step1AdminOnboardingContainer>
  )
}
export default TableauDeBord;