import React, { useState, useEffect } from 'react';
// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../Theme/utils/formatNumber';
// components
import Iconify from '../../../Components/iconify/Iconify';

import { apiVente, entete, apiCreateEntrepriseActif } from '../../../Components/API/Api';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));
  
// ----------------------------------------------------------------------

AppWidgetSummaryTotalVendu.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};


export default function AppWidgetSummaryTotalVendu({ title, total, icon, color = 'primary', sx, ...other }) {
  const token = localStorage.getItem('token');
  const idUser = localStorage.getItem('id');
  const [etat, setEtat] = useState("");
  const [venteTotal, setVenteTotal] = useState('');
  //***************************************************************************************************** */

  const datePartielle = new Date().toLocaleDateString('en-GB');

  const dateActuelle = new Date().toISOString().slice(0, 10);

  //***************************************************************************************************** */

  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getActifUserById = async () => {
    try {
      const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
      })
      console.log(data);
      console.log(data.data);
      setEtat(data.data[0].etat)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
            // Gérez spécifiquement l'erreur 404
            // Affichez un message d'erreur ou effectuez d'autres actions
            console.log("Erreur 404 : Page non trouvée");
        } else if (error.response.status === 401) {
            // Gérez spécifiquement l'erreur 401
            // Affichez un message d'erreur ou effectuez d'autres actions
            console.log("Erreur 401 : Requête non autorisée");
        } else {
            // Gérez les autres erreurs
            // Affichez un message d'erreur générique ou effectuez d'autres actions
            console.log(error);
        }
      } else {
        // Gérez les autres erreurs qui n'ont pas de réponse associée
        console.log(error);
      }
    }
  }

    
  // ALL MENUS
  const allVenteByDate = async () => {
      try {
        apiVente.get(`/entreprise/date/${dateActuelle}`, {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data.data)
            setVenteTotal(res.data.totalMontantVente);
        }).catch (error => {
          if (error.response) {
              if (error.response.status === 404) {
                  // Gérez spécifiquement l'erreur 404
                  // Affichez un message d'erreur ou effectuez d'autres actions
                  console.log("Erreur 404 : Page non trouvée");
              } else if (error.response.status === 401) {
                  // Gérez spécifiquement l'erreur 401
                  // Affichez un message d'erreur ou effectuez d'autres actions
                  console.log("Erreur 401 : Requête non autorisée");
              } else {
                  // Gérez les autres erreurs
                  // Affichez un message d'erreur générique ou effectuez d'autres actions
                  console.log(error);
              }
          } else {
              // Gérez les autres erreurs qui n'ont pas de réponse associée
              console.log(error);
          }
        })
      } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (error.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(error);
            }
        } else {
            // Gérez les autres erreurs qui n'ont pas de réponse associée
            console.log(error);
        }
      }
  }

  useEffect(() => {
    allVenteByDate();
  }, []);

  useEffect(() => {
    getActifUserById();
  }, [idUser]);

  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h3">{datePartielle}</Typography>
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>

      <Typography variant="h3">{venteTotal !=0 ? fShortenNumber(venteTotal) : 0}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}  