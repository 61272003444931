import React, { useState, useEffect } from "react";
import { AiFillMobile } from "react-icons/ai";
import { CgWebsite, CgDatabase } from "react-icons/cg";
import { GiEarthAmerica } from "react-icons/gi";
import styled from "styled-components";
import { CardService } from "./Card";
import { Slide } from "react-awesome-reveal";
import suivi from "../../../assets/images/77suivi.jpg";
import distance from "../../../assets/images/22.jpg";
import gestion from "../../../assets/images/33gest.jpg";
import privacy from "../../../assets/images/55privacy.jpg";
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

const images = [
  {
    id: 1,
    imageUrl: require('../../../assets/images/77suivi.jpg'),
  },
  {
    id: 2,
    imageUrl: require('../../../assets/images/22.jpg'),
  },
  {
    id: 3,
    imageUrl: require('../../../assets/images/33gest.jpg'),
  },
  {
    id: 4,
    imageUrl: require('../../../assets/images/55privacy.jpg'),
  }
];

const Services = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePointClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container id="service">
      <div>
        <h4>
          NOS <span className="green">SERVICES</span>
        </h4>
        <h1 className="subTitle">Ce que nous offrons</h1>
      </div>
      <Cards>
        <CardService
          Icon={GiEarthAmerica}
          title={"Suivi rigoureuse de vos marchandises"}
          disc={`
            Elle permet de suivre les activités, de faire le point
            journalier, enregistrer les entrées et sorties des articles,
            connaître le stock restant à chaque achat ou vente.
          `}
        />
        <CardService
          Icon={CgDatabase}
          title={"Sécurité et fiabilité"}
          disc={`
            Elle est utilisé par plusieurs entreprises et personnes dans
            tous les pays de l'Afrique et partout dans le monde. Elle est
            sécurisée, fiable et assure la bonne gestion de votre entreprise.
          `}
        />
        <CardService
          Icon={CgWebsite}
          title={"Gestion des produits"}
          disc={`
            Elle permet d'avoir un aperçu sur les produits et sur les 
            achats effectués dans l'entreprise.
          `}
        />
        <CardService
          Icon={AiFillMobile}
          title={"Suivi à distance"}
          disc={`
            Elle permet aux chefs de petites et moyennes entreprises de
            suivre leur boutique quelques soit le lieu dont il se trouve.
          `}
        />
      </Cards>
  
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  //border: 2px solid #ffffff;
  @media (max-width: 840px) {
    width: 90%;
  }

  h4, .subTitle {
    color: #2F2F2F;
  }

  h1 {
    padding-top: 1rem;
  }
  .green {
    color: #005702 !important;
  }
`;
const Cards = styled.div`
  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 4rem;
  gap: 1rem;
  //border: 2px solid red
`;
const Slides = styled(Slide)`
  //width: 100%;
  //border: 2px solid red
`
const SubSlides = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  //width: 100%;
  //border: 2px solid black;
  @media (min-width: 600px) {
    flex-direction: row;
  }

  div {
    width: 100%;
    //border: 2px solid black;
    @media (min-width: 600px) {
      width: 45%;
    }
  }
`