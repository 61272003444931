import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";
import Uploader from "rsuite/Uploader";

export const Uploaders = styled(Uploader)`
  background-color: #fafafb !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .rs-uploader-picture {
    width: 100% !important;
    border: none !important;
  }
  .rs-uploader-trigger-btn {
    width: 100% !important;
    border: none !important;
  }
`;

export const UploadButton = styled.button`
  width: 100% !important;
  height: 100px !important;
  background-color: #fafafb !important;
  border: none !important;

  img{
    width: 100% !important;
    height: 100px !important;
    object-fit: cover;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  padding: 5px 0px;
  gap: 8px;
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #111439;
  }
`;