import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Components
import { ButtonStyles } from '../../../Components/Buttons/ButtonStyles';
import { Links, StyledNavLink } from "../../../Components/Links/LinksStyle";
import { HintContainer, HintDescription, InputLabel, Inputs } from "../../../Components/Inputs/InputStyles";
import { InputContainer } from '../../../Components/Inputs/InputStyles';

//Icons
import BrandDarkSvg from "../../../assets/Svg/BrandSvg";

// Styles
import "../StylesAuth.css";
import {
    BodyContainer,
    LoginCard,
    BrandLogo,
    LoginHeadWrap,
    LoginHeadTitle,
    LoginHeadDescription,
    EmailLoginWrap,
    SubBodyContainer,
    LoginFooterTop,
    PasswordEmailContainer,
    PasswordEmail,
    LoginFooterTopWrap
} from "../AuthStyle";

//Images
import HomeBackgroundImage from "../../../assets/images/1.jpg";


// API
import { apiCreateCodeVerify, entete, notify, apiVerificationForPassword } from '../../../Components/API/Api';

const ConfirmCode = () => {
    const [actif, setActif] = useState(false);
    const [status, setStatut] = useState(false);
    const [statusCode, setStatutCode] = useState(false);
    const email = localStorage.getItem('email');
    const userID = localStorage.getItem('id');
    const [msg, setMsg] = useState('');
    const [userInfoc, setuserInfoc] = useState('');
    const navigate = useNavigate();

    const initialTime = 240; // 4 minutes en secondes
    const [time, setTime] = useState(initialTime);
    const [counting, setCounting] = useState(false);

    const onChangeValue = (e) => {
        setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
    };

    const receiveCodeVerify = async (e) => {
        e.preventDefault();
        setStatut(true);
        try {
            await apiCreateCodeVerify.post('/', {
              email : email,
              userID : userID
            }).then((res) => {
                setActif(true)
                notify('success', `${res.data.message}`);
            }).catch((err) => {
              console.log(err);
              notify('warning', "Le code de vérification existe déjà.");
              setActif(true)
            });
          } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    // Gérez spécifiquement l'erreur 404
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 404 : Page non trouvée");
                } else if (error.response.status === 401) {
                    // Gérez spécifiquement l'erreur 401
                    // Affichez un message d'erreur ou effectuez d'autres actions
                    console.log("Erreur 401 : Requête non autorisée");
                } else {
                    // Gérez les autres erreurs
                    // Affichez un message d'erreur générique ou effectuez d'autres actions
                    console.log(error);
                }
            } else {
                // Gérez les autres erreurs qui n'ont pas de réponse associée
                console.log(error);
            }
        }
    }

    const submitVerify = async (e) => {
        e.preventDefault();
        setStatutCode(true);

        if (userInfoc.code != "") {
            try {
                await apiVerificationForPassword.post('/', {
                    userID : userID,
                    code : userInfoc.code,
                }).then((res) => {
                  notify('success', `${res.data.message}`);
                  navigate('/updatePassword', { replace: true });
                }).catch((err) => {
                    console.log(err);
                    setStatutCode(false);
                    setMsg("Le code a expiré ou incorrecte")
                    notify('warning', `Renseignez un code de vérification correct`);
                });
            } catch (error) {
                setStatutCode(false);
                if (error.response) {
                    if (error.response.status === 404) {
                        // Gérez spécifiquement l'erreur 404
                        // Affichez un message d'erreur ou effectuez d'autres actions
                        console.log("Erreur 404 : Page non trouvée");
                    } else if (error.response.status === 401) {
                        // Gérez spécifiquement l'erreur 401
                        // Affichez un message d'erreur ou effectuez d'autres actions
                        console.log("Erreur 401 : Requête non autorisée");
                    } else {
                        // Gérez les autres erreurs
                        // Affichez un message d'erreur générique ou effectuez d'autres actions
                        console.log(error);
                    }
                } else {
                    // Gérez les autres erreurs qui n'ont pas de réponse associée
                    console.log(error);
                }
            }
        } else {
            notify('warning', "Renseignez le champ pour activer votre compte");
            setMsg('Le champ est obligatoire')
            setStatutCode(false);
        }
    }

    useEffect(() => {
        setActif(actif)
    }, []);

    useEffect(() => {
        let interval;
    
        if (counting && time > 0) {
        interval = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);
        }
    
        if (time === 0) {
        setCounting(false);
        }
    
        return () => {
        clearInterval(interval);
        };
    }, [counting, time]);
    
    const handleStartStop = () => {
        if (counting) {
          setCounting(false);
        } else {
          setCounting(true);
        }
    };
    
    const handleReset = () => {
        setTime(initialTime);
        setCounting(false);
    };
    
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;


    return (
        <SubBodyContainer background={`url(${HomeBackgroundImage})`}>
            <BodyContainer>
                <ToastContainer 
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <LoginCard onSubmit={submitVerify}>
                    {/* ********************Header Login Start******************** */}
                    <BrandLogo>
                        <BrandDarkSvg />
                    </BrandLogo>

                    {
                        !actif ? (
                            <>
                                <LoginHeadWrap>
                                    <LoginHeadTitle>Vérification de l'Email : </LoginHeadTitle>
                                    <LoginHeadDescription>
                                        Cliquez sur le bouton ci-dessous pour renseigner un code qui vous 
                                        sera envoyer dans votre boîte mail.
                                    </LoginHeadDescription>
                                </LoginHeadWrap>
                                {
                                    !status ? (
                                        <ButtonStyles
                                            width={"100%"}
                                            primarybuttom
                                            primarymediumbuttom
                                            onClick={receiveCodeVerify}
                                        >
                                            Continer
                                        </ButtonStyles>
                                    ) : (
                                        <ButtonStyles
                                            width={"100%"}
                                            primarybuttom
                                            primarymediumbuttom
                                        >
                                            En cours...
                                        </ButtonStyles>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                <LoginHeadWrap>
                                    <LoginHeadTitle>Vérification de l'Email : </LoginHeadTitle>
                                    <LoginHeadDescription>
                                        Un code vous a été envoyer dans votre 
                                        boîte mail. Confirmez-le dans le champs 
                                        ci-dessous.
                                    </LoginHeadDescription>
                                </LoginHeadWrap>
                                {/* ********************Header Login End******************** */}

                                {/* ********************Input and Button Login Start******************** */}
                                <EmailLoginWrap>
                                    <InputContainer>
                                        <InputLabel>Saisissez le code</InputLabel>
                                        <Inputs
                                            width={"100%"}
                                            type="number"
                                            placeholder="Entrez le code envoyer par email"
                                            name="code"
                                            value={userInfoc.code}
                                            onChange={onChangeValue}
                                        />
                                        {
                                            msg != "" && (
                                                <HintContainer>
                                                    <HintDescription hintdescriptionerror>
                                                        { msg }
                                                    </HintDescription>
                                                </HintContainer>
                                            )
                                        }
                                    </InputContainer>
                                    {
                                        !statusCode ? (
                                            <ButtonStyles
                                                width={"100%"}
                                                primarybuttom
                                                primarymediumbuttom
                                                type='submit'
                                            >
                                                Confirmer le code
                                            </ButtonStyles>
                                        ) : (
                                            <ButtonStyles
                                                width={"100%"}
                                                primarybuttom
                                                primarymediumbuttom
                                            >
                                                Confirmation en cours...
                                            </ButtonStyles>
                                        )
                                    }
                                    <LoginFooterTop>
                                        {/*<StyledNavLink>Renvoyez le code {minutes}:{seconds < 10 ? "0"+{seconds} : seconds}</StyledNavLink>*/}
                                        {/*{time === 0 ? (
                                                <StyledNavLink>Renvoyez le code</StyledNavLink>
                                            ) : (
                                                <div>
                                                    <button onClick={handleStartStop}>
                                                        {counting ? 'Pause' : 'Démarrer'}
                                                    </button>
                                                    <button onClick={handleReset}>Réinitialiser</button>
                                                </div>
                                            )
                                        }*/}
                                    </LoginFooterTop>
                                </EmailLoginWrap>
                            {/* ********************Input and Button Login End******************** */}
                            </>
                        )
                    }

                </LoginCard>
            </BodyContainer>
        </SubBodyContainer>
    )
}
export default ConfirmCode;