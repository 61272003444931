import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { filter } from 'lodash';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';

// Components
import { UserListToolbar, UserListHead } from '../TableauDeBord/Section/user';
import { Step1AdminOnboardingContainer } from '../../Components/Oboarding/Admin/AdminOnboardingStyle';
import Iconify from '../../Components/iconify/Iconify';
import Scrollbar from '../../Components/scrollbar/Scrollbar';
import { fCurrencyDot } from '../../Theme/utils/formatNumber';
import { ColorPreview } from '../../Components/color-utils';
import Label from '../../Components/label';

//STYLES
import { 
  ApprovisionementHeaderTabs, ModalForm
} from './ApprovisionementStyles';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Stack, Container, TextField, Typography, Tabs, Tab, Box, FormControl, TableContainer, Table, TableBody, 
  InputLabel, Select, MenuItem, Input, InputAdornment, TableRow, TableCell, TablePagination, Popover, Paper,
  IconButton, Card, Link
} from '@mui/material';
import { Modal, Form, Button } from 'rsuite';

import { ProductList, ProductListCommander } from './section';


import Image from '../../assets/images/produit.jpg';

// API
import { apiArticle, entetes, apiCategorie, apiSousCat, entete, url,
  apiStock, notify, apiCreateEntrepriseActif, apiArticleCommander, apiPersonnel
} from '../../Components/API/Api';


// ----------------------------------------------------------------------

const TABLE_HEAD_CATEGORIE = [
  { id: 'nomcat', label: 'Nom Categorie', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_SOUS_CATEGORIE = [
  { id: 'option', label: 'Option de paiement', alignRight: false },
  { id: 'etat', label: 'Etat', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nomcat.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterCategorie(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.libsouscat.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  borderRadius: '10px',
});

// ----------------------------------------------------------------------

const Approvisionement = () => {
  const idUser = localStorage.getItem('id');
  const typeLocal = localStorage.getItem('type');

  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState('');

  //-------------------DATA articles START---------------------
  // Data categorie
  const [categorie, setCategorie] = useState([]);

  // Data Sous categorie
  const [sousCat, setSousCat] = useState([]);

  // Data Article
  const [article, setArticle] = useState([]);
  const [ids, setIds] = useState(0);
  const [nomarticle, setNomarticle] = useState('');
  const [ref, setRef] = useState('');
  const [description, setDescription] = useState('');
  const [prix, setPrix] = useState('');
  const [prix_promo, setPrix_promo] = useState(0);
  const [stockArt, setStockArt] = useState(0);
  const [nomOrg, setNomOrg] = useState("");
  const [imgArt, setImg_art] = useState(null);
  const [sousCategorieID, setSousCategorieID] = useState('');
  const [categorieID, setCategorieID] = useState('');
  const [entrepriseID, setEntrepriseID] = useState(2);
  const [status, setStatus] = useState(false);
  const [view, setView] = useState(false);

  // Data Stock
  const [quantiteStock, setQuantiteStock] = useState('');
  const [articleID, setArticleId] = useState(false);
  const [idStock, setIdStock] = useState("");
  //-------------------DATA articles END---------------------

  //-------------------DATA articles sous commande START---------------------
  // Data categorie
  const [categoriec, setCategoriec] = useState([]);

  // Data Sous categorie
  const [sousCatc, setSousCatc] = useState([]);

  // Data Article Commander
  const [articlec, setArticlec] = useState([]);
  const [idsc, setIdsc] = useState(0);
  const [libelleArticleCommande, setLibelleArticleCommande] = useState('');
  const [nomOrgCmd, setNomOrgCmd] = useState("");
  const [etatArtCmd, setEtatArtCmd] = useState('');
  const [refCmd, setRefCmd] = useState('');
  const [dateCmd, setDateCmd] = useState('');
  const [nomHomme, setNomHomme] = useState('');
  const [nomFemme, setNomFemme] = useState('');
  const [tailleHomme, setTailleHomme] = useState(0);
  const [tailleFemme, setTailleFemme] = useState(0);
  const [img_art_commande, setImg_art_commande] = useState(null);
  const [descriptionc, setDescriptionc] = useState('');
  const [prixNormal, setPrixNormal] = useState('');
  const [montantVerse, setMontantVerse] = useState('');
  const [montantRestant, setMontantRestant] = useState('');
  const [Date, setDate] = useState('');
  
  // Data Stock
  const [quantiteStockc, setQuantiteStockc] = useState(false);
  const [articleIDc, setArticleIdc] = useState(false);
  //-------------------DATA articles sous commande END---------------------

  // ----------------------------------------
  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // ----------------------------------------

  //-------------------MODAL Gestion des articles START---------------------
  // Modal Article
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setNomarticle('');
    setDescription('');
    setPrix(0);
    setPrix_promo(0);
    setImg_art(null);
    setCategorieID('');
    setSousCategorieID('');
    setuserInfo({
      filepreview: null
    });
    setShow(false)
  };
  const handleShow = () => {
    setShow(true)
  };

  // Modal Update Article
  const [showupd, setShowupd] = useState(false);
  const handleCloseupd = () => { 
    setNomarticle('');
    setDescription('');
    setPrix(0);
    setPrix_promo(0);
    setImg_art(null);
    setCategorieID('');
    setSousCategorieID('');
    setuserInfo({
      filepreview: null
    });
    setShowupd(false) 
  };
  const handleShowupd = (e) => { setShowupd(true) };

  // Modal Article
  const [showCmd, setShowCmd] = useState(false);
  const handleCloseCmd = () => {
    setNomHomme('');
    setNomFemme('');
    setTailleHomme(0);
    setTailleFemme(0);
    setPrixNormal(0);
    setMontantVerse(0);
    setDate('');
    setDescription('');
    setImg_art_commande(null);
    setCategorieID('');
    setSousCategorieID('');
    setuserInfo({
      filepreview: null
    });
    setShowCmd(false)
  };
  const handleShowCmd = () => setShowCmd(true);

  // Modal Update Article commander
  const [showupdCmd, setShowupdCmd] = useState(false);
  const handleCloseupdCmd = () => { 
    setNomHomme('');
    setNomFemme('');
    setTailleHomme(0);
    setTailleFemme(0);
    setPrixNormal(0);
    setMontantVerse(0);
    setDate('');
    setDescription('');
    setImg_art_commande(null);
    setCategorieID('');
    setSousCategorieID('');
    setuserInfo({
      filepreview: null
    });
    setShowupdCmd(false) 
  };
  const handleShowupdCmd = (e) => { setShowupdCmd(true) };

  // Modal Open Article
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => { 
    setShowView(false) 
  };
  const handleShowView = (e) => { setShowView(true) };

  // Modal Open Article Commander
  const [showViewCommander, setShowViewCommander] = useState(false);
  const handleCloseViewCommander = () => { 
    setShowViewCommander(false) 
  };
  const handleShowViewCommander = (e) => { setShowViewCommander(true) };

  // Modal Stock
  const [showStock, setShowStock] = useState(false);
  const handleCloseStock = () => setShowStock(false);
  const handleShowStock = () => setShowStock(true);

  // Open Modal Update article
  const handleUpdate = (id) => {
    setIds(id);
    handleShowupd();
  };  

  const handleView = (id) => {
    setIds(id);
    handleShowView();
  }; 

  const handleViewCommander = (id) => {
    setIdsc(id);
    handleShowViewCommander();
  }; 

  // Open Modal Update article commander
  const handleUpdateCommander = (id) => {
    setIdsc(id);
    handleShowupdCmd();
  }; 

  //-------------------MODAL Gestion des articles END---------------------

  //-------------------MODAL Gestion des commandes Personnalisées START---------------------

  // Modale ADD Categorie
  const [openModalCategorie, setOpenModalCategorie] = useState(null);
  const handleOpenModalCategorie = () => setOpenModalCategorie(true);
  const handleCloseModalCategorie = () => setOpenModalCategorie(false);

  // Modale ADD Sous Categorie 
  const [openModalSousCategorie, setOpenModalSousCategorie] = useState(null);
  const handleOpenModalSousCategorie = () => setOpenModalSousCategorie(true);
  const handleCloseModalSousCategorie = () => setOpenModalSousCategorie(false);

  // Data Categorie
  const [categorieC, setCategorieC] = useState([]);
  const [nomcat, setNomCategorie] = useState('');
  const [idcategorie, setIdCategorie] = useState(0);

  // Sous Categorie
    const [idSousCategorie, setIdSousCategorie] = useState(0);
    const [libsouscat, setLibsouscat] = useState('');
    const [categoriId, setCategoriId] = useState('');

  // Modal Update Categorie
  const [showupdCategorie, setShowupdCategorie] = useState(false);
  const handleCloseupdCategorie = () => {
    setShowupdCategorie(false)
  };

  // Modal Update Sous Categorie
  const [showupdSousCategorie, setShowupdSousCategorie] = useState(false);
  const handleCloseupdSousCategorie = () => {
    setShowupdSousCategorie(false)
  };

  // Modal Stock
  const [showStockCmd, setShowStockCmd] = useState(false);
  const handleCloseStockCmd = () => setShowStockCmd(false);
  const handleShowStockCmd = () => setShowStockCmd(true);
  //-------------------MODAL Gestion des commandes Personnalisées END---------------------

  const [userInfo, setuserInfo] = useState({
    filepreview: null,
  });

/****************************************************************************************************** */
  // All les catégories
  const allcategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiCategorie.get('/', {
        headers: {
            entete,
            Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setCategorie(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) {
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };
  
  // All sous Categories
  const allsouscategorie = async () => {
    const token = localStorage.getItem('token');
    try {
      apiSousCat.get('/', {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
      }).then(res => {
        console.log(res.data.data)
        setSousCat(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All article
  const allarticle = async () => {
    const token = localStorage.getItem('token');
    try {
      apiArticle.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setArticle(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All article commander
  const allarticleCommander = async () => {
    const token = localStorage.getItem('token');
    try {
      apiArticleCommander.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setArticlec(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

/*************************************************************************************************** */
  // Add articles
  const ajoutArticle = async () => {
    // e.persist();
    const token = localStorage.getItem('token');
    setView(true)

    if (nomarticle && prix) {
      const imgSize = imgArt && imgArt.size;
      if (imgSize && imgSize > 4194304) { // Vérification de la taille de l'image (4 Mo en octets)
        notify('warning', "La taille de l'image doit être inférieure ou égale à 4 Mo");
        setView(false)
        return;
      }  
      // setIsLoading(true);
      const parsedPrix = parseFloat(prix);
      const parsedPrixPromo = parseFloat(prix_promo);
      if(!Number.isNaN(parsedPrix) && !Number.isNaN(parsedPrixPromo) && parsedPrix > parsedPrixPromo){
        try{
          const formData = new FormData();
          formData.append("nomarticle", nomarticle);
          formData.append("description", description);
          formData.append("prix", prix);
          formData.append("prix_promo", prix_promo);
          formData.append("img_art", imgArt);
          formData.append("categorieID", categorieID);
          formData.append("sousCategorieID", sousCategorieID);
          formData.append("entrepriseID", entrepriseID);

          await apiArticle.post('/', formData, {
              headers: {
                entetes,
                Authorization: `Bearer ${token}`
              }
          }).then (res => {
            console.log(res.data);
            setNomarticle("");
            setDescription("");
            setPrix(0);
            setPrix_promo(0);
            setImg_art(null);
            setCategorieID('');
            setSousCategorieID('');
            handleClose();
            setArticle([]);
            allarticle();
            setStatus(true);
            notify('success', `${res.data.message}`);
          }).catch((err) => {
            console.log(err);
            notify('warning', "Une erreur s'est produite lors de la création de l'article. Assurez-vous que les champs sont bien renseignés");
            handleClose();
          })
        } catch (err) {
          setView(false)
          console.error(err);
          notify('', `Echec lors de l'ajout de l'article. Veuillez rééssayer plutard`)
          handleClose();
        }
      } else {
        setView(false)
        notify('warning', "Vérifiez que le prix normal est supérieur au prix promo et que les prix sont supérieurs à zéro");
      }
    } else {
      setView(false)
      notify('warning', "Assurez-vous que tous les champs sont bien renseignés");
    }
  };

  // Add articles
  const ajoutArticleCommander = async () => {
    // e.persist();
    const token = localStorage.getItem('token');
    setView(true)

    if (nomHomme && nomFemme && prixNormal && Date) {
      const imgSize = img_art_commande && img_art_commande.size;
      if (imgSize && imgSize > 4194304) { // Vérification de la taille de l'image (4 Mo en octets)
        notify('warning', "La taille de l'image doit être inférieure ou égale à 4 Mo");
        setView(false)
        return;
      }  
      // setIsLoading(true);
      const parsedPrixNormal = parseFloat(prixNormal);
      const parsedMontantVerse = parseFloat(montantVerse);
      if(!Number.isNaN(parsedPrixNormal) && !Number.isNaN(parsedMontantVerse)){
        try{
          const formData = new FormData();
          formData.append("nomHomme", nomHomme);
          formData.append("nomFemme", nomFemme);
          formData.append("tailleHomme", tailleHomme);
          formData.append("tailleFemme", tailleFemme);
          formData.append("description", description);
          formData.append("prixNormal", prixNormal);
          formData.append("montantVerse", montantVerse);
          formData.append("Date", Date);
          formData.append("img_art_commande", img_art_commande );
          formData.append("categorieID", categorieID);
          formData.append("sousCategorieID", sousCategorieID);
          formData.append("entrepriseID", entrepriseID);

          await apiArticleCommander.post('/', formData, {
              headers: {
                entetes,
                Authorization: `Bearer ${token}`
              }
          }).then (res => {
            console.log(res.data);
            setNomHomme("");
            setNomFemme(0);
            setTailleHomme(0);
            setTailleFemme(0);
            setDescription('');
            setPrixNormal('');
            setMontantVerse('');
            setDate('');
            setCategorieID('');
            setSousCategorieID('');
            setImg_art_commande(null);
            handleCloseCmd();
            setArticle([]);
            allarticleCommander();
            setStatus(true);
            notify('success', `${res.data.message}`);
          }).catch((err) => {
            console.log(err);
            notify('warning', "Une erreur s'est produite lors de la création de l'article. Assurez-vous que les champs sont bien renseignés");
            handleCloseCmd();
          })
        } catch (err) {
          setView(false)
          console.error(err);
          notify('', `Echec lors de l'ajout de l'article. Veuillez rééssayer plutard`)
          handleCloseCmd();
        }
      } else {
        setView(false)
        notify('warning', "Vérifiez que le prix normal et le montant versé sont des chiffres");
      }
    } else {
      setView(false)
      notify('warning', "Assurez-vous que tous les champs sont bien renseignés");
    }
  };

  // Ajout de Categorie
  const ajoutCategorie = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    setView(true)

    if (nomcat) {
        try{
            // alert(libsouscat)
            await apiCategorie.post('/', 
            { 
                nomcat
            }, {
                headers: {
                    entete,
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res.data)
                // alert(res.data.message)
                setNomCategorie("");
                handleCloseModalCategorie();
                allcategorie();
                setStatus(true);
                setView(false)
                notify('success', `${res.data.message}`);
            }).catch((err) => {
              setView(false)
              console.log(err);
              if (err.response && err.response.data && err.response.data.message === "La catégorie existe déjà.") {
                notify('warning', "La catégorie existe déjà.");
              } else {
                notify('warning', "Une erreur s'est produite lors de la création de la catégorie. Assurez-vous que les champs sont bien renseignés");
              }
              handleCloseModalCategorie();
              setNomCategorie("");
              allcategorie();
              setStatus(true);
            }) 
        } catch (err) {
          setView(false)
          console.error(err);
          notify('', `Echec lors de l'ajout de la catégorie. Veuillez rééssayer plutard`)
          handleCloseModalCategorie();
        }
    } else {
      setView(false)
      notify('', `Vérifiez que tous les champs ne sont pas vide`);
    }
  };

  // Ajouter sous Categorie
  const ajoutSousCategorie = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    setView(true)
    // alert(nomSouscat);

    if (libsouscat && categoriId) {
      try{
          // alert(libsouscat)
          await apiSousCat.post('/', 
          { libsouscat, categoriId }, {
            headers: {
              entete,
              Authorization: `Bearer ${token}`
            }
          }).then(res => {
            console.log(res.data)
            // alert(res.data.message)
            setLibsouscat("");
            setCategoriId("");
            handleCloseModalSousCategorie();
            setLibsouscat([]);
            allsouscategorie();
            setStatus(true);
            notify('success', `${res.data.message}`);
          }).catch((err) => {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message === "La sous-catégorie existe déjà.") {
              notify('warning', "La sous-catégorie existe déjà.");
            } else {
              notify('warning', "Une erreur s'est produite lors de la sous-création de la catégorie. Assurez-vous que les champs sont bien renseignés");
            }
            setLibsouscat("");
            setCategoriId("");
            handleCloseModalSousCategorie();
            setLibsouscat([]);
            allsouscategorie();
            setStatus(true);
          })
      } catch (err) {
        setView(false)
          console.error(err);
          notify('', `Echec lors de l'ajout de la sous catégorie. Veuillez rééssayer plutard`)
          handleCloseModalSousCategorie();
      }
    } else {
      setView(false)
      notify('', `Vérifiez que tous les champs ne sont pas vide`);
    }
  };
//***************************************************************************************************** */

  // Recuperer id de l'article
  const getDataById = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiArticle.get(`/${ids}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`${data.data}`);
      //setArticleid_upd(data.data.id_art);
      setNomarticle(data.data.nomarticle);
      setDescription(data.data.description);
      setPrix(data.data.prix);
      setPrix_promo(data.data.prix_promo);
      setImg_art(null);
      setCategorieID(data.data.categorieID);
      setSousCategorieID(data.data.sousCategorieID);
      setuserInfo({
        filepreview: data.data.img_art 
      });
      setRef(data.data.reference);
      setStockArt(data.data.Stock_Art.quantiteStock)
      setNomOrg(data.data.ManagerProfileEntreprise.nomEntreprise)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle specific 404 error
        // Display an error message or perform other actions
      } else {
        // Handle other errors
        // Display a generic error message or perform other actions
      }
    }
  };

  // Recuperer id de l'article
  const getDataByIdCmd = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiArticleCommander.get(`/${idsc}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`${data.data}`);
      //setArticleid_upd(data.data.id_art);
      setLibelleArticleCommande(data.data.libelleArticleCommande);
      setNomOrgCmd(data.data.ManagerProfileEntreprise.nomEntreprise);
      setEtatArtCmd(data.data.etat);
      setNomHomme(data.data.nomHomme);
      setNomFemme(data.data.nomFemme);
      setTailleHomme(data.data.tailleHomme);
      setTailleFemme(data.data.tailleFemme);
      setPrixNormal(data.data.prixNormal);
      setMontantVerse(data.data.montantVerse);
      setMontantRestant(data.data.montantRestant);
      setDate(data.data.Date);
      setDescription(data.data.description);
      setImg_art_commande(null);
      setCategorieID(data.data.categorieID);
      setSousCategorieID(data.data.sousCategorieID);
      setuserInfo({
        filepreview: data.data.img_art_commande 
      });
      setRefCmd(data.data.reference);
      setDateCmd(data.data.Date);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle specific 404 error
        // Display an error message or perform other actions
      } else {
        // Handle other errors
        // Display a generic error message or perform other actions
      }
    }
  };

  // Recuperer id du stock de l'article
  const getDataByIdStock = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiStock.get(`/${articleID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`${data.data}`);
      setQuantiteStock(data.data.quantiteStock);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle specific 404 error
        // Display an error message or perform other actions
      } else {
        // Handle other errors
        // Display a generic error message or perform other actions
      }
    }
  };
  
  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getActifUserById = async () => {
    const token = localStorage.getItem('token');
    try {
      if (typeLocal != "admin") {
        const { data } = await apiPersonnel.get(`/personnel/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].entreprises[0].id)
      } else {
        const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
            headers: {
              entete,
              Authorization: `Bearer ${token}`
            }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].id)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  // Recuperer id de la categorie
  const getDataByCategorieId = async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await apiCategorie.get(`/${idcategorie}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
      })
      console.log(data);
      //setIdUpd(data.data.id)
      setNomCategorie(data.data.nomcat)
    } catch (error) { 
        if (error.response && error.response.status === 404) {
            // Gérez spécifiquement l'erreur 404
            // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
            // Gérez les autres erreurs
            // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
    }
  }

  // Recuperer id de la sous categorie
  const getDataBySousCategorieId = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiSousCat.get(`/${idSousCategorie}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      console.log(data);
      setIdSousCategorie(data.data.id)
      setLibsouscat(data.data.libsouscat)
      setCategoriId(data.data.categoriId)
    } catch (error) { 
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  }

/******************************************************************************************************* */

  // Open Modal Stock
  const handleStock = (id) => {
    setArticleId(id);
    getDataByIdStock();
    handleShowStock();
  }; 

  // Update categorie
  const handleShowupdCategorie = (e) => {
    setShowupdCategorie(true);
    getDataByCategorieId();
  };

  // Update categorie
  const handleShowupdSousCategorie = (e) => {
    setShowupdSousCategorie(true);
    getDataBySousCategorieId();
  };
/******************************************************************************************************** */

  // Update article
  const updateArticle = async () => {
    setView(true)
    // alert(ids)
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("nomarticle", nomarticle);
    formData.append("description", description);
    formData.append("prix", prix);
    formData.append("prix_promo", prix_promo);
    formData.append("img_art", imgArt != null ? imgArt : userInfo.filepreview);
    formData.append("categorieID", categorieID);
    formData.append("sousCategorieID", sousCategorieID);
    try{ 
        await apiArticle.patch(`/${ids}`, formData,
        {
            headers: {
              entetes,
              Authorization: `Bearer ${token}`
            }
        }).then(res => {
          setNomarticle("");
          setDescription("");
          setPrix(0);
          setPrix_promo(0);
          setImg_art("");
          setCategorieID('');
          setSousCategorieID('');
          handleCloseupd();
          setArticle([]);
          allarticle();
          setStatus(true);
          notify('success', 'Mise à jour réussi');
        }).catch((err) => {
            console.log(err);
            notify('warning', "Echec de la mise à jour");
            handleCloseupd();
        });
    } catch (error) {
      setView(false)
        if (error.response && error.response.status === 404) {
            // Gérez spécifiquement l'erreur 404
            // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
            // Gérez les autres erreurs
            // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
    }
  };

  // Update article commander
  const updateArticleCommander = async () => {
    setView(true);
    // alert(idsc)
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("nomHomme", nomHomme);
    formData.append("nomFemme", nomFemme);
    formData.append("tailleHomme", tailleHomme);
    formData.append("tailleFemme", tailleFemme);
    formData.append("description", description);
    formData.append("prixNormal", prixNormal);
    formData.append("montantVerse", montantVerse);
    formData.append("Date", Date);
    formData.append("img_art_commande", img_art_commande != null ? img_art_commande : userInfo.filepreview);
    formData.append("categorieID", categorieID);
    formData.append("sousCategorieID", sousCategorieID);
    try{ 
        await apiArticleCommander.patch(`/${idsc}`, formData,
        {
          headers: {
            entetes,
            Authorization: `Bearer ${token}`
          }
        }).then(res => {
          setNomHomme("");
          setNomFemme(0);
          setTailleHomme(0);
          setTailleFemme("");
          setDescription('');
          setPrixNormal('');
          setMontantVerse('');
          setDate('');
          setCategorieID('');
          setSousCategorieID('');
          setImg_art_commande(null);
          handleCloseupdCmd();
          setArticle([]);
          allarticleCommander();
          setStatus(true);
          notify('success', 'Mise à jour réussi');
        }).catch((err) => {
            console.log(err);
            notify('warning', "Echec de la mise à jour");
            handleCloseupdCmd();
        });
    } catch (error) {
      setView(false)
        if (error.response && error.response.status === 404) {
            // Gérez spécifiquement l'erreur 404
            // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
            // Gérez les autres erreurs
            // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
    }
  };
  
  // update Stock
  const updateStock = async () => {
    setView(true)
    const token = localStorage.getItem('token');
    if (quantiteStock) {
      try {
        const response = await apiStock.patch(`/${articleID}`, { quantiteStock }, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response.data);   
        setQuantiteStock("");
        setArticleId("");
        handleCloseStock();
        allarticle();
        setStatus(true);
        notify('success', `${response.data.message}`);
      } catch (error) {
        setView(false)
        console.error(error);
        setQuantiteStock("");
        setArticleId("");
        handleCloseStock();
        if (error.response && error.response.status === 400 && error.response.data && error.response.data.message && error.response.data.message.includes('existe déjà')) {
          notify('warning', `${error.response.data.message}`);
        } else {
          notify('warning', "Une erreur s'est produite lors de la mise à jour de stock disponible. Assurez-vous que le champ est bien renseigné");
        }
      }
    } else {
      setView(false)
      notify('warning', `Vérifiez que tous les champs ne sont pas vide`);
    }
  };

  // Update de categorie
  const updateCategorie = async (e) => {
    setView(true);
    // alert(ids)
    e.preventDefault();
    const token = localStorage.getItem('token');
    
    await apiCategorie.patch(`/${idcategorie}`, 
    { 
      nomcat
    },
    {
      headers: {
        entete,
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      setView(false)
      notify('success', 'Mise à jour réussi');
      setNomCategorie("");
      allcategorie();
      setStatus(true);
      handleCloseupdCategorie();
    }).catch((err) => {
      setView(false)
        console.log(err);
        notify('warning', "Echec de la mise à jour");
        handleCloseupdCategorie();
    });
  };

  // Update sous categorie
  const updateSousCategorie = async (e) => {
    setView(true);
    // alert(ids)
    e.preventDefault();
    const token = localStorage.getItem('token');
    try{ 
      await apiSousCat.patch(`/${idSousCategorie}`, 
      { 
        libsouscat,
        categoriId
      },
      {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        setView(false)
        handleCloseupdSousCategorie();
        setLibsouscat("");
        allsouscategorie();
        setStatus(true);
        notify('success', 'Mise à jour réussi');
      }).catch((err) => {
        setView(false)
        console.log(err);
        notify('warning', "Echec de la mise à jour");
        handleCloseupdSousCategorie();
      });
    } catch (error) {
      setView(false)
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
};

/********************************************************************************************************* */
  // Delete article
  const deleteConfirm = (ids) => {
    Swal.fire({
        title: 'Êtes-vous sûr?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Confirmer la suppression',
        denyButtonText: 'Non'

    }).then((result) => {
        // Read more about isConfirmed, isDenied below 
        if (result.isConfirmed) {
            deleteArticle(ids);
        } else if (result.isDenied) {
            Swal.fire('Suppression Annuler', '', 'info')
        }
    })
  };
  const deleteArticle = async (ids) => {
      const token = localStorage.getItem('token');
      try{ 
        apiArticle.delete(`/${ids}`, {
              id: ids,
              headers: {
                entete,
                Authorization: `Bearer ${token}`
              }
          }).then(res => {
            // alert(res.data)
            notify('success', 'Suppression réussi');
            setArticle([]);
            allarticle();
          }).catch((err) => {
            console.log(err);
            notify('warning', "Suppression échoué");
          })
      } catch (error) {
          if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
          }
      }
  }

  // Delete article
  const deleteConfirmCommande = (idsc) => {
    Swal.fire({
        title: 'Êtes-vous sûr?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Confirmer la suppression',
        denyButtonText: 'Non'

    }).then((result) => {
        // Read more about isConfirmed, isDenied below 
        if (result.isConfirmed) {
            deleteArticleCommande(idsc);
        } else if (result.isDenied) {
            Swal.fire('Suppression Annuler', '', 'info')
        }
    })
  };
  const deleteArticleCommande = async (idsc) => {
      const token = localStorage.getItem('token');
      try{ 
        apiArticleCommander.delete(`/${idsc}`, {
              id: idsc,
              headers: {
                entete,
                Authorization: `Bearer ${token}`
              }
          }).then(res => {
            // alert(res.data)
            notify('success', 'Suppression réussi');
            setArticle([]);
            allarticleCommander();
          }).catch((err) => {
            console.log(err);
            notify('warning', "Suppression échoué");
          })
      } catch (error) {
          if (error.response && error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
          }
      }
  }

  // Delete categorie
  const deleteConfirmCategorie = (idcategorie) => {
    Swal.fire({
        title: 'Êtes-vous sûr?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Confirmer la suppression',
        denyButtonText: 'Non',

    }).then((result) => {
        // Read more about isConfirmed, isDenied below 
        if (result.isConfirmed) {
            deleteArticleCategorie(idcategorie);
        } else if (result.isDenied) {
            Swal.fire('Suppression Annulée', '', 'info')
        }
    })
  };
  const deleteArticleCategorie = async (idcategorie) => {
    const token = localStorage.getItem('token');
    apiCategorie.delete(`/${idcategorie}`, {
      id: idcategorie,
      headers: {
        entete,
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      // alert(res.data)
      notify('success', 'Suppression réussi');
      allcategorie();
    })
  }

  // Delete Sous Categorie
  const deleteConfirmSousCategorie = (idSousCategorie) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Confirmer la suppression',
      denyButtonText: 'Non',

    }).then((result) => {
      // Read more about isConfirmed, isDenied below 
      if (result.isConfirmed) {
        deleteArticleSousCategorie(idSousCategorie);
      } else if (result.isDenied) {
        Swal.fire('Suppression Annulée', '', 'info')
      }
    })
  };
  const deleteArticleSousCategorie = async (idSousCategorie) => {
    const token = localStorage.getItem('token');
    try{ 
        apiSousCat.delete(`/${idSousCategorie}`, {
          id: idSousCategorie,
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        }).then(res => {
          // alert(res.data)
          notify('success', 'Suppression réussi');
          allcategorie();
          allsouscategorie();
        }).catch((err) => {
          console.log(err);
          notify('warning', "Suppression échoué");
          handleCloseModalSousCategorie();
        })
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Gérez spécifiquement l'erreur 404
        // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
        // Gérez les autres erreurs
        // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  }
  
/*********************************************************************************************************** */

  const handleOpenMenuCategorie = (event, id) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenuCategorie = () => {
    setOpen(null);
  };

  const handleOpenMenuSousCategorie = (event, id) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenuSousCategorie = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
    const newSelecteds = categorie.map((n) => n.nomcat);
        setSelected(newSelecteds);
        return;
    }
    setSelected([]);
  };

  const handleSelectAllClickSousCategorie = (event) => {
    if (event.target.checked) {
      const newSelecteds = sousCat.map((n) => n.libsouscat);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categorie.length) : 0;
  const emptyRowsSousCategorie = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sousCat.length) : 0;

  const filteredUsers = applySortFilter(categorie, getComparator(order, orderBy), filterName);
  const filteredUsersSousCategorie = applySortFilterCategorie(sousCat, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const isNotFoundSousCategorie = !filteredUsersSousCategorie.length && !!filterName;

  const rowsPerPageOptions = [5, 10, 25];

  for (let i = 25; i <= categorie.length; i += 50) {
    rowsPerPageOptions.push(i);
  }
  for (let i = 25; i <= sousCat.length; i += 50) {
    rowsPerPageOptions.push(i);
  }

  const filterArticles = article.filter( (item) => {
    if (filterName === "") {
      return item
    } else if (item.nomarticle.toLowerCase().includes(filterName.toLowerCase())) {
      return item
    }
  });

  const filterArticlesCommander = articlec.filter( (item) => {
    if (filterName === "") {
      return item
    } else if (item.libelleArticleCommande.toLowerCase().includes(filterName.toLowerCase())) {
      return item
    }
  });

  useEffect(() => {
    allcategorie();
    allsouscategorie();
  }, []);

  useEffect(() => {
    getDataById();
  }, [ids]);

  useEffect(() => {
    getDataByIdCmd();
  }, [idsc]);

  useEffect(() => {
    getActifUserById();
  }, [idUser]);

  useEffect(() => {
    allarticle();
    allarticleCommander();
  }, [entrepriseID]);
  

  return (
    <Step1AdminOnboardingContainer>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="one" label="Gestion des Articles" />
          <Tab value="two" label="Gestion des Commandes Personnalisées" />
          <Tab value="tree" label="Gestion des Catégories" />
          <Tab value="four" label="Gestion des Sous Catégories" />
        </Tabs>
      </Box>

      {/* -----------------Tab Gestion des articles START----------------- */}
      {
        value === "one" && (
          <div>
            <ApprovisionementHeaderTabs>
              <UserListToolbar label={"Rechercher un article"} filterName={filterName} onFilterName={handleFilterByName} />
              <label>
                Gestion des articles
              </label>
            </ApprovisionementHeaderTabs>
            <div style={{width: "100%", display: "flex", justifyContent: "flex-end", padding: "8px 0px"}}>
              <Button onClick={handleShow} appearance="primary" startIcon={<Iconify icon="eva:plus-fill" />}>
                Ajouter un article
              </Button>
            </div>

            <div>
              <ProductList 
                articles={filterArticles} 
                onClickUpdate={handleUpdate} 
                onClickDelete={deleteConfirm}
                onClickStock={handleStock}
                onClickView={handleView}
              />
            </div>
          </div>
        )
      }
      {/* -----------------Tab Gestion des articles END----------------- */}

      {/* -----------------Tab Gestion des commandes Personnalisées START----------------- */}
      {
        value === "two" && (
          <div>
            <ApprovisionementHeaderTabs>
              <UserListToolbar label={"Rechercher une commande"} filterName={filterName} onFilterName={handleFilterByName} />
              <label>
                Gestion des commandes Personnalisées
              </label>
            </ApprovisionementHeaderTabs>
            <div style={{width: "100%", display: "flex", justifyContent: "flex-end", padding: "8px 0px"}}>
              <Button onClick={handleShowCmd} appearance="primary" startIcon={<Iconify icon="eva:plus-fill" />}>
                Ajouter un article commandé
              </Button>
            </div>

            <div>
              <ProductListCommander 
                articles={filterArticlesCommander} 
                onClickUpdate={handleUpdateCommander} 
                onClickDelete={deleteConfirmCommande}
                onClickStock={handleStock}
                onClickView={handleViewCommander}
              />
            </div>
          </div>
        )
      }
      {/* -----------------Tab Gestion des commandes Personnalisées END----------------- */}

      {/* -----------------Tab Gestion des Catégories START----------------- */}
      {
        value === "tree" && (
          <div>
            <ApprovisionementHeaderTabs>
              <UserListToolbar label={"Rechercher une catégorie"} filterName={filterName} onFilterName={handleFilterByName} />
              <label>
                Gestion des catégories
              </label>
            </ApprovisionementHeaderTabs>
            <div style={{width: "100%", display: "flex", justifyContent: "flex-end", padding: "8px 0px"}}>
              <Button onClick={handleOpenModalCategorie} appearance="primary" startIcon={<Iconify icon="eva:plus-fill" />}>
                Ajouter une catégorie
              </Button>
            </div>

            <div>
              <Scrollbar sx={{ height: "auto", overflow: "hidden", overflowY: "scroll"}}>
                <TableContainer sx={{ minWidth: "100%" }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD_CATEGORIE}
                      rowCount={categorie.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, nomcat } = row;
                        const selectedUser = selected.indexOf(nomcat) !== -1;

                        return(
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Box height={16} />
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {nomcat}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenuCategorie(event, id); setIdCategorie(id) }}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                            >
                                <Typography variant="h6" paragraph>
                                  Pas trouvé
                                </Typography>

                                <Typography variant="body2">
                                  Aucun résultat trouvé pour &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                                </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  <TablePagination
                    sx={{width: "100%"}}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={categorie.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Scrollbar>
            </div>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenuCategorie}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem onClick={() => { handleShowupdCategorie(); handleCloseMenuCategorie()}}>
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                Modifier
              </MenuItem>

              <MenuItem onClick={() => { deleteConfirmCategorie(idcategorie); handleCloseMenuCategorie() }} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                Supprimer
              </MenuItem>
            </Popover>
          </div>
        )
      }
      {/* -----------------Tab Gestion des Catégories END----------------- */}

      
      {/* -----------------Tab Gestion des Sous Catégories START----------------- */}
      {
        value === "four" && (
          <div>
            <ApprovisionementHeaderTabs>
              <UserListToolbar label={"Rechercher une sous catégorie"} filterName={filterName} onFilterName={handleFilterByName} />
              <label>
                Gestion des sous catégories
              </label>
            </ApprovisionementHeaderTabs>
            <div style={{width: "100%", display: "flex", justifyContent: "flex-end", padding: "8px 0px"}}>
              <Button onClick={handleOpenModalSousCategorie} appearance="primary" startIcon={<Iconify icon="eva:plus-fill" />}>
                Ajouter une sous catégorie
              </Button>
            </div>

            <div>
              <Scrollbar sx={{ height: "auto", overflow: "hidden", overflowY: "scroll"}}>
                <TableContainer sx={{ minWidth: "100%" }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD_SOUS_CATEGORIE}
                      rowCount={sousCat.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClickSousCategorie}
                    />
                    <TableBody>
                      {filteredUsersSousCategorie.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, libsouscat, Categorie } = row;
                        const selectedUser = selected.indexOf(libsouscat) !== -1;

                        return(
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Box height={16} />
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {libsouscat}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {Categorie && Categorie.nomcat ? Categorie.nomcat : ''}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenuSousCategorie(event, id); setIdSousCategorie(id) }}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                            >
                                <Typography variant="h6" paragraph>
                                  Pas trouvé
                                </Typography>

                                <Typography variant="body2">
                                  Aucun résultat trouvé pour &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                                </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  <TablePagination
                    sx={{width: "100%"}}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={sousCat.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Scrollbar>
            </div>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenuSousCategorie}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem onClick={() => { handleShowupdSousCategorie(); handleCloseMenuSousCategorie()}}>
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                Modifier
              </MenuItem>

              <MenuItem onClick={() => { deleteConfirmSousCategorie(idSousCategorie); handleCloseMenuSousCategorie() }} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                Supprimer
              </MenuItem>
            </Popover>
          </div>
        )
      }
      {/* -----------------Tab Gestion des Sous Catégories END----------------- */}
      
      {/* MODAL STOCK */}
      <Modal
        open={showStock}
        onClose={handleCloseStock}
      >
        <Modal.Header>
          <Modal.Title>Modifier Stock</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form fluid onSubmit={updateStock} style={{width: "100%", backgroundColor: "#FFFFFF"}}>
            <Form.Group controlId="libsouscat">
              <Form.ControlLabel>Quantité</Form.ControlLabel>
              <Form.Control 
                name="libsouscat" 
                placeholder="Quantité"
                value={quantiteStock}
                onChange={(value) => setQuantiteStock(value)}
                type='number'
              />
            </Form.Group>
            <Modal.Footer>
              {
                view != true ? (
                  <Button type='submit' appearance="primary">
                    Ok
                  </Button>
                ) : (
                  <Button disabled appearance="primary">
                    Mise à jour en cours...
                  </Button>
                )
              }
              <Button onClick={handleCloseStock} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* MODAL ADD ARTICLE */}
      <Modal
        open={show}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Ajouter un article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid onSubmit={ajoutArticle} style={{width: "100%", backgroundColor: "#FFFFFF"}}>
            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={categorieID}
                onChange={(e) => setCategorieID(e.target.value)}
                label="Catégorie"
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une catégorie</em>) : (<em>Chargement des catégories</em>)}
                </MenuItem>
                {categorie.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.nomcat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Sous Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sousCategorieID}
                onChange={(e) => setSousCategorieID(e.target.value)}
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une sous catégorie</em>) : (<em>Chargement des sous catégories</em>)}
                </MenuItem>
                {sousCat.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.libsouscat}</MenuItem>
                ))}
              </Select>
            </FormControl>


            <TextField
              name="nomarticle"
              type="text"
              label="Nom article"
              value={nomarticle}
              onChange={(e) => setNomarticle(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="description"
              type="text"
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Prix article</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={prix}
                onChange={(e) => setPrix(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                required
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Prix Promo article</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={prix_promo}
                onChange={(e) => setPrix_promo(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                
              />
            </FormControl>

            <TextField
              name="img_art"
              type="file"
              label="Image article"
              variant="standard"
              onChange={(e) => {
                setImg_art(e.target.files[0]);
                setuserInfo({ 
                  ...userInfo, 
                  filepreview: URL.createObjectURL(e.target.files[0]) 
                });
              }} 
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <Box sx={{width: "100%", marginBottom: "20px"}}>
              {userInfo.filepreview !== null ? (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={userInfo.filepreview} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              ) : (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={Image} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              )}
            </Box>
            <Modal.Footer>
              {
                view != true ? (
                  <Button type='submit' appearance="primary">
                    Ajouter
                  </Button>
                ) : (
                  <Button disabled appearance="primary">
                    Ajout en cours...
                  </Button>
                )
              }
              <Button onClick={handleClose} appearance="subtle">
                Fermer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* MODAL ADD ARTICLE COMMANDER */}
      <Modal
        open={showCmd}
        onClose={handleCloseCmd}
      >
        <Modal.Header>
          <Modal.Title>Ajouter une commande</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid onSubmit={ajoutArticleCommander} style={{width: "100%", backgroundColor: "#FFFFFF"}}>
            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={categorieID}
                onChange={(e) => setCategorieID(e.target.value)}
                label="Catégorie"
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une catégorie</em>) : (<em>Chargement des catégories</em>)}
                </MenuItem>
                {categorie.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.nomcat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Sous Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sousCategorieID}
                onChange={(e) => setSousCategorieID(e.target.value)}
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une sous catégorie</em>) : (<em>Chargement des sous catégories</em>)}
                </MenuItem>
                {sousCat.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.libsouscat}</MenuItem>
                ))}
              </Select>
            </FormControl>


            <TextField
              name="nomHomme"
              type="text"
              label="Nom Homme"
              value={nomHomme}
              onChange={(e) => setNomHomme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="nomFemme"
              type="text"
              label="Nom Femme"
              value={nomFemme}
              onChange={(e) => setNomFemme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="description"
              type="text"
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="tailleHomme"
              type="number"
              label="Taille Homme"
              value={tailleHomme}
              onChange={(e) => setTailleHomme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />
            
            <TextField
              name="tailleFemme"
              type="number"
              label="Taille Femme"
              value={tailleFemme}
              onChange={(e) => setTailleFemme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="Date"
              type="date"
              label="Date"
              value={Date}
              onChange={(e) => setDate(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Prix normal</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={prixNormal}
                onChange={(e) => setPrixNormal(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                required
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Montant Versé</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={montantVerse}
                onChange={(e) => setMontantVerse(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                
              />
            </FormControl>

            <TextField
              name="img_art_commande"
              type="file"
              label="Image article commander"
              variant="standard"
              onChange={(e) => {
                setImg_art_commande(e.target.files[0]);
                setuserInfo({ 
                  ...userInfo, 
                  filepreview: URL.createObjectURL(e.target.files[0]) 
                });
              }} 
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <Box sx={{width: "100%", marginBottom: "20px"}}>
              {userInfo.filepreview !== null ? (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={`${userInfo.filepreview}`} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              ) : (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={Image} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              )}
            </Box>
            <Modal.Footer>
              {
                view != true ? (
                  <Button type='submit' appearance="primary">
                    Ajouter
                  </Button>
                ) : (
                  <Button disabled appearance="primary">
                    Ajout en cours...
                  </Button>
                )
              }
              <Button onClick={handleCloseCmd} appearance="subtle">
                Fermer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* MODAL UPDATE ARTICLE */}
      <Modal
        open={showupd}
        onClose={handleCloseupd}
      >
        <Modal.Header>
          <Modal.Title>Modifier un article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid onSubmit={updateArticle} style={{width: "100%", backgroundColor: "#FFFFFF"}}>
            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={categorieID}
                onChange={(e) => setCategorieID(e.target.value)}
                label="Catégorie"
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une catégorie</em>) : (<em>Chargement des catégories</em>)}
                </MenuItem>
                {categorie.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.nomcat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Sous Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sousCategorieID}
                onChange={(e) => setSousCategorieID(e.target.value)}
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une sous catégorie</em>) : (<em>Chargement des souscatégories</em>)}
                </MenuItem>
                {sousCat.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.libsouscat}</MenuItem>
                ))}
              </Select>
            </FormControl>


            <TextField
              name="nomarticle"
              type="text"
              label="Nom article"
              value={nomarticle}
              onChange={(e) => setNomarticle(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="description"
              type="text"
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Prix article</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={prix}
                onChange={(e) => setPrix(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                required
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Prix Promo article</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={prix_promo}
                onChange={(e) => setPrix_promo(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                
              />
            </FormControl>

            <TextField
              name="img_art"
              type="file"
              label="Image article"
              variant="standard"
              onChange={(e) => {
                setImg_art(e.target.files[0]);
                setuserInfo({ 
                  ...userInfo, 
                  filepreview: URL.createObjectURL(e.target.files[0]) 
                });
              }} 
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <Box sx={{width: "100%", marginBottom: "20px"}}>
              {userInfo.filepreview !== null ? (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    {
                      imgArt != null ? (
                        <img src={`${userInfo.filepreview}`} alt='img' width="100%" height="100%"/>
                      ) : (
                        <img src={`${url}/imageArticle/${userInfo.filepreview}`} alt='img' width="100%" height="100%"/>
                      )
                    }
                  </div>
                </div>
              ) : (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={Image} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              )}
            </Box>
            <Modal.Footer>
              {
                view != true ? (
                  <Button type='submit' appearance="primary">
                    Modifier
                  </Button>
                ) : (
                  <Button type='submit' appearance="primary">
                    Mise à jour en cours...
                  </Button>
                )
              }
              <Button onClick={handleCloseupd} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* MODAL UPDATE ARTICLE COMMANDER */}
      <Modal
        open={showupdCmd}
        onClose={handleCloseupdCmd}
      >
        <Modal.Header>
          <Modal.Title>Modifier une commande</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid onSubmit={updateArticleCommander} style={{width: "100%", backgroundColor: "#FFFFFF"}}>
            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={categorieID}
                onChange={(e) => setCategorieID(e.target.value)}
                label="Catégorie"
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une catégorie</em>) : (<em>Chargement des catégories</em>)}
                </MenuItem>
                {categorie.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.nomcat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ width: "100%", marginBottom: "20px" }}>
              <InputLabel id="demo-simple-select-standard-label">Sous Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sousCategorieID}
                onChange={(e) => setSousCategorieID(e.target.value)}
                required
              >
                <MenuItem value="0">
                  {categorie ? (<em>Choisir une sous catégorie</em>) : (<em>Chargement des souscatégories</em>)}
                </MenuItem>
                {sousCat.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{item.libsouscat}</MenuItem>
                ))}
              </Select>
            </FormControl>


            <TextField
              name="nomHomme"
              type="text"
              label="Nom Homme"
              value={nomHomme}
              onChange={(e) => setNomHomme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="nomFemme"
              type="text"
              label="Nom Femme"
              value={nomFemme}
              onChange={(e) => setNomFemme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="description"
              type="text"
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="tailleHomme"
              type="number"
              label="Taille Homme"
              value={tailleHomme}
              onChange={(e) => setTailleHomme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />
            
            <TextField
              name="tailleFemme"
              type="number"
              label="Taille Femme"
              value={tailleFemme}
              onChange={(e) => setTailleFemme(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <TextField
              name="Date"
              type="date"
              label="Date"
              value={Date}
              onChange={(e) => setDate(e.target.value)}
              required
              variant="standard"
              sx={{width: "100%", marginBottom: "20px"}}
            />

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Prix normal</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={prixNormal}
                onChange={(e) => setPrixNormal(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                required
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "20px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Montant Versé</InputLabel>
              <Input
                id="standard-adornment-amount"
                type="number"
                value={montantVerse}
                onChange={(e) => setMontantVerse(e.target.value)}
                minRows={0}
                endAdornment={<InputAdornment position="start">FCFA</InputAdornment>}
                
              />
            </FormControl>

            <TextField
              name="img_art_commande"
              type="file"
              label="Image article commander"
              variant="standard"
              onChange={(e) => {
                setImg_art_commande(e.target.files[0]);
                setuserInfo({ 
                  ...userInfo, 
                  filepreview: URL.createObjectURL(e.target.files[0]) 
                });
              }} 
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <Box sx={{width: "100%", marginBottom: "20px"}}>
              {userInfo.filepreview !== null ? (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    {
                      img_art_commande != null ? (
                        <img src={`${userInfo.filepreview}`} alt='img' width="100%" height="100%"/>
                      ) : (
                        <img src={`${url}/imageArticleCommander/${userInfo.filepreview}`} alt='img' width="100%" height="100%"/>
                      )
                    }
                  </div>
                </div>
              ) : (
                <div className='sectionViewImage'>
                  <div style={{width: "100%", height: "80%", borderRadius: "10px", overflow: 'hidden'}}>
                    <img src={Image} alt='img' width="100%" height="100%"/>
                  </div>
                </div>
              )}
            </Box>
            <Modal.Footer>
              {
                view != true ? (
                  <Button type='submit' appearance="primary">
                    Modifier
                  </Button>
                ) : (
                  <Button disabled appearance="primary">
                    Mise à jour en cours...
                  </Button>
                )
              }
              <Button onClick={handleCloseupdCmd} appearance="subtle">
                Fermer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* MODAL ADD CATEGORIE */}
      <Modal
        open={openModalCategorie}
        onClose={handleCloseModalCategorie}
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Ajouter une Catégorie
            </Typography>
            <TextField
              name="nomcat"
              type='text'
              label="Nom catégorie"
              value={nomcat}
              onChange={(e) => setNomCategorie(e.target.value)}
              required
              autoFocus
              autoCapitalize={true}
            />
            <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
              {
                view != true ? (
                  <LoadingButton  size="medium" type="submit" variant="contained" onClick={ajoutCategorie}>
                    Ajouter
                  </LoadingButton>
                ) : (
                  <LoadingButton  size="medium" disabled variant="contained">
                    Ajout en cours...
                  </LoadingButton>
                )
              }
              <LoadingButton  size="medium" variant="contained" sx={{backgroundColor: "gray"}} onClick={handleCloseModalCategorie}>
                Fermer
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL UPDATE CATEGORIE */}
      <Modal
        open={showupdCategorie}
        onClose={handleCloseupdCategorie}
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Modifier une Catégorie
            </Typography>
            <TextField
              name="nomcat"
              type='text'
              label="Nom catégorie"
              value={nomcat}
              onChange={(e) => setNomCategorie(e.target.value)}
              required
            />
            <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
              {
                view != true ? (
                  <LoadingButton size="medium" type="submit" variant="contained" onClick={updateCategorie}>
                    Modifier
                  </LoadingButton>
                ) : (
                  <LoadingButton size="medium" disabled variant="contained">
                    Mise à jour en cours...
                  </LoadingButton>
                )
              }
              <LoadingButton size="medium" variant="contained" onClick={handleCloseupdCategorie}>
                Fermer
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL ADD SOUS-CATEGORIE */}
      <Modal
        open={openModalSousCategorie}
        onClose={handleCloseModalSousCategorie}
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Ajouter un Sous Catégorie
            </Typography>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={categoriId} 
                  onChange={(e) => setCategoriId(e.target.value)} 
                  label="Catégorie"
                  required
                >
                  <MenuItem value="0">
                    {categorie ? (<em>Choisir une catégorie</em>) : (<em>Chargement des catégories</em>)}
                  </MenuItem>
                  {
                    categorie.map((item, index) =>(
                      <MenuItem key={index} value={item.id}>{item.nomcat}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              <TextField
                name="libsouscat"
                type='text'
                label="Nom Sous catégorie"
                value={libsouscat}
                onChange={(e) => setLibsouscat(e.target.value)}
                required
                autoFocus
              />
              <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                {
                  view != true ? (
                    <LoadingButton size="medium" type="submit" variant="contained" onClick={ajoutSousCategorie}>
                      Ajouter
                    </LoadingButton>
                  ) : (
                    <LoadingButton size="medium" disabled variant="contained" >
                      Ajout en cours...
                    </LoadingButton>
                  )
                }
                <LoadingButton size="medium" variant="contained" onClick={handleCloseModalSousCategorie}>
                  Fermer
                </LoadingButton>
              </Box>
          </Stack>
        </Box>
      </Modal> 

      {/* MODAL UPDATE SOUS-CATEGORIE */}
      <Modal
        open={showupdSousCategorie}
        onClose={handleCloseupdSousCategorie}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Modifier une Sous Catégorie
            </Typography>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">Catégorie</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={categoriId} 
                onChange={(e) => setCategoriId(e.target.value)} 
                label="Catégorie"
                required
              >
                  <MenuItem value="0">
                    {categorie ? (<em>Choisir une catégorie</em>) : (<em>Chargement des catégories</em>)}
                  </MenuItem>
                  {
                    categorie.map((item, index) =>(
                      <MenuItem key={index} value={item.id}>{item.nomcat}</MenuItem>
                    ))
                  }
              </Select>
            </FormControl>

            <TextField
              name="libsouscat"
              type='text'
              label="Nom Sous catégorie"
              value={libsouscat}
              onChange={(e) => setLibsouscat(e.target.value)}
              required
            />

            <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
              {
                view != true ? (
                  <LoadingButton size="medium" type="submit" variant="contained" onClick={updateSousCategorie}>
                    Modifier
                  </LoadingButton>
                ) : (
                  <LoadingButton size="medium" disabled variant="contained">
                    Mise à jour en cours...
                  </LoadingButton>
                )
              }
              <LoadingButton size="medium" variant="contained" onClick={handleCloseupdSousCategorie}>
                Fermer
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL OPEN VIEW ARTICLE */}
      <Modal
        open={showView}
        onClose={handleCloseView}
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Détail article
            </Typography>
            <Card>
              <Box sx={{ pt: '30%', position: 'relative' }}>
                <StyledProductImg alt={nomarticle} src={`${url}/imageArticle/${userInfo.filepreview}`} />
              </Box>
              <Stack spacing={1} sx={{ p: 2 }}>
                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    {nomOrg}
                  </Typography>
                  {ref}
                </Typography>
                <Link color="inherit" underline="none" display="flex" flexWrap="wrap-reverse" alignItems="center" justifyContent="space-between" >
                  <Typography variant="subtitle2" noWrap>
                    {nomarticle}
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    <Label color={(stockArt <= 10 && 'error') || 'success'}>{stockArt} Stock</Label>
                  </Typography>
                </Link> 
        
                { prix_promo > "0" ?
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    
                    <Typography variant="subtitle1">
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          color: 'text.disabled',
                          textDecoration: 'line-through',
                        }}
                      >
                        {prix && fCurrencyDot(prix)} F CFA
                      </Typography>
                      &nbsp;
                      {fCurrencyDot(prix_promo)} F CFA
                    </Typography>
                  </Stack>
                  :
                  <Typography style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", color: "#000000"}}>
                    {fCurrencyDot(prix)} F CFA
                  </Typography>
                }
                <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
                  {description}
                </div>
              </Stack>
            </Card>
            <Box sx={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
              <LoadingButton  size="medium" variant="contained" sx={{backgroundColor: "gray"}} onClick={handleCloseView}>
                Fermer
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL OPEN VIEW ARTICLE COMMANDER */}
      <Modal
        open={showViewCommander}
        onClose={handleCloseViewCommander}
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Détail article
            </Typography>
            <Card>
              <Box sx={{ pt: '30%', position: 'relative' }}>
                <StyledProductImg alt={libelleArticleCommande} src={`${url}/imageArticleCommander/${userInfo.filepreview}`} />
              </Box>
              <Stack spacing={1} sx={{ p: 2 }}>
                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    {nomOrgCmd}
                  </Typography>
                  {refCmd}
                </Typography>
                <Link color="inherit" underline="none" display="flex" flexWrap="wrap-reverse" alignItems="center" justifyContent="space-between" >
                  <Typography variant="subtitle2" noWrap>
                    {libelleArticleCommande}
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    <Label color={(etatArtCmd != "Commander" && 'error') || 'success'}>{sentenceCase(etatArtCmd)}</Label>
                  </Typography>
                </Link> 
        
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        color: 'text.disabled',
                      }}
                    >
                      {prixNormal && fCurrencyDot(prixNormal)} F CFA
                    </Typography>
                    &nbsp;
                    {
                      montantRestant >= 0 ? (
                        <span style={{color: 'red'}}>{fCurrencyDot(montantRestant)} F CFA</span>
                      ) : (
                        <span style={{color: 'green'}}>solder</span>
                      )
                    }
                  </Typography>
                </Stack>
                <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
                  {description}
                </div>

                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    Homme: {nomHomme} 
                  </Typography>
                  Taille Homme: {tailleHomme} 
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    Femme: {nomFemme}
                  </Typography>
                  Taille Femme: {tailleFemme}
                </Typography>
                <br/>
                <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
                  Date limite pour délivrer la commande au client: {dateCmd}
                </div>
              </Stack>
            </Card>
            <Box sx={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
              <LoadingButton  size="medium" variant="contained" sx={{backgroundColor: "gray"}} onClick={handleCloseViewCommander}>
                Fermer
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </Step1AdminOnboardingContainer>
  )
}
export default Approvisionement;

