import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";

export const ClockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 130%;
    color: #d5bdaf;
    cursor: default;
    user-select: none;
    font-family: 'Times New Roman', Times, serif;

    //tablet and desktop display
    @media ${devices.tablet} {
      font-size: 1050%;
    }
  }
`;
