import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";
import { Toggle } from "rsuite";
import DatePicker from "rsuite/DatePicker";
import Popover from "rsuite/Popover";

export const ContainerDays = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  gap: var(--gap-16px);
  width: var(--width-100);
  overflow: hidden;
  overflow-y: scroll;
`;

export const Days = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: var(--gap-24px);
  height: fit-content;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--gap-4px);

  @media ${devices.tablet} {
    flex-direction: row;
  }
`;

export const ContainerToggles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: var(--gap-8px);
  width: 120px;
  /* overflow: hidden; */

  .days {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: var(--body-semi-bold-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-130);
    color: var(--brand-500S);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  .rs-toggle .rs-toggle-presentation {
    height: 20px;
    min-width: 31px;
    border-radius: 16px;
  }

  .rs-toggle .rs-toggle-presentation::after {
    width: 14px;
    height: 14px;
    border-radius: 100px;
  }

  .rs-toggle.rs-toggle-checked .rs-toggle-presentation:after {
    margin-left: -17px;
  }

  .rs-toggle-checked .rs-toggle-presentation {
    background-color: var(--primary-color);
  }
`;

// *********************Toggle activate*********************

export const Times = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: var(--gap-24px);
`;
export const ContainerOperation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;

  @media ${devices.tablet} {
    flex-direction: row;
    gap: var(--gap-24px);
  }
`;

export const SelectTimes = styled(DatePicker)`
  .rs-btn-close {
    display: none;
  }

  .rs-picker-toggle-value {
    color: var(--header-text-color) !important;
    font-weight: 500;
  }
`;

export const Toggles = styled(Toggle)`
  // do not delete this
`;

export const Btn = styled.button`
  background-color: transparent;
`;

// *********************Toggle disabled*********************

export const ContainerDisabled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: var(--gap-24px);
  width: fit-content;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: var(--body-regular-weight);
  font-size: var(--body-sm);
  line-height: var(--line-height-130);
  color: var(--brand-500S);
`;

// *********************Copy Popover*********************

export const DateCopyPopover = styled(Popover)`
  /* border: 1px solid red; */
  padding: 16px 16px 0 16px;
  max-width: 250px;
`;

export const DateCopyPopoverHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const DateCopyPopoverHeaderTitle = styled.h4`
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: var(--body-semi-bold-weight);
  font-size: var(--body-lg);
  line-height: var(--line-height-130);
  color: var(--header-text-color);
`;

export const DateCopyPopoverHeaderDes = styled.h4`
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: var(--body-regular-weight);
  font-size: var(--body-sm);
  line-height: var(--line-height-130);
  color: var(--brand-100S);
`;

export const DateCopyPopoverBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;

  ${(props) =>
    props.filter &&
    css`
        gap: 4px
    `}
`;

export const DateCopyPopoverBodyDays = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: var(--body-semi-bold-weight);
    font-size: var(--body-md);
    line-height: var(--line-height-130);
    color: var(--header-text-color);
  }
`;

export const DateCopyPopoverFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px 0px;
  gap: 16px !important;
  border-top: 1px solid var(--input-border);
  margin-top: 8px;
`;
