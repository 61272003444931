import styled from "styled-components";


export const SelectBox = styled.select`
    padding: var(--spacing-16px);
    border-radius: var(--border-radius-md);
    font-size: var(-body-sm);
    line-height: var(--line-height-130);
    border: 1px solid var(--input-border);
    color: var(--palceholder);
    background-color: var(--transparent-background);
    width: var(--width-250px);
    cursor: pointer;
    &:hover {
        border: 1px solid var(--brand-hover);
    }
    &:focus-visible {
        outline: var(--brand-hover) auto 1px;
        outline-color: var(--brand-hover);
        outline-style: auto;
        outline-width: 1px;
    }
    &:disabled {
        background-color: var(--surface-50);
        color: var(--palceholder);
        cursor: none;
        &:hover {
            border: 1px solid var(--input-border);
        }
    }
`

export const OptionSelect = styled.option`
    font-size: var(-body-sm);
    line-height: var(--line-height-130);
    color: var(--palceholder);
    background-color: var(--transparent-background);
`

export const SelectHint = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-4px);
`
export const SelectHintSpan = styled.span`
    font-weight: var(--body-regular-weight);
    font-size: var(--body-xs);
    line-height: var(--line-height-130);
    text-align: center;
    color: var(--brand-500S);
`