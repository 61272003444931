import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCardCommanderVente from './ProductCardCommanderVente';

// ----------------------------------------------------------------------

ProductListCommanderVente.propTypes = {
  articles: PropTypes.array.isRequired,
  onClickVente: PropTypes.func.isRequired,
  onClickView: PropTypes.func.isRequired,
};

export default function ProductListCommanderVente({ articles, onClickVente, onClickView, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {articles.map((article) => (
        <Grid key={article.id} item xs={12} sm={6} md={3}>
          <ShopProductCardCommanderVente 
            article={article} 
            onClickVente={onClickVente} 
            onClickView={onClickView}
          />
        </Grid>
      ))}
    </Grid>
  );
}
