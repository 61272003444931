import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";
import { Modal } from "rsuite";

export const ApprovisionementHeaderTabs = styled.div`
    border-bottom: 1px solid gray;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-item: center;
    flex-wrap: wrap;
    padding-bottom: 4px;
    @media ${devices.laptop} {
        flex-direction: row;
    }

    label {
        font-size: 20px;
        font-weight: bolder;
        @media ${devices.laptop} {
            font-size: 30px;
        }
    }
`;

export const ModalForm = styled(Modal)`
    background-color: #FFFFFF
`;