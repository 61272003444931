import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';

// Component
import { Grid } from '@mui/material';
import { ContainerTopDashboard, ButtonDashboard, ContainerCenterDashboard } from '../TableauDeBord/TableauDeBordStyles';
import { Inputs } from '../../Components/InputPassword/InputPasswordStyles';
import { InputContainer } from '../../Components/Inputs/InputStyles';
import Scrollbar from '../../Components/scrollbar/Scrollbar';
import { UserListHead } from '../TableauDeBord/Section/user';
import Label from '../../Components/label';

import { Step1AdminOnboardingContainer } from '../../Components/Oboarding/Admin/AdminOnboardingStyle';

import { Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer,
  TablePagination, Box, Modal, TextField, Select, InputLabel, FormControl
} from '@mui/material';

// API 
import { entete, apiSousCat, apiCategorie, apiPersonnel, 
  apiCreateEntrepriseActif, apiArticle, apiVente, notify, 
  apiIDEntreprise
} from '../../Components/API/Api';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'ref_vente', label: 'Reference Vente', alignRight: false },
  { id: 'ref_article', label: 'Reference Article', alignRight: false },
  { id: 'nomVendeur', label: 'Nom du Vendeur', alignRight: false },
  { id: 'montantVente', label: 'Prix', alignRight: false },
  { id: 'etat', label: 'Status', alignRight: false },
  { id: 'dateVente', label: 'Date & Heure', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nomVendeur.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------


const Historique = () => {
  const typeLocal = localStorage.getItem('type');
  const idUser = localStorage.getItem('id');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('nomVendeur');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedDateDebut, setSelectedDateDebut] = useState('');
  const [selectedDateFin, setSelectedDateFin] = useState('');
  const [vente, setVente] = useState([]);
  const [entrepriseID, setEntrepriseID] = useState("");

  const handleDateDebutChange = (event) => {
    setSelectedDateDebut(event.target.value);
  };
  const handleDateFinChange = (event) => {
    setSelectedDateFin(event.target.value);
  };

  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getActifUserById = async () => {
    const token = localStorage.getItem('token');
    try {
      if (typeLocal != "admin") {
        const { data } = await apiIDEntreprise.get(`/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data.entrepriseID)
      } else {
        const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].id)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  const AllVente = () => {
    getActifUserById();
    const token = localStorage.getItem('token');
    try {
      apiVente.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setVente(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
              notify('warning', `Erreur lors du chargement de la page`);
              setVente([]);
            } else if (err.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  const generateList = () => {
    // Appelez allVente avec la date sélectionnée
    const dateDebut = selectedDateDebut;
    const dateFin = selectedDateFin;
    const token = localStorage.getItem('token');
    try {
      apiVente.get(`/entreprise/${entrepriseID}/date/${dateDebut}/${dateFin}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setVente(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
              notify('warning', `Aucune vente effectuer pendant ces périodes: ${dateDebut} - ${dateFin}`);
              setVente([]);
            } else if (err.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
            } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  };

  const downloadListPDF = () => {
    const dateDebut = selectedDateDebut;
    const dateFin = selectedDateFin;
    const token = localStorage.getItem('token');
  
    apiVente
      .get(`/entreprise/${entrepriseID}/date/${dateDebut}/${dateFin}/pdf`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Spécifiez le type de réponse comme un blob
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `ventes_${dateDebut}_${dateFin}.pdf`; // Nom du fichier PDF
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch((err) => {
        if (err.response && err.response.status === 404) {
          console.log("Erreur 404 : Page non trouvée");
          notify('warning', `Aucune vente effectuée à cette date: ${dateDebut} à ${dateFin}`);
          setVente([]);
        } else if (err.response && err.response.status === 401) {
          console.log("Erreur 401 : Requête non autorisée");
        } else {
          console.log(err);
        }
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vente.map((n) => n.nomcat);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vente.length) : 0;

  const filteredUsers = applySortFilter(vente, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const rowsPerPageOptions = [5, 10, 25];

  for (let i = 25; i <= vente.length; i += 50) {
    rowsPerPageOptions.push(i);
  }

  useEffect(() => {
    AllVente();
  }, []);

  return (
    <Step1AdminOnboardingContainer>
      <>
        <ContainerCenterDashboard>
          <Box sx={{ width: "100%", marginBottom: "12px", '@media (min-width: 600px)': { width: "30%" } }}>
            <Inputs
              type="date"
              value={selectedDateDebut}
              onChange={handleDateDebutChange}
            />
          </Box>
          <Box sx={{ width: "100%", marginBottom: "12px", '@media (min-width: 600px)': { width: "30%" } }}>
            <Inputs
              type="date"
              value={selectedDateFin}
              onChange={handleDateFinChange}
            />
          </Box>
          <Box className='btnGenerate'>
            <ButtonDashboard onClick={generateList}>
              Générer la liste
            </ButtonDashboard>
          </Box>
          <Box className='btnGenerate'>
            <ButtonDashboard onClick={downloadListPDF}>
              Télécharger la liste
            </ButtonDashboard>
          </Box>
        </ContainerCenterDashboard>
        
        {
          selectedDateDebut != "" && selectedDateFin != "" ? (
            <div className='title'>
              Historique des ventes du jours <strong>{selectedDateDebut} - {selectedDateFin}</strong>
            </div>
          ) : (
            <div></div>
          )
        }
        <div>
          <Scrollbar>
            <TableContainer sx={{ minWidth: "100%" }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={vente.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, ref_vente, ref_article, nomVendeur, etat, dateVente, montantVente } = row;
                    const selectedUser = selected.indexOf(nomVendeur) !== -1;
                    
                    return (
                      <>
                        {
                          vente.length != 0 ? (
                            <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                              <TableCell padding="checkbox">
                                <Box height={16} />
                              </TableCell>
      
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {ref_vente}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {ref_article}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {nomVendeur}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {montantVente}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    <Label color={(etat === 'vendu' && 'secondary') || 'error'}>{sentenceCase(etat)}</Label>
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    Ce {new Date(dateVente).toISOString().replace('T', ' à ').replace(/\.000Z$/, '')}
                                  </Typography>
                                </Stack>
                              </TableCell>
      
                              <TableCell align="right">
                                <IconButton size="large" color="inherit">
                                  {/*<Iconify icon={'eva:more-vertical-fill'} />*/}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <>
                              <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                <TableCell padding="checkbox">
                                  <Box height={16} />
                                </TableCell>
        
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" textTransform="capitalize" spacing={4}>
                                    <Typography variant="subtitle2" noWrap>
                                      Pas de vente effectuer pendant ces jours {selectedDateDebut} - {selectedDateFin}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                
        
                                <TableCell align="right">
                                  <IconButton size="large" color="inherit">
                                    {/*<Iconify icon={'eva:more-vertical-fill'} />*/}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        }
                      </>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Pas trouvé
                          </Typography>
  
                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </div>
      </>
    </Step1AdminOnboardingContainer>
  )
}
export default Historique;