import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Components
import { Badge, Whisper, Progress, Drawer } from "rsuite";
import { ButtonStyles } from "../Buttons/ButtonStyles";
import Step1AdminOnboarding from "../Oboarding/Admin/Step1AdminOnboarding";
import Step2AdminOnboarding from "../Oboarding/Admin/Step2AdminOnboarding";
import Step3AdminOnboarding from "../Oboarding/Admin/Step3AdminOnboarding";
import Step4AdminOnboarding from "../Oboarding/Admin/Step4AdminOnboarding";
import Step5AdminOnboarding from "../Oboarding/Admin/Step5AdminOnboarding";
import Step6AdminOnboarding from "../Oboarding/Admin/Step6AdminOnboarding";
import Step7AdminOnboarding from "../Oboarding/Admin/Step7AdminOnboarding";
import Step8AdminOnboarding from "../Oboarding/Admin/Step8AdminOnboarding";
import Step9AdminOnboarding from "../Oboarding/Admin/Step9AdminOnboarding";
import Step10AdminOnboarding from "../Oboarding/Admin/Step10AdminOnboarding";

//Styles
import {
  HeaderContainer,
  HeaderContainerRight,
  HeaderContainerLeft,
  OrgBrand,
  OrgSelect,
  SetupGuide,
  NotificationButton,
  ProfileDropDownContainer,
  UserProfileDropdownContainer,
  UserProfileDropdownContainerData,
  UserProfileDropdownName,
  UserProfileDropdownEmail,
  UserProfileDropdownMenuContainer,
  UserProfileDropdownMenu,
  OrgMobileMenu,
  UserProfile,
  Profile,
  HelpfullTips,
  OnboardingDrawer, NameOrg
} from "./HeaderStyle";

//Svg
import ArrowDownSvg from "../../assets/Svg/ArrowDownSvg";
import Guide from "../../assets/Svg/Guide";
import Notification from "../../assets/Svg/Notification";
import ProfileSvg from "../../assets/Svg/ProfileSvg";
import SwitchSvg from "../../assets/Svg/SwitchSvg";
import LogoutSvg from "../../assets/Svg/LogoutSvg";
import OrganizationSvg from "../../assets/Svg/OrganizationSvg";
import MenuSvg from "../../assets/Svg/MenuSvg";
import ArrowLeftSvg from "../../assets/Svg/ArrowLeftSvg";
import HelpfulltipSvg from "../../assets/Svg/HelpfulltipSvg";

import { AiFillHome } from "react-icons/ai";

// API
import { notify, apiSignUp, entete, apiCreateEntrepriseActif, apiPersonnel } from "../API/Api";

// org select options data
const data = ["Bilingual Bridges", "Ivytutor", "Vasitutor"].map((item) => ({
  label: item,
  value: item,
}));

const Headers = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem('email');
  const nom = localStorage.getItem('nom');
  const prenom = localStorage.getItem('prenom');
  const idUser = localStorage.getItem('id');
  const token = localStorage.getItem('token');
  const typeLocal = localStorage.getItem('type');
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [etat, setEtat] = useState("");
  const [nomEntreprise, setNomEntreprise] = useState("");
  const [open, setOpen] = useState(false);
  const [statut, setStatut] = useState("");
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [step, setStep] = useState(0);
  const [percent, setPercent] = useState(10);

  const decline = () => {
    const value = Math.max(percent - 10, 0);
    setPercent(value);
    setStep(step - 1);
  };

  const increase = () => {
    const value = Math.min(percent + 10, 100);
    setPercent(value);
    setStep(step + 1);
  };

  const status = percent === 100 ? "success" : null;
  const color = percent === 100 ? "#00A85B" : "#00A85B";

  const logout = () => {
    localStorage.clear();
    navigate('/auth', { replace: true });
    notify('success', 'Déconnexion...')
  };

  const getUserById = async () => {
    try {
      if (typeLocal != "admin") {
        const { data } = await apiPersonnel.get(`/personnel/${idUser}`, {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        })
        console.log(data);
        console.log(data.data);
        setStatut(data.data[0].user.statut)
        setNomEntreprise(data.data[0].entreprises[0].nomEntreprise)
        setEtat(data.data[0].entreprises[0].etat)
      } else {
        const { data } = await apiSignUp.get(`/${idUser}`, {
          headers: {
              entete,
              Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setStatut(data.data.statut)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getActifUserById = async () => {
    try {
      if (typeLocal != "admin") {
        console.log(typeLocal)
      } else {
        const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setNomEntreprise(data.data[0].nomEntreprise)
        setEtat(data.data[0].etat)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    getUserById();
    getActifUserById();
  }, [idUser]);

  return (
    <HeaderContainer>
      <HeaderContainerRight>
        <OrgBrand>
          <span>BUSINESS PRO 9</span>
        </OrgBrand>
        {/*<OrgMobileMenu>
          <MenuSvg />
        </OrgMobileMenu>*/}
        {
          statut != true ? (
            <>
              {
                showSkeleton != true ? (
                  <OrgSelect>
                    Votre compte n'est pas encore actif
                  </OrgSelect>
                ) : (
                  <></>
                )
              }
            </>
          ) : (
            <>
              {
                nomEntreprise ? (
                  <OrgSelect>
                    {nomEntreprise}
                  </OrgSelect>
                ) : (
                  <NameOrg>BUSINESS PRO 9</NameOrg>
                )
              }
            </>
          )
        }
      </HeaderContainerRight>

      <HeaderContainerLeft>

       {/* <Badge content={1}>
          <NotificationButton>
            <Notification />
          </NotificationButton>
      </Badge>*/}
        <Whisper
          preventOverflow
          placement="bottomEnd"
          trigger="click"
          speaker={
            <ProfileDropDownContainer arrow={false}>
              <UserProfileDropdownContainer>
                <Profile>{nom.slice(0, 1)}{prenom.slice(0, 1)}</Profile>
                <UserProfileDropdownContainerData>
                  <UserProfileDropdownName>{ prenom } { nom }</UserProfileDropdownName>
                  <UserProfileDropdownEmail>
                    { email }
                  </UserProfileDropdownEmail>
                </UserProfileDropdownContainerData>
              </UserProfileDropdownContainer>
              <UserProfileDropdownMenuContainer
                href="/"
              >
                <AiFillHome color="#000" size="17px" />
                <UserProfileDropdownMenu>
                  Accueil
                </UserProfileDropdownMenu>
              </UserProfileDropdownMenuContainer>
              <UserProfileDropdownMenuContainer
                href="/profile"
                target="_blank"
              >
                <ProfileSvg />
                <UserProfileDropdownMenu>
                  Profile
                </UserProfileDropdownMenu>
              </UserProfileDropdownMenuContainer>
              {
                etat && (
                  <UserProfileDropdownMenuContainer href="/orgListe">
                    <SwitchSvg />
                    <UserProfileDropdownMenu>
                      Gestion de compte
                    </UserProfileDropdownMenu>
                  </UserProfileDropdownMenuContainer>
                )
              }
              <UserProfileDropdownMenuContainer onClick={logout}>
                <LogoutSvg />
                <UserProfileDropdownMenu>Déconnexion</UserProfileDropdownMenu>
              </UserProfileDropdownMenuContainer>
            </ProfileDropDownContainer>
          }
        >
          <UserProfile>
            <Profile>{nom.slice(0, 1)}{prenom.slice(0, 1)}</Profile>
          </UserProfile>
        </Whisper>
      </HeaderContainerLeft>

      {/* ---------------------- Admin Onboarding Modal --------------------------------------------------------*/}

    </HeaderContainer>
  );
};
export default Headers;
