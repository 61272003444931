import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
// @mui
import { Box, Card, Link, Typography, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrencyDot } from '../../../Theme/utils/formatNumber';
// components
import Label from '../../../Components/label';
import { ColorPreview } from '../../../Components/color-utils';
import { url } from '../../../Components/API/Api';
import Iconify from '../../../Components/iconify/Iconify';
import { FaShoppingCart } from 'react-icons/fa';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCardCommanderVente.propTypes = {
  article: PropTypes.object,
};

export default function ShopProductCardCommanderVente({ article, onClickVente, onClickView }) {
  const { id, libelleArticleCommande, img_art_commande, prixNormal, description, etat, montantRestant } = article;

  return (
    <Card key={id}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={libelleArticleCommande} src={`${url}/imageArticleCommander/${img_art_commande}`} />
      </Box>

      <Stack spacing={1} sx={{ p: 2 }}>
        <Link color="inherit" underline="none" display="flex" flexWrap="wrap-reverse" alignItems="center" justifyContent="space-between" >
          <Typography variant="subtitle2" noWrap>
            <ColorPreview descriptions={libelleArticleCommande} />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            <Label color={(etat != "Commander" && 'error') || 'success'}>{sentenceCase(etat)}</Label>
          </Typography>
        </Link> 
        
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
              }}
            >
              {prixNormal && fCurrencyDot(prixNormal)} F CFA
            </Typography>
            &nbsp;
            {
              montantRestant >= 0 ? (
                <span style={{color: 'red'}}>{fCurrencyDot(montantRestant)} F CFA</span>
              ) : (
                <span style={{color: 'green'}}>solder</span>
              )
            }
          </Typography>
        </Stack>

        <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
          <ColorPreview descriptions={description} />
        </div>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <IconButton onClick={() => onClickView(article.id)} aria-label="stock">
            <Iconify icon="eva:eye-fill" />
          </IconButton>
          <IconButton onClick={() => onClickVente(article.id)} style={{display: 'flex', alignItems: "center", justifyContent: "center"}} sx={{ color: 'success.main' }}>
            <FaShoppingCart style={{ width: "20px" }} />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
}
