import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";


// Components
import { ButtonStyles } from "../../Components/Buttons/ButtonStyles";
import { Links, StyledNavLink } from "../../Components/Links/LinksStyle";
import { HintContainer, HintDescription } from "../../Components/Inputs/InputStyles";

//Icons
import BrandDarkSvg from "../../assets/Svg/BrandSvg";

// Styles
import "./StylesAuth.css";
import {
  BodyContainer,
  LoginCard,
  BrandLogo,
  LoginHeadWrap,
  LoginHeadTitle,
  LoginHeadDescription,
  EmailLoginWrap,
  LoginFooterWrap,
  LoginFooterTop,
  LegalContainer,
  PasswordEmailContainer,
  PasswordEmail,
  LoginFooterTopWrap,
  SubBodyContainer
} from "./AuthStyle";


// Input Styled
import { InputPasswordHint } from '../../Components/InputPassword/InputPassword';

//Images
import HomeBackgroundImage from "../../assets/images/1.jpg";

// API
import { apiAuth, entete, notify } from "../../Components/API/Api";

const Passwords = () => {
  const email = localStorage.getItem('email');
  const navigate = useNavigate();
  const [userInfoc, setuserInfoc] = useState('');
  const [msg, setMsg] = useState('');
  const [show, setShow] = useState(false);

  const onChangeValue = (e) => {
    setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
  };

  const submitAuth = async (e) => {
    e.preventDefault();
    setShow(true)

    await apiAuth.post('/', {
      entete,
      email: email,
      password: userInfoc.password
    }).then(() => {
      setShow(false)
      notify('success', 'Connexion réussi');
      navigate('/dashboard', { replace: true });
    }).catch((err) => {
      setShow(false)
      console.log(err);
      setMsg("Le mot de passe que vous avez renseigné est incorrecte")
      notify('warning', "Connexion impossible. Vérifier votre mot de passe");
    });
  }

  const logout = () => {
    localStorage.clear();
    navigate('/auth', { replace: true });
    notify('success', 'Déconnexion...')
  };

  return (
    <SubBodyContainer background={`url(${HomeBackgroundImage})`}>
      <BodyContainer>
        <ToastContainer 
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <LoginCard onSubmit={submitAuth}>
          {/* ********************Header Login Start******************** */}
          <BrandLogo>
            <BrandDarkSvg />
          </BrandLogo>
          <LoginHeadWrap>
            <LoginHeadTitle>Connexion</LoginHeadTitle>
            <LoginHeadDescription>Salut, bon retour 👋</LoginHeadDescription>
          </LoginHeadWrap>
          {/* ********************Header Login End******************** */}

          {/* ********************Input and Button Login Start******************** */}
    
            <PasswordEmailContainer>
              <PasswordEmail>{ email }</PasswordEmail>
              <Links onClick={logout}>
                Change email
              </Links>
            </PasswordEmailContainer>
            <EmailLoginWrap>
              <InputPasswordHint 
                password = {userInfoc.password}
                handleOnchange = {onChangeValue}
              />
              {msg != "" && 
                <HintContainer>
                  <HintDescription hintdescriptionerror>
                    { msg }
                  </HintDescription>
                </HintContainer>
              }
            </EmailLoginWrap>
            {
              show != true ? (
                <ButtonStyles
                  width={"100%"}
                  type="submit"
                  primarybuttom
                  primarymediumbuttom
                >
                  Connexion
                </ButtonStyles>
              ) : (
                <ButtonStyles
                  width={"100%"}
                  disabled
                  primarybuttom
                  primarymediumbuttom
                >
                  Authentification en cours...
                </ButtonStyles>
              )
            }
        
          {/* ********************Input and Button Login End******************** */}

          {/* ********************Footer Information Login Start******************** */}
        
          <LoginFooterWrap>
          <LoginFooterTopWrap>
            <LoginFooterTop>
              <StyledNavLink to="/forgetPassword">Mot de passe oublié?</StyledNavLink>
            </LoginFooterTop>
            </LoginFooterTopWrap>
          </LoginFooterWrap>
        
        </LoginCard>
        {/* ********************Footer Information Login Start******************** */}
      </BodyContainer>
    </SubBodyContainer>
  );
};
export default Passwords;