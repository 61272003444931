import React from 'react';

const InfoCircleSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.3335 8.00048C1.3335 11.6798 4.32016 14.6671 8.00016 14.6671C11.6868 14.6671 14.6668 11.6798 14.6668 8.00048C14.6668 4.31981 11.6868 1.33381 8.00016 1.33381C4.32016 1.33381 1.3335 4.31981 1.3335 8.00048ZM7.4135 10.5271C7.4135 10.8465 7.68016 11.1138 8.00016 11.1138C8.32016 11.1138 8.58016 10.8465 8.58016 10.5271V7.58048C8.58016 7.25981 8.32016 7.00048 8.00016 7.00048C7.68016 7.00048 7.4135 7.25981 7.4135 7.58048V10.5271ZM8.00683 4.87981C7.68016 4.87981 7.42016 5.14648 7.42016 5.46648C7.42016 5.78648 7.68016 6.04648 8.00016 6.04648C8.32683 6.04648 8.58683 5.78648 8.58683 5.46648C8.58683 5.14648 8.32683 4.87981 8.00683 4.87981Z" fill="#111439" />
        </svg>

    );
};

export default InfoCircleSvg;