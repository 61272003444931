import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";

export const GreetingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  top: -10px;
  padding: 0px 10px;
  /*border: 2px solid #fff;*/

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    color: #ffffff;
    cursor: default;
    user-select: none;
    /*border: 2px solid #fff;*/
    //tablet and desktop display
    @media ${devices.tablet} {
      font-size: 48px;
      top: -500px;
    }
  }

  //tablet and desktop display
  @media ${devices.tablet} {
    top: -20px;
  }

`;
