import numeral from 'numeral';

// Configuration des paramètres régionaux de Numeral.js pour utiliser un espace comme séparateur de milliers
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal: (number) => {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});

// Définir les paramètres régionaux par défaut de Numeral.js sur 'fr'
numeral.locale('fr');
// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrencyDot(number) {
  const format = number ? numeral(number).format('0,0') : '';
  return format;
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
