import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

//Components
import Headers from '../../../Components/Header/Headers';
import Footers from "../../../Components/Footers/Footers";
import { ButtonStyles } from '../../../Components/Buttons/ButtonStyles';
import { Links, StyledNavLink } from "../../../Components/Links/LinksStyle";
import { HintContainer, HintDescription, InputLabel, Inputs } from "../../../Components/Inputs/InputStyles";

//Images
import HomeBackgroundImage from "../../../assets/images/2.jpg";
import HomeBackgroundTransparent from "../../../assets/images/bgTransparent.png";


//Icons
import BrandDarkSvg from "../../../assets/Svg/BrandSvg";

// Styles
import {
    HomeContainer,
    HomeContainerLayer,
    HomeBodyContainer,
    HomeBodyContainerGreating,
    HomeBodyAppContainer,
    HomeBodyAppWrap,
    HomeBodyAppIcon,
  } from "../HomeStyles";
import "../../Auth/StylesAuth.css";
import {
    BodyContainer,
    LoginCard,
    BrandLogo,
    LoginHeadWrap,
    LoginHeadTitle,
    LoginHeadDescription,
    EmailLoginWrap,
    LoginFooterWrap,
    LoginFooterTop,
    PasswordEmailContainer,
    PasswordEmail,
    LoginFooterTopWrap
} from "../../Auth/AuthStyle";
import { InputContainer } from '../../../Components/Inputs/InputStyles';

// API
import { apiVerification, entete, notify } from '../../../Components/API/Api';

const Activation = () => {
    const navigate = useNavigate();
    const id = localStorage.getItem('id');
    const [userInfoc, setuserInfoc] = useState('');
    const [msg, setMsg] = useState('');
    const [show, setShow] = useState(false);

    const onChangeValue = (e) => {
        setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
    };

    const submitVerify = async (e) => {
        e.preventDefault();
        setShow(true);

        if (userInfoc.code != "") {
            try {
                await apiVerification.post('/', {
                    userID : id,
                    code : userInfoc.code,
                }).then((res) => {
                    setShow(false);
                    notify('success', `${res.data.message}`);
                    navigate('/dashboard', { replace: true });
                }).catch((err) => {
                    setShow(false);
                    console.log(err);
                    setMsg("Le code a expiré ou incorrecte")
                    notify('warning', `Renseignez un code de vérification correct`);
                });
            } catch (error) {
                setShow(false);
                if (error.response) {
                    if (error.response.status === 404) {
                        // Gérez spécifiquement l'erreur 404
                        // Affichez un message d'erreur ou effectuez d'autres actions
                        console.log("Erreur 404 : Page non trouvée");
                    } else if (error.response.status === 401) {
                        // Gérez spécifiquement l'erreur 401
                        // Affichez un message d'erreur ou effectuez d'autres actions
                        console.log("Erreur 401 : Requête non autorisée");
                    } else {
                        // Gérez les autres erreurs
                        // Affichez un message d'erreur générique ou effectuez d'autres actions
                        console.log(error);
                    }
                } else {
                    // Gérez les autres erreurs qui n'ont pas de réponse associée
                    console.log(error);
                }
            }
        } else {
            setShow(false);
            notify('warning', "Renseignez le champ pour activer votre compte");
            setMsg('Le champ est obligatoire')
        }
    }
    

    return (
        <HomeContainer background={`url(${HomeBackgroundImage})`}>
            <ToastContainer 
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HomeContainerLayer background={`url(${HomeBackgroundTransparent})`}>
                <Headers />

                <LoginCard onSubmit={submitVerify} logincardcontainer>
                    {/* ********************Header Login Start******************** */}
                    <BrandLogo>
                        <BrandDarkSvg />
                    </BrandLogo>
                    <div ></div>
                    <LoginHeadWrap loginheadwrapcontainer>
                        <LoginHeadTitle>Vérification de l'Email : </LoginHeadTitle>
                        <LoginHeadDescription>
                            Un code vous a été envoyer dans votre 
                            boîte mail. Confirmez-le dans le champs 
                            ci-dessous.
                        </LoginHeadDescription>
                    </LoginHeadWrap>
                    {/* ********************Header Login End******************** */}

                    {/* ********************Input and Button Login Start******************** */}

                    <EmailLoginWrap>
                        <InputContainer>
                            <InputLabel>Saisissez le code</InputLabel>
                            <Inputs
                                width={"100%"}
                                type="number"
                                placeholder="Entrez le code envoyer par email"
                                name="code"
                                value={userInfoc.code}
                                onChange={onChangeValue}
                            />
                            {
                                msg != "" && (
                                    <HintContainer>
                                        <HintDescription hintdescriptionerror>
                                            { msg }
                                        </HintDescription>
                                    </HintContainer>
                                )
                            }
                        </InputContainer>
                        {
                            show != true ? (
                                <ButtonStyles
                                    width={"100%"}
                                    type='submit'
                                    primarybuttom
                                    primarymediumbuttom
                                >
                                    Confirmer le code
                                </ButtonStyles>
                            ) : (
                                <ButtonStyles
                                    width={"100%"}
                                    primarybuttom
                                    primarymediumbuttom
                                    disabled
                                >
                                    Activation en cours...
                                </ButtonStyles>
                            )
                        }
                    </EmailLoginWrap>

                    {/* ********************Input and Button Login End******************** */}
                </LoginCard>

                <Footers/>
            </HomeContainerLayer>
        </HomeContainer>
    )
}
export default Activation;