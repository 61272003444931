import React from "react";
import styled from "styled-components";
import { AiOutlineInstagram } from "react-icons/ai";
import { GiEarthAmerica } from "react-icons/gi";
import { FaLinkedinIn } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";

//SVG
import Suivi from "../../assets/images/77suivi.jpg";

const Apropos = () => {
  return (
    <Container id="apropos">
        <div className="sectionGlobal">
            <div className="sectionOne">
                <ImagesSectionOne src={Suivi} alt="BUSINESS PRO 9 Info" style={{opacity: 1}}/>
            </div>
            <div className="sectionTwo">
                <Slides>
                    <h4>
                        A <span className="green">PROPOS</span>
                    </h4>
                    <h1 className="subTitle">BUSINESS PRO 9</h1>
                    <p style={{textAlign: 'justify'}}>    
                        Business Pro 9, la solution incontournable en matière de Gestion de Stock, 
                        se positionne comme un atout stratégique pour les entreprises en quête 
                        d'excellence opérationnelle. En s'appuyant sur BUSINESS PRO 9, vous accédez 
                        à un suivi quotidien automatisé de votre activité, conférant un contrôle total 
                        sur l'ensemble de vos opérations. Vous pouvez automatiser les entrées et les 
                        sorties de vos produits pour une visibilité instantanée sur vos stocks et 
                        recevoir des notifications régulières pour une gestion proactive de votre 
                        inventaire. Optimisez vos opérations, réduisez vos coûts, et renforcez votre 
                        efficacité avec BUSINESS PRO 9.
                    </p>
                    <Social>
                        <p>Consultez nos</p>
                        <div className="social-icons">
                            <div>
                                <a href="/">
                                    <AiOutlineInstagram />
                                </a>
                            </div>
                            <div>
                                <a href="/">
                                    <GiEarthAmerica />
                                </a>
                            </div>
                            <div>
                                <a href="/">
                                    <FaLinkedinIn />
                                </a>
                            </div>
                        </div>
                    </Social>
                    <button className="btn">
                        <a 
                            style={{textDecoration: "none", color: "#fff", zInde: 99}} 
                            href="#footer"
                        >
                            Contactez-nous
                        </a>
                    </button>
                </Slides>
            </div>
        </div>
    </Container>
  );
};

export default Apropos;

const Container = styled.div`
    //border: 2px solid black;
    padding: 1rem;
    color: #2F2F2F;
    background: #eff3ff;

    @media (min-width: 600px) {
        padding: 2rem;
    }
    @media (min-width: 1150px) {
        padding: 5rem 6rem;
    }

    .sectionGlobal {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        @media (min-width: 1150px) {
            flex-direction: row;
        }
    }

    .sectionOne {
        width: 100%;
        opacity: 1,
        flex: 2;
        /*@media (min-width: 600px) {
            width: 53%;
        }*/
        @media (min-width: 1150px) {
            width: 45%;
        }
    }

    .sectionTwo {
        width: 100%;
        /*@media (min-width: 600px) {
            width: 45%;
        }*/
        @media (min-width: 1150px) {
            width: 45%;
        }
    }

    .green {
        color: #005702 !important;
    }
    
    .btn {
        padding: 0.7rem 2rem;
        margin-top: 3rem;
        cursor: pointer;
        background-color: #01be96;
        border: none;
        color: #fff;
        font-weight: 500;
        border-radius: 20px;
        /*filter: drop-shadow(0px 10px 10px #01be9551);*/
        transition: transform 500ms $ease-in-out-back, background-position 800ms $ease-in-out-back, box-shadow 500ms linear;
        :hover {
            /*filter: drop-shadow(0px 10px 10px #01be9570);*/
            transform: scale(1.1);
            background-position: -60px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            transition: transform 500ms ease-in-out, background-position 800ms ease-in-out, box-shadow 500ms linear;
        }
        :active { 
          transform: scale(1);
          background-position: 500px;
          box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
          transition: transform 500ms ease-in-out, background-position 800ms ease-in-out, box-shadow 500ms linear;
        }
    }

    .subTitle {
        font-size: 2rem;
    }
    
    /*@media (min-width: 600px) {
        width: 90%;
        flex-direction: row;
        border: 2px solid green;
    }
    
    @media (min-width: 1150px) {
        width: 90%;
        border: 2px solid yellow;
    }*/
  
`;

const ImagesSectionOne = styled.img`
    height: 100%;
    width: 100%;
`;

const Slides = styled.div`
    z-index: 99;
`;

const Social = styled.div`
    margin-top: 3rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 0;
    p {
        font-size: 0.9rem;
        @media (max-width: 690px) {
        font-size: 0.7rem;
        }
    }

    .social-icons {
        display: flex;
        align-items: center;
        gap: 1rem;
        div {
            width: 2rem;
            height: 2rem;
            background-color: #01be96;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 400ms ease-in-out;
            border-radius: 100%;
            :hover {
                transform: rotate(360deg);
            }
        }

        a {
            color: #fff;
        }
    }
`;