import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';

// Components
import { UserListToolbar } from '../TableauDeBord/Section/user';
import { Step1AdminOnboardingContainer } from '../../Components/Oboarding/Admin/AdminOnboardingStyle';
import Iconify from '../../Components/iconify/Iconify';
import { fCurrencyDot } from '../../Theme/utils/formatNumber';
import Label from '../../Components/label';

//STYLES
import { 
  ApprovisionementHeaderTabs 
} from '../Approvisionement/ApprovisionementStyles';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Stack, Container, TextField, Typography, Tabs, Tab, Box, FormControl, TableContainer, Table, TableBody, 
  InputLabel, Select, MenuItem, Input, InputAdornment, TableRow, TableCell, TablePagination, Popover, Paper,
  IconButton, Card, Link
} from '@mui/material';
import { Modal, Form, Button } from 'rsuite';

import { ProductListVente, ProductListCommanderVente } from '../Approvisionement/section';

// API
import { 
  apiArticle, apiArticleCommander, entetes, entete, url, notify, apiCreateEntrepriseActif, apiVente, apiPersonnel
} from '../../Components/API/Api';

//***************************************************************************************************** */

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  borderRadius: '10px',
});

//***************************************************************************************************** */

const Vente = () => {
  const idUser = localStorage.getItem('id');
  const nomVendeur = localStorage.getItem('nom');
  const prenomVendeur = localStorage.getItem('prenom');
  const typeLocal = localStorage.getItem('type');

  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  //***************************************************************************************************** */
  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //***************************************************************************************************** */

  // Data Article
  const [article, setArticle] = useState([]);
  const [ids, setIds] = useState(0);
  const [ref, setRef] = useState('');
  const [nomarticle, setNomarticle] = useState('');
  const [description, setDescription] = useState('');
  const [prix, setPrix] = useState('');
  const [prix_promo, setPrix_promo] = useState(0);
  const [stockArt, setStockArt] = useState(0);
  const [nomOrg, setNomOrg] = useState("");
  const [imgArt, setImg_art] = useState(null);
  const [sousCategorieID, setSousCategorieID] = useState('');
  const [categorieID, setCategorieID] = useState('');
  const [entrepriseID, setEntrepriseID] = useState(2);
  const [status, setStatus] = useState(false);

  // Data Article Commander
  const [articlec, setArticlec] = useState([]);
  const [idsc, setIdsc] = useState(0);
  const [libelleArticleCommande, setLibelleArticleCommande] = useState('');
  const [nomOrgCmd, setNomOrgCmd] = useState("");
  const [etatArtCmd, setEtatArtCmd] = useState('');
  const [refCmd, setRefCmd] = useState('');
  const [dateCmd, setDateCmd] = useState('');
  const [nomHomme, setNomHomme] = useState('');
  const [nomFemme, setNomFemme] = useState('');
  const [tailleHomme, setTailleHomme] = useState(0);
  const [tailleFemme, setTailleFemme] = useState(0);
  const [img_art_commande, setImg_art_commande] = useState(null);
  const [descriptionc, setDescriptionc] = useState('');
  const [prixNormal, setPrixNormal] = useState('');
  const [montantVerse, setMontantVerse] = useState('');
  const [montantRestant, setMontantRestant] = useState('');
  const [Date, setDate] = useState('');

  const [userInfo, setuserInfo] = useState({
    filepreview: null,
  });
  //***************************************************************************************************** */

  // Modal Open View Article
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => { 
    setShowView(false) 
  };
  const handleShowView = (e) => { setShowView(true) };

  // Modal Open View Article Commander
  const [showViewCommander, setShowViewCommander] = useState(false);
  const handleCloseViewCommander = () => { 
    setShowViewCommander(false) 
  };
  const handleShowViewCommander = (e) => { setShowViewCommander(true) };

  //***************************************************************************************************** */

  // All article
  const allarticle = async () => {
    const token = localStorage.getItem('token');
    try {
      apiArticle.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setArticle(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  // All article commander
  const allarticleCommander = async () => {
    const token = localStorage.getItem('token');
    try {
      apiArticleCommander.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data.data)
        setArticlec(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
      } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
      }
    }
  };

  //***************************************************************************************************** */
  // Recuperer id de l'article
  const getDataById = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiArticle.get(`/${ids}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`${data.data}`);
      //setArticleid_upd(data.data.id_art);
      setNomarticle(data.data.nomarticle);
      setDescription(data.data.description);
      setPrix(data.data.prix);
      setPrix_promo(data.data.prix_promo);
      setImg_art(null);
      setCategorieID(data.data.categorieID);
      setSousCategorieID(data.data.sousCategorieID);
      setuserInfo({
        filepreview: data.data.img_art 
      });
      setRef(data.data.reference);
      setStockArt(data.data.Stock_Art.quantiteStock)
      setNomOrg(data.data.ManagerProfileEntreprise.nomEntreprise)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle specific 404 error
        // Display an error message or perform other actions
      } else {
        // Handle other errors
        // Display a generic error message or perform other actions
      }
    }
  };

  // Recuperer id de l'article
  const getDataByIdCmd = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiArticleCommander.get(`/${idsc}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`${data.data}`);
      //setArticleid_upd(data.data.id_art);
      setLibelleArticleCommande(data.data.libelleArticleCommande);
      setNomOrgCmd(data.data.ManagerProfileEntreprise.nomEntreprise);
      setEtatArtCmd(data.data.etat);
      setNomHomme(data.data.nomHomme);
      setNomFemme(data.data.nomFemme);
      setTailleHomme(data.data.tailleHomme);
      setTailleFemme(data.data.tailleFemme);
      setPrixNormal(data.data.prixNormal);
      setMontantVerse(data.data.montantVerse);
      setMontantRestant(data.data.montantRestant);
      setDate(data.data.Date);
      setDescription(data.data.description);
      setImg_art_commande(null);
      setCategorieID(data.data.categorieID);
      setSousCategorieID(data.data.sousCategorieID);
      setuserInfo({
        filepreview: data.data.img_art_commande 
      });
      setRefCmd(data.data.reference);
      setDateCmd(data.data.Date);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle specific 404 error
        // Display an error message or perform other actions
      } else {
        // Handle other errors
        // Display a generic error message or perform other actions
      }
    }
  };

  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getActifUserById = async () => {
    const token = localStorage.getItem('token');
    try {
      if (typeLocal != "admin") {
        const { data } = await apiPersonnel.get(`/personnel/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].entreprises[0].id)
      } else {
        const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
            headers: {
              entete,
              Authorization: `Bearer ${token}`
            }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].id)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }
  //***************************************************************************************************** */

  const handleView = (id) => {
    setIds(id);
    handleShowView();
  }; 

  const handleViewCommander = (id) => {
    setIdsc(id);
    handleShowViewCommander();
  }; 
  

  //***************************************************************************************************** */
  // Modal Vente Article commander
  const [showupdCmd, setShowupdCmd] = useState(false);
  const handleCloseupdCmd = () => { 
    setNomHomme('');
    setNomFemme('');
    setTailleHomme(0);
    setTailleFemme(0);
    setPrixNormal(0);
    setMontantVerse(0);
    setDate('');
    setDescription('');
    setImg_art_commande(null);
    setCategorieID('');
    setSousCategorieID('');
    setuserInfo({
      filepreview: null
    });
    setShowupdCmd(false) 
  };
  const handleShowupdCmd = (e) => { setShowupdCmd(true) };

  // Modal Vente Article
  const [showupd, setShowupd] = useState(false);
  const handleCloseupd = () => { 
    setNomarticle('');
    setDescription('');
    setPrix(0);
    setPrix_promo(0);
    setImg_art(null);
    setCategorieID('');
    setSousCategorieID('');
    setuserInfo({
      filepreview: null
    });
    setShowupd(false) 
  };
  const handleShowupd = (e) => { setShowupd(true) };

  // Open Modal Vente article commander
  const handleUpdateCommander = (id) => {
    setIdsc(id);
    handleShowupdCmd();
  }; 
  // Open Modal Vente article
  const handleUpdate = (id) => {
    setIds(id);
    handleShowupd();
  };  
  //***************************************************************************************************** */

  //***************************************************************************************************** */
  
  const submitVenteArticle = async () => {
    const token = localStorage.getItem('token');
    try{ 
      apiVente.post(`/`, {
        articleID: `${ids}`,
        description: `${nomarticle} a été vendu`,
        montantVente: `${prix_promo}`,
        entrepriseID: `${entrepriseID}`,
        nomVendeur: `${nomVendeur} ${prenomVendeur}`,
        ref_article: `${ref}`,
      }, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        // alert(res.data)
        notify('success', `${res.data.message}`);
        setNomarticle('');
        setDescription('');
        setPrix(0);
        setPrix_promo(0);
        setImg_art(null);
        setCategorieID('');
        setSousCategorieID('');
        setuserInfo({
          filepreview: null
        });
        allarticle();
        handleCloseupd()
      }).catch((err) => {
        console.log(err);
        if (err.response && err.response.data && err.response.data.message) {
          notify('warning', `Vente non effectuée: ${err.response.data.message}`);
          handleCloseupd()
        } else {
          notify('warning', "Une erreur s'est produite lors de la vente.");
          handleCloseupd()
        }
      })
    } catch (error) {
        handleCloseupd()
        if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
          handleCloseupd()
        } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
          handleCloseupd()
        }
    }
  }

  const submitVenteArticleCommander = async () => {
    const token = localStorage.getItem('token');
    try{ 
      await apiVente.post(`/`, {
        article_commanderID: `${idsc}`,
        description: `${libelleArticleCommande} a été vendu`,
        montantVente: `${prixNormal}`,
        entrepriseID: `${entrepriseID}`,
        nomVendeur: `${nomVendeur} ${prenomVendeur}`,
        ref_article: `${refCmd}`,
      },
      {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        // alert(res.data)
        notify('success', `${res.data.message}`);
        allarticleCommander();
        setNomHomme('');
        setNomFemme('');
        setTailleHomme(0);
        setTailleFemme(0);
        setPrixNormal(0);
        setMontantVerse(0);
        setDate('');
        setDescription('');
        setImg_art_commande(null);
        setCategorieID('');
        setSousCategorieID('');
        setuserInfo({
          filepreview: null
        });
        handleCloseupdCmd();
      }).catch((err) => {
        console.log(err);
        notify('warning', "Vente non effectuée");
      })
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // Gérez spécifiquement l'erreur 404
            // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
            // Gérez les autres erreurs
            // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
    }
  }

  //***************************************************************************************************** */

  const filterArticles = article.filter( (item) => {
    if (filterName === "") {
      return item
    } else if (item.nomarticle.toLowerCase().includes(filterName.toLowerCase())) {
      return item
    }
  });

  const filterArticlesCommander = articlec.filter( (item) => {
    if (filterName === "") {
      return item
    } else if (item.libelleArticleCommande.toLowerCase().includes(filterName.toLowerCase())) {
      return item
    }
  });

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  //***************************************************************************************************** */

  useEffect(() => {
    getDataById();
  }, [ids]);

  useEffect(() => {
    getDataByIdCmd();
  }, [idsc]);

  useEffect(() => {
    getActifUserById();
  }, [idUser]);

  useEffect(() => {
    allarticle();
    allarticleCommander();
  }, [entrepriseID]);

  return (
    <Step1AdminOnboardingContainer>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="one" label="Liste d'articles" />
          <Tab value="two" label="Liste des Commandes Personnalisées" />
        </Tabs>
      </Box>

      {/* -----------------Tab Liste d'articles START----------------- */}
      {
        value === "one" && (
          <div>
            <ApprovisionementHeaderTabs>
              <UserListToolbar label={"Rechercher un article"} filterName={filterName} onFilterName={handleFilterByName} />
              <label>
                Liste d'articles
              </label>
            </ApprovisionementHeaderTabs>

            <div>
              <ProductListVente 
                articles={filterArticles} 
                onClickVente={handleUpdate}
                onClickView={handleView}
              />
            </div>
          </div>
        )
      }
      {/* -----------------Tab Liste d'articles END----------------- */}

      {/* -----------------Tab Liste des Commandes Personnalisées START----------------- */}
      {
        value === "two" && (
          <div>
            <ApprovisionementHeaderTabs>
              <UserListToolbar label={"Rechercher une commande"} filterName={filterName} onFilterName={handleFilterByName} />
              <label>
                Liste des Commandes Personnalisées
              </label>
            </ApprovisionementHeaderTabs>

            <div>
              <ProductListCommanderVente 
                articles={filterArticlesCommander} 
                onClickVente={handleUpdateCommander}
                onClickView={handleViewCommander}
              />
            </div>
          </div>
        )
      }
      {/* -----------------Tab Liste des Commandes Personnalisées END----------------- */}

      {/*******************Modal View START***************************************************************************/}
      {/* MODAL OPEN VIEW ARTICLE */}
      <Modal
        open={showView}
        onClose={handleCloseView}
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Détail article
            </Typography>
            <Card>
              <Box sx={{ pt: '30%', position: 'relative' }}>
                <StyledProductImg alt={nomarticle} src={`${url}/imageArticle/${userInfo.filepreview}`} />
              </Box>
              <Stack spacing={1} sx={{ p: 2 }}>
                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    {nomOrg}
                  </Typography>
                  {ref}
                </Typography>
                <Link color="inherit" underline="none" display="flex" flexWrap="wrap-reverse" alignItems="center" justifyContent="space-between" >
                  <Typography variant="subtitle2" noWrap>
                    {nomarticle}
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    <Label color={(stockArt <= 10 && 'error') || 'success'}>{stockArt} Stock</Label>
                  </Typography>
                </Link> 
        
                { prix_promo > "0" ?
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    
                    <Typography variant="subtitle1">
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          color: 'text.disabled',
                          textDecoration: 'line-through',
                        }}
                      >
                        {prix && fCurrencyDot(prix)} F CFA
                      </Typography>
                      &nbsp;
                      {fCurrencyDot(prix_promo)} F CFA
                    </Typography>
                  </Stack>
                  :
                  <Typography style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", color: "#000000"}}>
                    {fCurrencyDot(prix)} F CFA
                  </Typography>
                }
                <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
                  {description}
                </div>
              </Stack>
            </Card>
            <Box sx={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
              <LoadingButton  size="medium" variant="contained" sx={{backgroundColor: "gray"}} onClick={handleCloseView}>
                Fermer
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL OPEN VIEW ARTICLE COMMANDER */}
      <Modal
        open={showViewCommander}
        onClose={handleCloseViewCommander}
      >
        <Box sx={{color: "black"}}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Détail article
            </Typography>
            <Card>
              <Box sx={{ pt: '30%', position: 'relative' }}>
                <StyledProductImg alt={libelleArticleCommande} src={`${url}/imageArticleCommander/${userInfo.filepreview}`} />
              </Box>
              <Stack spacing={1} sx={{ p: 2 }}>
                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    {nomOrgCmd}
                  </Typography>
                  {refCmd}
                </Typography>
                <Link color="inherit" underline="none" display="flex" flexWrap="wrap-reverse" alignItems="center" justifyContent="space-between" >
                  <Typography variant="subtitle2" noWrap>
                    {libelleArticleCommande}
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    <Label color={(etatArtCmd != "Commander" && 'error') || 'success'}>{sentenceCase(etatArtCmd)}</Label>
                  </Typography>
                </Link> 
        
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        color: 'text.disabled',
                      }}
                    >
                      {prixNormal && fCurrencyDot(prixNormal)} F CFA
                    </Typography>
                    &nbsp;
                    {
                      montantRestant >= 0 ? (
                        <span style={{color: 'red'}}>{fCurrencyDot(montantRestant)} F CFA</span>
                      ) : (
                        <span style={{color: 'green'}}>solder</span>
                      )
                    }
                  </Typography>
                </Stack>
                <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
                  {description}
                </div>

                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    Homme: {nomHomme} 
                  </Typography>
                  Taille Homme: {tailleHomme} 
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ width: '100%', display: "flex", justifyContent: "space-between"}}>
                  <Typography
                    component="span"
                    variant="subtitle2"
                  >
                    Femme: {nomFemme}
                  </Typography>
                  Taille Femme: {tailleFemme}
                </Typography>
                <br/>
                <div style={{display: "flex", float: "left", margin: "-2px 0px"}}>
                  Date limite pour délivrer la commande au client: {dateCmd}
                </div>
              </Stack>
            </Card>
            <Box sx={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
              <LoadingButton  size="medium" variant="contained" sx={{backgroundColor: "gray"}} onClick={handleCloseViewCommander}>
                Fermer
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* MODAL VENTE ARTICLE */}
      <Modal
        open={showupd}
        onClose={handleCloseupd}
      >
        <Modal.Header>
          <Modal.Title>Confirmer la vente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid onSubmit={submitVenteArticle} style={{width: "100%", backgroundColor: "#FFFFFF"}}>
            <Modal.Footer>
              <Button type='submit' appearance="primary">
                confirmer
              </Button>
              <Button onClick={handleCloseupd} appearance="subtle">
                Annuler
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* MODAL VENTE ARTICLE COMMANDER */}
      <Modal
        open={showupdCmd}
        onClose={handleCloseupdCmd}
      >
        <Modal.Header>
          <Modal.Title>Confirmer la vente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid onSubmit={submitVenteArticleCommander} style={{width: "100%", backgroundColor: "#FFFFFF"}}>
            <Modal.Footer>
              <Button type='submit' appearance="primary">
                confirmer
              </Button>
              <Button onClick={handleCloseupdCmd} appearance="subtle">
                Annuler
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/*******************Modal View END*****************************************************************************/}
    </Step1AdminOnboardingContainer>
  )
}
export default Vente;