import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";

export const BodyContainer = styled.div`
  //default mobile display
  background: var(--primary-white);
  width: var(--width-100);
  min-height: var(--height-100vh);
  display: flex;
  justify-content: center;
  align-items: center;

  //tablet and desktop display and
  @media ${devices.tablet} {
    // background: var(--surface-100);
    //background: linear-gradient(to bottom, #102AB6, #FFFFFF);
    background: rgba(0, 0, 0, 0.8);
  }
`;

export const SubBodyContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.background};

`

export const LoginCard = styled.form`
  //default mobile display
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 16px 48px 16px;
  gap: var(--gap-24px);
  background: var(--primary-white);
  width: 100%;
  opacity: 1;
  //border: 2px solid black;

  //tablet and desktop display and
  @media ${devices.tablet} {
    padding: var(--padding-48px-24px);
    box-shadow: var(--cards-elevation);
    border-radius: var(--border-radius-lg);
    width: 465px;
  }

  //Container verification
  ${(props) =>
    props.logincardcontainer &&
    css`
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.3) !important;
      backdrop-filter: blur(10px);
      width: 90%;
      @media ${devices.tablet} {
        width: 465px;
      }
    `
  }

  ${(props) =>
    props.logincardtextcolor &&
    css`
      color: #000000;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
      @media ${devices.tablet} {
        width: 90%;
      }
    `
  }
`;

export const EntrepriseContainer = styled.div`
  //default mobile display
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: var(--padding-48px-16px);
  gap: var(--gap-24px);
  background: var(--primary-white);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;

  /* Ajoutez ces styles pour personnaliser la barre de défilement */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9A8174; 
    border-radius: 20px; 
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Couleur de l'arrière-plan de la barre de défilement */
  }

  //tablet and desktop display and
  @media ${devices.tablet} {
    padding: var(--padding-48px-24px);
    box-shadow: var(--cards-elevation);
    border-radius: var(--border-radius-lg);
    width: 465px;
  }

  //Container verification
  ${(props) =>
    props.logincardcontainer &&
    css`
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.3) !important;
      backdrop-filter: blur(10px);
    `
  }

  //Container verification
  ${(props) =>
    props.logincardcontainerorgListe &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 18px;
      flex-wrap: wrap;
      background: transparent;
      box-shadow: 0px 0px 0px rgba(0,0,0,0);
      border-radius: 1px;
      @media ${devices.tablet} {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
      }
    `
  }

  ${(props) =>
    props.displayprofile &&
    css`
      overflow: hidden;
      overflow-y: scroll;
    `}

  ${(props) =>
    props.logincardtextcolor &&
    css`
      color: #000000;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
      @media ${devices.tablet} {
        width: 90%;
      }
    `
  }
`;


export const BrandLogo = styled.div`
  width: 100%;
  height: 80px;
  // height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //border: 2px solid black;

  div {
    //default mobile display
    width: 200px;
    height: 200px;

    //tablet and desktop display and
    @media ${devices.tablet} {
      width: 250px;
      height: 250px;
    }
  }
`;

export const LoginHeadWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8px);
  width: 100%;

    
  //Container verification
  ${(props) =>
    props.loginheadwrapcontainer &&
    css`
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5) !important;
      backdrop-filter: blur(10px);
      padding: 10px;
    `
  }
`;

export const LoginHeadTitle = styled.h3`
  //default mobile display
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--body-md);
  line-height: var(--line-height-130);
  color: var(--brand-500S);

  //tablet and desktop display and
  @media ${devices.tablet} {
    font-size: var(--header-xs);
  }
`;

export const LoginHeadDescription = styled.p`
  //default mobile display
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--body-sm);
  line-height: var(--line-height-130);
  color: var(--body-text-color);

  //tablet and desktop display and
  @media ${devices.tablet} {
    font-size: var(--body-md);
  }
`;

export const EmailLoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-16px);
  width: 100%;
`;
export const LoginDivider = styled.div`
  width: var(--width-100);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .line {
    width: var(--width-100);
    border: 1px solid var(--input-border);
  }

  .text_line {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: var(--primary-white);
    width: var(--width-30_27px);
    height: var(--height-22px);
  }

  .text {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: var(--body-regular-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-17px);
    display: flex;
    align-items: center;
    color: var(--subText);
  }
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-16px);
  width: var(--width-100);
`;

export const LoginFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-40px);
  width: var(--width-100);
`;
export const LoginFooterTopWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
export const LoginFooterTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: var(--width-100);
  gap: var(--gap-8px);
`;
export const LoginFooterTopTitle = styled.p`
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: var(--body-regular-weight);
  font-size: var(--body-sm);
  line-height: var(--line-height-130);
  color: var(--primary-color);
`;

export const LegalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: var(--width-100);
  gap: var(--gap-8px);
`;

export const PasswordEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: var(--width-100);
`;
export const PasswordEmail = styled.div`
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: var(--body-regular-weight);
  font-size: var(--body-sm);
  line-height: var(--line-height-130);
  color: var(--primary-color);
`;

export const HalfEmailWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: var(--width-100);

  //tablet and desktop display and
  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const Spacing = styled.div`
  margin: 8px 0px;

  //tablet and desktop display and
  @media ${devices.tablet} {
    margin: 0px 8px;
  }
`;
