import styled, { css } from "styled-components";
// import { devices } from "../../Theme/Devices";

export const InputPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: var(--width-100);
    gap: var(--gap-4px);
`
export const InputPasswordBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: var(--width-100);
`
export const InputIconEye = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: var(--spacing-Negative-32px);
`

export const InputLabel = styled.label`
    font-weight: var(--body-semi-bold-weight);
    font-size: var(--body-md);
    line-height: var(--line-height-130);
    color: var(--brand-500S);
`

export const Validation = styled.div`
    font-weight: var(--body-regular-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-24px);
    color: var(--brand-500S);
`

export const ConditionValid = styled.div`
    margin-left: 10px;
`
export const Span = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) =>
        props.conditionCheck && css`
            color: var(--password-condition-valid);
        `
    }
`

export const Inputs = styled.input`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-16px);
    padding-right: var(--spacing-50px);
    border-radius: var(--border-radius-md);
    font-weight: var(--body-semi-bold-weight);
    font-size: var(-body-sm);
    line-height: var(--line-height-130);
    border: 1px solid var(--input-border);
    color: var(--header-text-color);
    background-color: var(--transparent-background);
    width: var(--width-100);

    &:hover {
        border: 1px solid var(--brand-hover);
    }
    &::placeholder {
        color: var(--palceholder);
        font-weight: var(--body-semi-bold-weight);
    }
    &:focus {
        border: 1px solid var(--brand-hover);
    }
    &:focus-visible {
        outline: var(--brand-hover) auto 1px;
        outline-color: var(--brand-hover);
        outline-style: auto;
        outline-width: 1px;
    }
    &:disabled {
        background-color: var(--surface-50);
        color: var(--palceholder);
        cursor: none;
        &:hover {
            border: 1px solid var(--input-border);
        }
    }
   
    /* Input Error */
    ${(props) =>
        props.inputerror && css`
            border: 1px solid var(--error-color);
            background-color: var(--system-Error-Surface);
            &:hover {
                border: 1px solid var(--error-color);
            }
            &:focus {
                border: 1px solid var(--error-color);
            }
            &:focus-visible {
                outline: var(--error-color) auto 1px;
                outline-color: var(--error-color);
                outline-style: auto;
                outline-width: 1px;
            }
        `
    }
`

export const SelectHint = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-4px);
    margin-top: var(--spacing-5px);
`
export const SelectHintSpan = styled.span`
    font-weight: var(--body-regular-weight);
    font-size: var(--body-xs);
    line-height: var(--line-height-130);
    text-align: center;
    color: var(--brand-500S);
    /* Input Error */
    ${(props) =>
        props.inputerrorhint && css`
            color: var(--error-color);
        `
    }
`