import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { MdAlternateEmail } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMailOpen } from "react-icons/hi";
import { AiFillGithub, AiFillLinkedin, AiOutlineArrowUp } from "react-icons/ai";
import { BsFacebook, BsSlack } from "react-icons/bs";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { Slide, Zoom, Fade } from "react-awesome-reveal";
import { ToastContainer, toast } from 'react-toastify';
import { apiContact, notify } from "../../API/Api";

const Footer = () => {
  const [show, setShow] = useState(false);
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const nom = event.target.nom.value;
    const email = event.target.email.value;
    const sujet = event.target.sujet.value;
    const message = event.target.message.value;
    setShow(true)

    if ( nom && email && sujet && message ) {
      apiContact.post('/', {
        nom: nom,
        email: email,
        sujet: sujet,
        message: message
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }
      }).then(response => {
        console.log(response.data); // Affichez la réponse du serveur dans la console
        // Effectuez les actions supplémentaires nécessaires en cas de succès de l'envoi du message
        event.target.nom.value = '';
        event.target.email.value = '';
        event.target.sujet.value = '';
        event.target.message.value = '';
        setShow(false);
        notify('success', "Mail envoyé");
      }).catch(error => {
        setShow(false);
        console.error(error); // Affichez les erreurs dans la console
        notify('', "Mail non envoyé")
      });
    } else {
      setShow(false);
      notify('warning', "Tous les champs sont obligatoires!!");
    }
  }

  return (
    <Container id="footer">
      <ToastContainer 
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Profile>
        <div>
          <h1>BUSINESS PRO 9</h1>
        </div>
        <div className="address">
          <div direction="left">
            <h1>Adresse:</h1>
          </div>
          <div>
            <p>Benin, Cotonou</p>
          </div>
        </div>
        <div className="links">
          <div direction="left">
            <h1>Contactez nous directement :</h1>
          </div>
          <div>
            <span>
              <FiPhoneCall />
            </span>
            <div>
              <a href="tel:+4733378901">+229 65989055 / 66980409</a>
            </div>
          </div>
          <div>
            <div direction="left">
              <span>
                <HiOutlineMailOpen />
              </span>
            </div>
            <div>
              <a href="mailto:yalogilchrist52@gmail.com">zkconsulting024@gmail.com</a>
            </div>
          </div>
        </div>
        <div className="profiles">
          <Slide direction="left">
            <h1>Consulter nos profils</h1>
          </Slide>
          <div className="icons">
            <Zoom>
              <span>
                <a href="/">
                  <AiFillGithub />
                </a>
              </span>
            </Zoom>
            <Zoom>
              <span>
                <a href="/">
                  <AiFillLinkedin />
                </a>
              </span>
            </Zoom>
            <Zoom>
              <span>
                <a href="/">
                  <BsFacebook />
                </a>
              </span>
            </Zoom>
            <Zoom>
              <span>
                <a href="/">
                  <BsSlack />
                </a>
              </span>
            </Zoom>
          </div>
        </div>
        <Fade>
          <ArrowUp onClick={scrollUp}>
            <AiOutlineArrowUp />
          </ArrowUp>
        </Fade>
      </Profile>
      <Form>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="name">
              <span>
                <CgProfile />
              </span>
              <input type="text" name="nom" placeholder="Nom complet..." />
            </div>
            <div className="email">
              <span>
                <MdAlternateEmail />
              </span>
              <input type="email" name="email" placeholder="Email..." />
            </div>
            <div className="name">
              <span>
                <MdAlternateEmail />
              </span>
              <input type="text" name="sujet" placeholder="Objet..." />
            </div>
            <div className="message">
              <span className="messageIcon">
                <FiMail />
              </span>
              <textarea cols="30" rows="10" name="message" placeholder="Message..."></textarea>
            </div>
            {
              show != true ? (
                <button type="submit">Envoyez</button>
              ) : (
                <button disabled>En cours...</button>
              )
            }
          </form>
        </div>
      </Form>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
  //position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 3rem;
  padding: 0 1rem;

  /*@media (max-width: 650px) {
    flex-direction: column;
    gap: 3rem;
    width: 100%;
  }*/

  @media (min-width: 600px) {
    padding: 2rem;
    flex-direction: column;
  }

  @media (min-width: 1150px) {
    flex-direction: row;
    //border: 2px solid red;
  }
`;
const Profile = styled.div`
  //flex: 1;
  //border: 2px solid red;
  @media (min-width: 1150px) {
    width: 40%;
  }
  .address {
    padding: 1rem 0;
    h1 {
      font-size: 1.2rem;
    }

    p {
      width: 60%;
      padding-top: 0.5rem;
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .links {
    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      a {
        text-decoration: none;
        color: lightgray;
        :hover {
          color: orange;
        }
      }
    }
  }

  .profiles {
    h1 {
      font-size: 1.2rem;
      padding: 1rem 0;
    }

    .icons {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        border-radius: 50px;

        :hover {
          background-color: orange;
        }

        a {
          margin-top: 0.2rem;
          color: #fff;
        }
      }
    }
  }
`;
const ArrowUp = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #01be96;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: 650px) {
    position: absolute;
    right: 3rem;
    top: 16rem;
  }
`;
const Form = styled.div`
  //flex: 1;
  //border: 2px solid red;
  h1 {
    font-size: 1.3rem;
    padding-bottom: 0.7rem;
  }

  form {
    background-color: #191923;
    padding: 0.8rem;
    border-radius: 5px;
    @media (min-width: 1150px) {
      width: 600px;
    }
    .name,
    .email,
    .message {
      display: flex;
      border: 1px solid gray;
      margin-bottom: 0.5rem;
      input,
      textarea {
        width: 100%;
        border: none;
        outline: none;
        color: #fff;
        background-color: transparent;
        padding: 1rem 0.5rem;
      }
      span {
        background-color: #3e3e3e;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .messageIcon {
        align-items: flex-start;
        padding-top: 0.5rem;
      }
    }

    button {
      width: 5rem;
      height: 1.8rem;
      background-color: #01be96;
      border: none;
      border-radius: 5px;
      filter: drop-shadow(0px 4px 5px #01be9551);
      cursor: pointer;
      :hover {
        filter: drop-shadow(0px 6px 9px #01be9551);
      }
    }
  }
`;
