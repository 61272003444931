import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//Components
import Headers from '../../../Components/Header/Headers';
import Footers from "../../../Components/Footers/Footers";


//RSUITE & MATERIEL UI
import { Card , CardContent, CardMedia, Typography, Button, CardActionArea, CardActions } from '@mui/material';
import { Badge } from 'rsuite';

//Svg
import InfoCircleSvg from "../../../assets/Svg/InfoCircleSvg";
import PublicBg from "../../../assets/images/publicbg.svg";
import PrivateBg from "../../../assets/images/privatebg.svg";
import PrivateUserSvg from '../../../assets/Svg/PrivateUserSvg';
import MultipleUserSvg from '../../../assets/Svg/MultipleUsersSvg';
import DogSvg from '../../../assets/Svg/DogSvg';
import OrgSvg from '../../../assets/Svg/OrgSvg';


//Images
import HomeBackgroundImage from "../../../assets/images/01.jpg";
import HomeBackgroundTransparent from "../../../assets/images/bgTransparent.png";

//Styles
import {
  HomeContainer,
  HomeContainerLayer,
  HomeBodyContainer,
  HomeBodyContainerGreating,
  HomeBodyAppContainer,
  HomeBodyAppWrap,
  HomeBodyAppIcon,
} from "../../Dashboard/HomeStyles";
import {
  EntrepriseContainer,
  contrainerListeOrg
} 
from "../../Auth/AuthStyle";
import { 
  StepContainerOrg,
  SubstepContainerOrg
} from '../OrganizationCreationStyles';
import { 
  WelcomeDescriptionCover,
  Step2AdminOnboardingContainer
} from '../../../Components/Oboarding/Admin/AdminOnboardingStyle';

// API
import { apiCreateEntreprise, entete, notify, url, apiChangeEtatToActif, apiPersonnel } from '../../../Components/API/Api';

const OrganizationListe = () => {
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const token = localStorage.getItem('token');
  const email = localStorage.getItem('email');
  const idUser = localStorage.getItem('id');
  const typeLocal = localStorage.getItem('type');
  const navigate = useNavigate();
  const [userOrgInfoc, setuserOrgInfoc] = useState([]);
  const [userOrgInfocPersonnel, setuserOrgInfocPersonnel] = useState("");


  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getOrgUserById = async () => {
    try {
      if (typeLocal != "admin") {
        const { data } = await apiPersonnel.get(`/personnel/${idUser}`, {
            headers: {
                entete,
                Authorization: `Bearer ${token}`
            }
        })
        console.log(data);
        console.log(data.data);
        setuserOrgInfocPersonnel(data.data[0].entreprises[0])
      } else {
        const { data } = await apiCreateEntreprise.get(`/userid/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setuserOrgInfoc(data.data)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }


  const handleActifEtat = async (idProfileOrg) => {
    try {
      await apiChangeEtatToActif.patch(`/${idProfileOrg}`, null, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        notify('success', `${res.data.message}`);
        navigate('/dashboard', { replace: true });
      }).catch((err) => {
        console.log(err);
        notify('warning', `Le profil n'a pas pu être activé. Patientez pendant quelque minute`);
      });
    } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (error.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(error);
            }
        } else {
            // Gérez les autres erreurs qui n'ont pas de réponse associée
            console.log(error);
        }
    }
  }

  const handleActif = async () => {
    handleActifEtat()
  }

  useEffect(() => {
    getOrgUserById();
  }, [idUser]);

  const gradientStyle = {
    // background: 'linear-gradient(to right, #00b09b, #96c93d)',
    background: 'linear-gradient(to right, #ff4e50, #f9d423)',
    color: '#fff',
    padding: '2px 10px',
  };
  const gradientStyleSuccess = {
    background: 'linear-gradient(to right, #00b09b, #96c93d)',
    color: '#fff',
    padding: '2px 10px',
  };


  return (
    <HomeContainer background={`url(${HomeBackgroundImage})`}>
      <ToastContainer 
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <HomeContainerLayer background={`url(${HomeBackgroundTransparent})`}>
        <Headers />

        <EntrepriseContainer logincardtextcolor >
          <Button onClick={() => navigate('/dashboard', { replace: true })} size="small" color="primary">
            Retour
          </Button>
          <EntrepriseContainer logincardcontainerorgListe>
            {
              typeLocal == "admin" && (
                <Card 
                  sx={{ width: "70%", marginBottom: "12px", height: "360px", '@media (min-width: 600px)': { width: "345px", border: "2px solid black" } }}
                >
                  <Button onClick={() => navigate('/orgCreationOff', { replace: true })} sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column"}}  size="small" color="primary">
                    <OrgSvg/>
                    Ajouter une nouvelle entreprise
                  </Button>
                </Card>
              )
            }
            {
              typeLocal != "admin" ? (
              <>
                {
                  <Card 
                    key={userOrgInfocPersonnel.id} 
                    //sx={{ maxWidth: 345 }}
                    sx={{ width: "70%", marginBottom: "12px", height: "360px", '@media (min-width: 600px)': { width: "245px", border: "2px solid black" } }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="140"
                        image={`${url}/imagelogo/${userOrgInfocPersonnel.logo}`}
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {userOrgInfocPersonnel.nomEntreprise}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userOrgInfocPersonnel.emailEntreprise}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userOrgInfocPersonnel.telephoneEntreprise}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userOrgInfocPersonnel.ifu}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userOrgInfocPersonnel.rccm}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{display: "flex", justifyContent: "space-between"}}>
                      <Badge
                        style={userOrgInfocPersonnel.etat != true ? gradientStyle : gradientStyleSuccess}
                        content={userOrgInfocPersonnel.etat != true ? "Désactivé" : "Activé"}
                      />
                      {
                        userOrgInfocPersonnel.etat != true && (
                          <Button onClick={() => {handleActifEtat(userOrgInfocPersonnel.id)}} size="small" color="primary">
                            Activer ce profile
                          </Button>
                        )
                      }
                    </CardActions>
                  </Card>
                }
              </>
              ) : (
              <>
                {
                  userOrgInfoc.map(item => (
                    <Card 
                      key={item.id} 
                      //sx={{ maxWidth: 345 }}
                      sx={{ width: "70%", marginBottom: "12px", height: "360px", '@media (min-width: 600px)': { width: "345px" } }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={`${url}/imagelogo/${item.logo}`}
                          alt="green iguana"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {item.nomEntreprise}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.emailEntreprise}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.telephoneEntreprise}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.ifu}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.rccm}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions sx={{display: "flex", justifyContent: "space-between"}}>
                        <Badge
                          style={item.etat != true ? gradientStyle : gradientStyleSuccess}
                          content={item.etat != true ? "Désactivé" : "Activé"}
                        />
                        {
                          item.etat != true && (
                            <Button onClick={() => {handleActifEtat(item.id)}} size="small" color="primary">
                              Activer ce profile
                            </Button>
                          )
                        }
                      </CardActions>
                    </Card>
                  ))
                }
              </>
              )
            }
          </EntrepriseContainer>
        </EntrepriseContainer>

        <Footers/>
      </HomeContainerLayer>
    </HomeContainer>
  )
}
export default OrganizationListe;
