import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCardVente from './ProductCardVente';

// ----------------------------------------------------------------------

ProductListVente.propTypes = {
  articles: PropTypes.array.isRequired,
  onClickVente: PropTypes.func.isRequired,
  onClickView: PropTypes.func.isRequired,
};

export default function ProductListVente({ articles, onClickVente, onClickView, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {articles.map((article) => (
        <Grid key={article.id_art} item xs={12} sm={6} md={3}>
          <ShopProductCardVente 
            article={article} 
            onClickVente={onClickVente}
            onClickView={onClickView}
          />
        </Grid>
      ))}
    </Grid>
  );
}