import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";
import { NavLink } from "react-router-dom";

export const HomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: ${(props) => props.background};
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
`;

export const HomeContainerLayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: ${(props) => props.background};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const HomeBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 24px;
  /* border: 2px solid yellow; */
  padding-top: 4%;

  //tablet and desktop display
  @media ${devices.tablet} {
    gap: 32px;
  }
`;
export const HomeBodyContainerGreating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const HomeBodyAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 40px;
  /*border: 3px solid yellow;*/
  flex-wrap: wrap;
`;

export const HomeBodyAppWrap = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  gap: 8px;
  background: transparent;
  border: none;
  outline: none;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    color: #000000;
  }

  &:hover {
    text-decoration: none;
  }

  /* on Mobile this will help to control how we are displaying app on mobile vs desktop view*/
    ${(props) =>
    props.mobileappdisplay &&
    css`
      display: flex;

      //tablet and desktop display
      @media ${devices.tablet} {
        display: none;
      }
    `}

    /* on Desktop this will help to control how we are displaying app on mobile vs desktop view*/

    ${(props) =>
    props.desktopappdisplay &&
    css`
      display: none;

      //tablet and desktop display
      @media ${devices.tablet} {
        display: flex;
      }
    `}

    ${(props) =>
      props.menustyleforme &&
      css`
        &:hover {
          div {
            background: ${(props) => {
              switch (props.menustyleforme) {
                case "Tableau De Bord":
                  return "#D25FDE";
                case "Approvisionement":
                  return "#009c5b";
                case "Point Journalier":
                  return "#FF8E26";
                case "Historique de Ventes":
                  return "#4654F7";
                case "Gestion des Ventes":
                  return "#D25FDE";
                case "Gestion du personnel":
                  return "#D25FDE";
                default:
                  return "#D25FDE";
              }
            }};
            svg {
              fill: #ffffff;
            }
          }
        }
      `}


  // this is here only for when you hover over the app button

    // dropin app button
    ${(props) =>
    props.dropin &&
    css`
      &:hover {
        div {
          background: #009c5b;
          svg {
            fill: #ffffff;
          }
        }
      }
    `}

    // tutor portal app button
    ${(props) =>
    props.tutorportal &&
    css`
      &:hover {
        div {
          background: #4654F7;
          svg {
            fill: #ffffff;
          }
        }
      }
    `}

    // schedule app button
    ${(props) =>
    props.schedule &&
    css`
      &:hover {
        div {
          background: #FF8E26;
          svg {
            fill: #ffffff;
          }
        }
      }
    `}

    // dashboard app button
    ${(props) =>
    props.dashboard &&
    css`
      &:hover {
        div {
          background: #D25FDE;
          svg {
            fill: #ffffff;
          }
        }
      }
    `}

     // workspace app button
     ${(props) =>
    props.workspace &&
    css`
      &:hover {
        div {
          background: #111439;
          svg {
            fill: #ffffff;
          }
        }
      }
    `}
`;

export const HomeBodyAppIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 64px;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 23, 47, 0.16);
  border-radius: 100px;

  svg {
    width: 32px;
    height: 32px;
  }
`;
