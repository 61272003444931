import React from "react";
import styled from "styled-components";
import { AiOutlineInstagram } from "react-icons/ai";
import { GiEarthAmerica } from "react-icons/gi";
import { FaLinkedinIn } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";

//SVG
import BusinessProStatistiqueSvg from "../../../assets/Svg/BusinessProStatistiqueSvg";

const ProfComponent = () => {
  return (
    <Container id="home">
      <Slide direction="left">
        <Texts>
          <h4>
            Bienvenue, <span className="green">qu'est-ce-que</span>
          </h4>
          <h1 className="green">BUSINESS PRO 9</h1>
          <h3>Une Plateforme de Gestion de Stock</h3>
          <p style={{textAlign: 'justify'}}>    
            BUSINESS PRO 9 est un outil précieux pour les entreprises
            qui cherchent à optimiser leur gestion des stocks, à réduire les 
            coûts et améliorer leur efficacité. Avec BUSINESS PRO 9, vous avez
            la possibilié de suivre les activités de votre entité, avoir un point
            journalier de façon automatisé vous permettant d'avoir le contrôle sur tous
            vos différentes activités, automatisé les entrées et sorties de vos 
            marchandises afin de connaitre vos produits restant et également 
            recevoir régulierement des notifications sur le stock de vos produits restant.
          </p>
          <ContainerBtn>
            <button>
              <a 
                style={{textDecoration: "none", color: "#fff"}} 
                href="#service"
              >
                Voir plus
              </a>
            </button>
            <Social>
              <p>Consultez nos</p>
              <div className="social-icons">
                <span>
                  <a href="/">
                    <AiOutlineInstagram />
                  </a>
                </span>
                <span>
                  <a href="/">
                    <GiEarthAmerica />
                  </a>
                </span>
                <span>
                  <a href="/">
                    <FaLinkedinIn />
                  </a>
                </span>
              </div>
            </Social>
          </ContainerBtn>
        </Texts>
      </Slide>
      <Slide direction="right">
        <Profile>
          <BusinessProStatistiqueSvg />
        </Profile>
      </Slide>
    </Container>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  opacity: 1;
  //border: 2px solid red;
  padding: 0px 12px;
  
  @media (min-width: 840px) {
    width: 90%;
    flex-direction: row;
    //border: 2px solid green;
  }

  @media (min-width: 1150px) {
    width: 90%;
    //border: 2px solid yellow;
  }
`;
const ContainerBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 3rem;
    cursor: pointer;
    background-color: #01be96;
    border: none;
    color: #fff;
    font-weight: 500;
    filter: drop-shadow(0px 10px 10px #01be9551);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
  }
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #01be96;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  svg {
    display: none;
    width: 100%;
    height: 400px;
    filter: drop-shadow(0px 10px 10px #01be9570);
    transition: transform 400ms ease-in-out;
    z-index: 2;
    flex-direction: "center";
    //border: 2px solid blue;
    @media all and (min-width: 600px) {
      width: 22rem;
    }
    @media all and (min-width: 1150px) {
      display: flex;
      width: 40rem;
      height: 500px;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;
