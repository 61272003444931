import styled, { css } from "styled-components";
// import { devices } from "../../../Theme/Devices";

export const ClassCardContainer = styled.div`
  width: 163px;
  height: 130px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #ccd4dc;
  box-shadow: 0px 3px 2px -3px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

export const ClassCardButtonContainer = styled.button`
  width: 163px;
  height: 130px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  background: #F2F4F6;
  border: 0.5px solid #ccd4dc;
  box-shadow: 0px 3px 2px -3px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  span{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    color: #111439;
  }
  
  ${(props) =>
        props.bordernone &&
        css`
          border: none;
          box-shadow: none;
        `
    }
`;

export const ClassCardContainerTop = styled.div`
  width: 100%;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -20px;
`;
export const ClassCardContainerTopWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.1);
`;
export const ClassCardContainerBottom = styled.div`
  width: 100%;
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
`;

export const ClassCardContainerOption = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 4px;
`;

export const ClassCardContainerOptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  /* padding: 0px 4px; */

  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
    border-radius: 2px;
  }
`;
export const ClassCardBottomTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #111439;
  }
`;

export const ClassCardBottomOption = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ClassCardGradeTag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  width: fit-content;
  background: #f0f1ff;
  border-radius: 2px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #2b2e4f;
  }
`;

export const ClassCardLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #007efc;
`;
