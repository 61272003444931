
const size = {
    mobile: '320px',
    tablet: '600px',
    laptop: '1150px',
}

export const devices = {
    mobile: `(min-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
};