import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';

// Component
import { ContainerTopDashboard, ButtonDashboard } from '../TableauDeBord/TableauDeBordStyles';
import { InputContainer, Inputs } from '../../Components/Inputs/InputStyles';
import Scrollbar from '../../Components/scrollbar/Scrollbar';
import { UserListHead, UserListToolbar } from '../TableauDeBord/Section/user';
import Iconify from '../../Components/iconify/Iconify';
import Label from '../../Components/label/Label';
import { Step1AdminOnboardingContainer } from '../../Components/Oboarding/Admin/AdminOnboardingStyle';

import { LoadingButton } from '@mui/lab';
import {
  Card, Table, Stack, Paper, Avatar, Popover, Checkbox,
  TableRow, MenuItem, TableBody, TableCell, Container, Typography,
  IconButton, TableContainer, TablePagination, Box, TextField,
  Select, InputLabel, FormControl, FormControlLabel, Divider, Switch, 
  InputAdornment
} from '@mui/material';
import { Modal, Form, Button } from 'rsuite';

import { 
  PersonnelForm, PersonnelFormContainer
} from './PersonnelStyle';

// Icon
import { AiOutlineClose } from 'react-icons/ai';

import { apiPersonnel, notify, entete, apiMenu, apiCreateEntrepriseActif } from '../../Components/API/Api';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#000000',
  zIndex: 99999
};

const TABLE_HEAD = [
  { id: 'nom', label: 'Nom', alignRight: false },
  { id: 'prenom', label: 'Prenom', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'adresse', label: 'Adresse', alignRight: false },
  { id: 'telephone', label: 'Telephone', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.user.nom.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------



const Personnel = () => {
  const idUser = localStorage.getItem('id');
  const typeLocal = localStorage.getItem('type');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(null);
  const [orderBy, setOrderBy] = useState('nom');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // Data Users
  const [user, setUser] = useState([]);
  const [id_upd, setId_upd] = useState('');
  const [ids, setIds] = useState(0);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [adresse, setAdresse] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const [permissionID, setPermissionID] = useState([]);
  const [entrepriseID, setEntrepriseID] = useState(2);

  
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => {
    setOpenAdd(!openAdd)
    setNom("")
    setPrenom("")
    setAdresse("")
    setEmail("")
    setTelephone("")
    setPassword("")
    setType("")
    setSelectedPermissions([])
  }
  const [openUpdate, setOpenUpdate] = useState(false);
  const handleOpenUpdate = () => {
    setOpenAdd(!openAdd)
    setOpenUpdate(!openUpdate)
  }

  //**************************************************************** */
    // Modal Article
    const [show, setShow] = useState(false);
    const handleClose = () => {
      //setOpenAdd(!openAdd)
      setNom("")
      setPrenom("")
      setAdresse("")
      setEmail("")
      setTelephone("")
      setPassword("")
      setType("")
      setSelectedPermissions([])
      setShow(false)
      setOpenUpdate(false)
    };
    const handleShow = () => {
      setShow(true);
      setOpenUpdate(false)
    };

    /*********************************************** */

    // Modal Update Article
    const [showupd, setShowupd] = useState(false);
    const handleCloseupd = () => { 
      setOpenAdd(!openAdd)
      setNom("")
      setPrenom("")
      setAdresse("")
      setEmail("")
      setTelephone("")
      setPassword("")
      setType("")
      setSelectedPermissions([])
    };
    const handleShowupd = (e) => { setShowupd(true) };

    /******************************************** */
      // Open Modal Update article
      const handleUpdate = (id) => {
        getDataById();
        setIds(id);
        handleShow();
        handleShowupd();
        setOpenUpdate(true)
      };  
  /***************************************************************** */

  // Permission
  const [permission, setPermission] = useState([]);

  // Recuperer id de l'entreprise actif en fonction de l'id de l'user
  const getActifUserById = async () => {
    const token = localStorage.getItem('token');
    try {
      if (typeLocal != "admin") {
        const { data } = await apiPersonnel.get(`/personnel/${idUser}`, {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].entreprises[0].id)
      } else {
        const { data } = await apiCreateEntrepriseActif.get(`/userid/${idUser}`, {
            headers: {
              entete,
              Authorization: `Bearer ${token}`
            }
        })
        console.log(data);
        console.log(data.data);
        setEntrepriseID(data.data[0].id)
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  // Afficher tous les utilisateurs
  const allpermission = async () => {
    const token = localStorage.getItem('token');
    try {
        apiMenu.get('/', {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
          console.log(res.data.data)
          setPermission(res.data.data);
        })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  };

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  
  const handleChange = (permissionId) => {
    if (selectedPermissions.includes(permissionId)) {
      // La permission est déjà dans le tableau, donc on la retire
      setSelectedPermissions(selectedPermissions.filter(id => id !== permissionId));
    } else {
      // La permission n'est pas dans le tableau, donc on l'ajoute
      setSelectedPermissions([...selectedPermissions, permissionId]);
    }
  };

  // Afficher tous les utilisateurs
  const alluser = async () => {
    getActifUserById();
    const token = localStorage.getItem('token');
    try {
      apiPersonnel.get(`/entreprise/${entrepriseID}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        console.log(res.data.data)
        setUser(res.data.data);
      }).catch (err => {
        if (err.response) {
            if (err.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (err.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(err);
            }
        } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(err);
        }
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }; 

  const ajoutUser = async () => {
    //e.preventDefault();
    //e.persist()
    const token = localStorage.getItem('token');
    // console.log(email, password);
    const userID = localStorage.getItem('id');
    const permissionId = selectedPermissions;
    if(nom != "" && prenom != "" && email != "" && type != "" && password != "" && permissionId != "") {
      try {
        await apiPersonnel.post('/', {
          nom,
          prenom,
          adresse,
          email,
          telephone,
          type,
          password,
          userID,
          entrepriseID,
          permissionId
        },{
          entete,
          Authorization: `Bearer ${token}`
        }).then((res) => {
          setNom("")
          setPrenom("")
          setAdresse("")
          setEmail("")
          setTelephone("")
          setPassword("")
          setType("")
          handleOpenAdd()
          handleClose()
          setSelectedPermissions([])
          setUser([]);
          alluser();
          setStatus(true);
          notify('success', `${res.data.message}`);
        }).catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.message === "L'email existe déjà pour un utilisateur.") {
            notify('warning', "L'email existe déjà pour un utilisateur.");
          } else {
            notify('warning', "Vos informations sont incorrectes");
          }
        })
      } catch (error) { 
        if (error.response) {
            if (error.response.status === 404) {
                // Gérez spécifiquement l'erreur 404
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 404 : Page non trouvée");
            } else if (error.response.status === 401) {
                // Gérez spécifiquement l'erreur 401
                // Affichez un message d'erreur ou effectuez d'autres actions
                console.log("Erreur 401 : Requête non autorisée");
            } else {
                // Gérez les autres erreurs
                // Affichez un message d'erreur générique ou effectuez d'autres actions
                console.log(error);
            }
        } else {
            // Gérez les autres erreurs qui n'ont pas de réponse associée
            console.log(error);
        }
      }
    } else {
      notify('warning', "Les champs sont vides");
    }
  };

  const getDataById = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await apiPersonnel.get(`/${ids}`, {
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      })
      console.log(data);
      console.log(data.data);
      setId_upd(data.data.id)
      setNom(data.data.nom)
      setPrenom(data.data.prenom)
      setAdresse(data.data.adresse)
      setEmail(data.data.email)
      setTelephone(data.data.telephone)
      setPassword(data.data.password)
      setType(data.data.type)
      setPermissionID(data.data.permissions); 
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  const updateUser = async () => {
    // alert(ids)
    // e.preventDefault();
    const token = localStorage.getItem('token');
    const permissionId = selectedPermissions;
    const userID = localStorage.getItem('id');

    if(permissionId.length !== 0) {
      try {
        await apiPersonnel.patch(`/${ids}`, 
        { 
          nom,
          prenom,
          adresse,
          email,
          telephone,
          type,
          password,
          entrepriseID,
          permissionId
        },
        {
          headers: {
            entete,
            Authorization: `Bearer ${token}`
          }
        }).then(res => {
          setNom("")
          setPrenom("")
          setAdresse("")
          setEmail("")
          setTelephone("")
          setPassword("")
          setType("")
          setOpenUpdate(!openUpdate)
          setOpenAdd(!openAdd)
          setSelectedPermissions([])
          setUser([]);
          handleClose()
          alluser();
          setStatus(true);
          notify('success', `${res.data.message}`);
        })
      } catch (error) { 
        if (error.response && error.response.status === 404) {
          // Gérez spécifiquement l'erreur 404
          // Affichez un message d'erreur ou effectuez d'autres actions
        } else {
          // Gérez les autres erreurs
          // Affichez un message d'erreur générique ou effectuez d'autres actions
        }
      }
    } else {
      notify('warning', `Attention!!, Vérifiez que vous avez bien recréé les droits d'accès`);
    }
  };

  // Supprimer un categorie
  const deleteConfirm = (ids) => {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Confirmer la suppression',
      denyButtonText: 'Non'

    }).then((result) => {
      // Read more about isConfirmed, isDenied below 
      if (result.isConfirmed) {
        deleteArticle(ids);
      } else if (result.isDenied) {
        Swal.fire('Suppression Annulée', '', 'info')
      }
    })
  };

  const deleteArticle = async (ids) => {
    const token = localStorage.getItem('token');
    try {
      apiPersonnel.delete(`/${ids}`, {
        id: ids,
        headers: {
          entete,
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        // alert(res.data)
        setUser([]);
        alluser();
        notify('success', `${res.data.message}`);
      })
    } catch (error) { 
      if (error.response) {
          if (error.response.status === 404) {
              // Gérez spécifiquement l'erreur 404
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 404 : Page non trouvée");
          } else if (error.response.status === 401) {
              // Gérez spécifiquement l'erreur 401
              // Affichez un message d'erreur ou effectuez d'autres actions
              console.log("Erreur 401 : Requête non autorisée");
          } else {
              // Gérez les autres erreurs
              // Affichez un message d'erreur générique ou effectuez d'autres actions
              console.log(error);
          }
      } else {
          // Gérez les autres erreurs qui n'ont pas de réponse associée
          console.log(error);
      }
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = user.map((n) => n.nom);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, nom) => {
    const selectedIndex = selected.indexOf(nom);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nom);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - user.length) : 0;

  const filteredUsers = applySortFilter(user, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const rowsPerPageOptions = [5, 10, 25];

  for (let i = 25; i <= user.length; i += 50) {
    rowsPerPageOptions.push(i);
  }

  useEffect(() => {
    alluser();
    allpermission();
  }, []);


  useEffect(() => {
    getActifUserById();
  }, [idUser]);

  useEffect(() => {
    alluser();
  }, [entrepriseID]);

  return (
    <Step1AdminOnboardingContainer>

      <UserListToolbar label={"Rechercher un utilisateur"} filterName={filterName} onFilterName={handleFilterByName} />
      
      
      <div style={{width: "100%", display: "flex", justifyContent: "flex-end", padding: "8px 0px"}}>
        <Button onClick={handleShow} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
          Ajouter une personnel
        </Button>
      </div>

      <Card sx={{ background: 'transparent' }}>
        <Scrollbar sx={{ height: "auto", overflow: "hidden", overflowY: "scroll"}}>
          <TableContainer sx={{ minWidth: "100%" }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={user.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { user} = row;
                  const selectedUser = selected.indexOf(nom) !== -1;

                  return (
                    <TableRow hover key={user.id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, nom)} />
                      </TableCell>

                      <TableCell align="left">
                        {user.nom}
                      </TableCell>

                      <TableCell align="left">{user.prenom}</TableCell>

                      <TableCell align="left">{user.email}</TableCell>
                      
                      <TableCell align="left">{user.adresse}</TableCell>

                      <TableCell align="left">{user.telephone}</TableCell>

                      <TableCell align="left">
                        <Label color={'secondary'}>{sentenceCase(user.type)}</Label>
                      </TableCell>

                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenu(event, user.id); setIds(user.id) }}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Pas trouvé
                          </Typography>

                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
              )}
            </Table>
            <TablePagination
              sx={{width: "100%"}}
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={user.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => { handleUpdate(); setIds(ids); handleCloseMenu()}}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Modifier
        </MenuItem>

        <MenuItem onClick={() => { deleteConfirm(ids); handleCloseMenu() }} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Supprimer
        </MenuItem>
      </Popover>


      {/* MODAL ADD USER */}
      <Modal
        open={show}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>{ openUpdate == false ? ("Ajouter un personnel") : ("Modifier un personnel")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form fluid onSubmit={openUpdate == false ? ajoutUser : updateUser} style={{width: "100%", backgroundColor: "#FFFFFF", color: "#000000"}}>
            <FormControl variant="standard" sx={{  width: "100%" }}>
              <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={type} 
                onChange={(e) => setType(e.target.value)} 
                label="Type"
                required
              >
                <MenuItem value="0">
                  <em>Choisir un Type</em>
                </MenuItem>
                <MenuItem value="caissier">
                  <em>Caissier</em>
                </MenuItem>
                <MenuItem value="comptable">
                  <em>Comptable</em>
                </MenuItem>
                <MenuItem value="vendeur">
                  <em>Vendeur</em>
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="nom"
              type='text'
              label="Nom"
              variant="standard"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="prenom"
              type='text'
              label="Prenom"
              variant="standard"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="adresse"
              type='text'
              label="Adresse"
              variant="standard"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="email"
              type='email'
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="telephone"
              type='number'
              label="Téléphone"
              variant="standard"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              required
              sx={{width: "100%", marginBottom: "20px"}}
            />
            <TextField
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="Mot de passe"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{width: "90%", marginBottom: "20px"}}
            />
            <Box>
              {
                openUpdate == false ? (
                  "Donnez à cet utilisateur des accès"
                ) : (
                  "Recréé à l'utilisateur ces accès"
                )
              }
              {permission.slice(0, 5).map(item => (
                <div key={item.id}>
                  <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selectedPermissions.includes(item.id)}
                          onChange={() => handleChange(item.id)}
                          name="gilad"
                        />
                      }
                      label={item.designation}
                    />
                  </div>
                </div>
              ))}
            </Box>

            <Modal.Footer>
              <Button type='submit' appearance="primary">
                { openUpdate == false ? ("Ajouter") : ("Modifier")}
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Fermer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </Step1AdminOnboardingContainer>
  )
}
export default Personnel;