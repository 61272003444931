import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import BrandDarkSvg from '../../../assets/Svg/BrandSvg';

const Header = () => {
    const token = localStorage.getItem('token');
    const [bar, setBar] = useState(false);
    const [longPress, setLongPress] = useState(false); // État pour suivre le "clic long"
    const handleMenuItemClick = () => {
        // Si le "clic long" est en cours, ne fermez pas le menu
        if (!longPress) {
            setBar(false);
        }
    };
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            // Si le "clic long" est en cours, ne fermez pas le menu
            if (!longPress) {
                setBar(false);
            }
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [longPress]); // Inclure le "clic long" comme dépendance de useEffect

    // Gérer le "clic long"
    const handleLongPress = () => {
        setLongPress(true);
    };

    const handleLongPressEnd = () => {
        setLongPress(false);
    };

    return (
        <Container bar={bar}>
            <Logo>
                <span className='green'>
                    <BrandDarkSvg />
                </span>
                <h1>BUSINESS PRO 9</h1>
            </Logo>
            <Nav bar={bar} ref={menuRef}>
                <span><a href="#home" onClick={handleMenuItemClick}>Accueil</a></span>
                <span><a href="#apropos" onClick={handleMenuItemClick}>A propos</a></span>
                <span><a href="#service" onClick={handleMenuItemClick}>Prestations de service</a></span>
                <span><a href="#footer" onClick={handleMenuItemClick}>Contact</a></span>
                {
                    token ? (
                        <span><a href="/dashboard">Tableau de bord</a></span>
                    ) : (
                        <span><a href="/auth" onClick={handleMenuItemClick}>Connexion/S'inscrire</a></span>
                    )
                }
            </Nav>
            <div
                onClick={() => setBar(!bar)}
                onMouseDown={handleLongPress} // Gérer le début du "clic long"
                onMouseUp={handleLongPressEnd} // Gérer la fin du "clic long"
                onMouseLeave={handleLongPressEnd} // Gérer la fin du "clic long" lorsque le curseur quitte le bouton
                className="bars"
            >
                <div className="bar"></div>
            </div>
        </Container>
    )
}

export default Header;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    //max-width: 1280px;
    width: 100%;
    //border: 2px solid white;
    margin: 0 auto;
    padding: 1rem 2rem;
    position: fixed;
    z-index: 9999;
    background-color: #0B1A46;
    animation: header 500ms ease-in-out;
    opacity: 0.8;
    @media(max-width: 840px){
        width: 100%;
        padding: 1rem;
    }
    .bars{
        display: none;
    }
    @media(max-width:682px){
        .bars{
            width: 40px;
            height: 40px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5rem;
            z-index: 999999;
            .bar{
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: ${props => props.bar ? "transparent" : "#fff"};
                transition: all 400ms ease-in-out;
                :before, :after{
                    content: "";
                    width: 100%;
                    height: 2px;
                    background-color: #fff;
                    position: absolute;
                }

                :before{
                    transform: ${props => props.bar ? "rotate(45deg)" : "translateY(10px)"};
                    transition: all 400ms ease-in-out;
                }

                :after{
                    transform: ${props => props.bar ? "rotate(-45deg)" : "translateY(-10px)"};
                    transition: all 400ms ease-in-out;
                }
            }
        }
    }
`
const Logo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #fff;
    span{
        font-size: 1.8rem;
    }

    h1{
        font-weight: 600;
        font-size: 1rem;
        @media (min-width: 600px) {
            font-size: 0.5rem;
        }

        @media (min-width: 730px) {
            font-size: 1rem;
        }
        
        @media (min-width: 1150px) {
            font-size: 1.2rem;
        }
    }
    
    .green {
        width: 60px;
    }
`
const Nav = styled.div`
    z-index: 99999;
    @media(max-width:682px){
        position: fixed;
        display: flex;
        flex-direction: column;
        background-color: #0B1A46;
        inset: 0;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        gap: 2rem;
        font-weight: 700;
        height: ${props => props.bar ? "60vh" : 0};
        transition: height 400ms ease-in-out;
        overflow: hidden;
        z-index: 100;
    }
    span{
        margin-left: 1rem;
        a{
            color: #fff;
            text-decoration: none;
            font-weight: 400;
            position: relative;
            :before{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -5px;
                height: 2px;
                background-color: #fff;
                transform: scale(0);
                transform-origin: right;
                transition: transform 400ms ease-in-out;
            }
            :hover:before{
                transform: scale(1);
                transform-origin: left;
            }
            :hover{
                opacity: 0.7;
            }
        }
    }
`