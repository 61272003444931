import axios from "axios";
import { toast } from 'react-toastify';


// Entete
export const entete = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
}
export const entetes = {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
}

export const notify = (type, text) => {
    switch (type) {
        case 'success':
            toast.success(text)
            break;
        case 'warning':
            toast.warning(text)
            break;
        case 'info':
            toast.info(text)
            break;
        default:
            toast.error(text)
            break;
    }
}


export const url = "https://back.businesspro9.com";
//export const url = "http://localhost:4000";

// AUTH EMAIL
export const apiAuthEmail = axios.create({
    baseURL: `${url}/api/login-email`
})

// AUTH
export const apiAuth = axios.create({
    baseURL: `${url}/api/login`
})

// MENU
export const apiMenu = axios.create({
    baseURL: `${url}/api/menu`
})

// SIGN UP
export const apiSignUp = axios.create({
    baseURL: `${url}/api/user`
})

// Verification Account
export const apiVerification = axios.create({
    baseURL: `${url}/api/verify-code`
})

// Creation du code de verification pour modifier le mot de passe
export const apiCreateCodeVerify = axios.create({
    baseURL: `${url}/api/verify-code-auth`
})

// Creation du code de verification pour modifier le mot de passe
export const apiVerificationForPassword = axios.create({
    baseURL: `${url}/api/verify-code-password`
})

//  Modifier le mot de passe
export const apiUpdatePassword = axios.create({
    baseURL: `${url}/api/user-email-password`
})

//  Entreprise
export const apiCreateEntreprise = axios.create({
    baseURL: `${url}/api/entreprise-manager`
})

//  Entreprise
export const apiCreateEntrepriseActif = axios.create({
    baseURL: `${url}/api/entreprise-manager-etat`
})

//  Entreprise
export const apiIDEntreprise = axios.create({
    baseURL: `${url}/api/findOrgByUserID`
})

//  Recuperer le logo de l'entreprise
export const apiGetImageLogo = axios.create({
    baseURL: `${url}/imagelogo`
})

//  Entreprise
export const apiChangeEtatToActif = axios.create({
    baseURL: `${url}/api/entreprise-manager-etat-true`
})

//  Personnel
export const apiPersonnel = axios.create({
    baseURL: `${url}/api/underUser`
})

// SOUS CATEGORIE
export const apiSousCat = axios.create({
    baseURL: `${url}/api/souscategorie`
})

// CATEGORIE
export const apiCategorie = axios.create({
    baseURL: `${url}/api/categorie`
})

// ARTICLE
export const apiArticle = axios.create({
    baseURL: `${url}/api/article`
})

// ARTICLE
export const apiArticleCommander = axios.create({
    baseURL: `${url}/api/article-commander`
})

// ARTICLE
export const apiStock = axios.create({
    baseURL: `${url}/api/stock`
})

// VENTE
export const apiVente = axios.create({
    baseURL: `${url}/api/vente`
})

// VENTE
export const apiContact = axios.create({
    baseURL: `${url}/api/contact`
})