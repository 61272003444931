
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

export const Links = styled.a`
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: var(--body-regular-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-130);
    color: var(--link-text-color);
    cursor: pointer;
    text-decoration: none;
  // Legal links
  ${(props) =>
    props.legallinks &&
    css`
    color: var(--subText);
    font-size: var(--body-xs);
    `}
`
export const StyledNavLink = styled(NavLink)`
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: var(--body-regular-weight);
    font-size: var(--body-sm);
    line-height: var(--line-height-130);
    color: var(--link-text-color);
    cursor: pointer;
    text-decoration: none;
  // Legal links
  ${(props) =>
    props.legallinks &&
    css`
    color: var(--subText);
    font-size: var(--body-xs);
    `}
`