import React from "react";
import styled from "styled-components";

//SVG
import Logo from "../../../assets/images/logo.png";

const SectionOne = () => {
  return (
    <Container id="home">
        <div style={{padding: "1.8rem 0"}}></div>
        <Images src={Logo} alt="BUSINESS PRO 9"/>
        <Title>BUSINESS PRO 9</Title>
        <p>Optimisez vos opérations de gestion de stock et boostez votre rentabilité grâce à notre plateforme de pointe</p>
        <button>
            <a 
                style={{textDecoration: "none", color: "#fff"}} 
                href="#footer"
            >
                En savoir plus
            </a>
        </button>
    </Container>
  );
};

export default SectionOne;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    z-index: 99;
    opacity: 1;
    padding: 20px;
    //border: 2px solid yellow;
  
    p {
        font-family: 'Sometype Mono', monospace;
        text-align: center;
    }

    button {
        padding: 0.7rem 2rem;
        margin-top: 3rem;
        cursor: pointer;
        background-color: #01be96;
        border: none;
        color: #fff;
        font-weight: 500;
        filter: drop-shadow(0px 10px 10px #01be9551);
        :hover {
          filter: drop-shadow(0px 10px 10px #01be9570);
        }
    }
    
    /*@media (min-width: 600px) {
        width: 90%;
        flex-direction: row;
        border: 2px solid green;
    }
    
    @media (min-width: 1150px) {
        width: 90%;
        border: 2px solid yellow;
    }*/
  
`;

const Images = styled.img`
    height: 200px;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-family: 'Russo One', sans-serif;

    @media (min-width: 600px) {
        font-size: 4rem;
    }

    @media (min-width: 1150px) {
        font-size: 6rem;
    }
`;
