import React from "react";

export default function TutorPortalSvg() {
  return (
    <svg
      height="512"
      viewBox="0 0 32 32"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      fill="#4654F7"
    >
      <g id="Layer_9" data-name="Layer 9">
        <path d="m27 2h-22a3 3 0 0 0 -3 3v16c.17 3.86 4.42 2.89 7 3a1 1 0 0 0 0-2h-4a1 1 0 0 1 -1-1v-16a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v16a1 1 0 0 1 -1 1h-4a1 1 0 0 0 0 2h4a3 3 0 0 0 3-3v-16a3 3 0 0 0 -3-3z" />
        <path d="m16 16c-5.91-.1-5.91-8.89 0-9 5.91.1 5.91 8.89 0 9z" />
        <path d="m21 22v6a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2-2v-6a5 5 0 0 1 10 0z" />
      </g>
    </svg>
  );
}
