import React, { useEffect, useState } from "react";
import 'moment-timezone';

//Style
import { ClockContainer } from "./ClockStyle";

const Clock = () => {
  
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const hours = time.getHours().toString().padStart(2, '0');
  const minutes = time.getMinutes().toString().padStart(2, '0');
  // const seconds = time.getSeconds().toString().padStart(2, '0');
  // const dateDays = time.toLocaleDateString();

  return (
    <ClockContainer>
      <span>{`${hours}:${minutes}`}</span>
    </ClockContainer>
  );
};
export default Clock;





