import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";

export const ClassPackagesContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  width: 200px;
  height: 295px;
  background: ${({ background }) => background || "#ffffff"};
  border: 1px solid #ccd4dc;
  border-radius: 16px;
  box-shadow: 0px 3px 2px -3px rgba(0, 0, 0, 0.25);

  ${(props) =>
    props.package_container &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.methodpaid &&
    css`
      width: 250px;
      height: 330px;
      padding: 40px 24px;
      gap: 16px;
      @media ${devices.laptop} {
        width: 300px;
      height: 430px;
      }
    `}
`;

export const ClassPackagesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 124px;
  width: 100%;
  height: 34px;
  background: ${(props) => props.bg};
  border-radius: 16px 16px 0px 0px;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #fbfbfb;
  }
`;

export const ClassPackagesBodyTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 8px;
  width: 90%;
  border-bottom: 0.5px solid #ccd4dc;

  .session {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #111439;
    text-align: center;
  }

  .day {
    font-weight: 700;
    font-size: 10px;
    line-height: 17px;
  }

  .free {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: flex-end;
    color: #111439;
  }

  .dollard {
    font-size: 14px;
  }

  .number_minute {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #929292;
  }
`;

export const ClassPackagesBodyBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  gap: 4px;
  width: 80%;
`;

export const ClassPackages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  .number_session_day {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #393939;
  }
`;
