import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Components
import { ButtonStyles } from '../../../Components/Buttons/ButtonStyles';
import { Links, StyledNavLink } from "../../../Components/Links/LinksStyle";
import { HintContainer, HintDescription, InputLabel, Inputs } from "../../../Components/Inputs/InputStyles";

//Icons
import BrandDarkSvg from "../../../assets/Svg/BrandSvg";

// Styles
import "../StylesAuth.css";
import {
    BodyContainer,
    LoginCard,
    BrandLogo,
    LoginHeadWrap,
    LoginHeadTitle,
    LoginHeadDescription,
    EmailLoginWrap,
    LoginFooterWrap,
    LoginFooterTop,
    PasswordEmailContainer,
    PasswordEmail,
    LoginFooterTopWrap,
    SubBodyContainer
} from "../AuthStyle";
import { InputContainer } from '../../../Components/Inputs/InputStyles';
import { InputPassword } from '../../../Components/InputPassword/InputPassword';

//Images
import HomeBackgroundImage from "../../../assets/images/1.jpg";

import { apiUpdatePassword, notify } from '../../../Components/API/Api';


const UpdatePassword = () => {
    const email = localStorage.getItem('email');
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();
    const [userInfoc, setuserInfoc] = useState('');
    const [statusCode, setStatutCode] = useState(false);

    // validated states
    const [lowerValidated, setLowerValidated] = useState(false);
    const [upperValidated, setUpperValidated] = useState(false);
    const [numberValidated, setNumberValidated] = useState(false);
    const [specialValidated, setSpecialValidated] = useState(false);
    const [lengthValidated, setLengthValidated] = useState(false);

    const handleChange = (value) => {
        const lower = new RegExp("(?=.*[a-z])");
        const upper = new RegExp("(?=.*[A-Z])");
        const number = new RegExp("(?=.*[0-9])");
        const special = new RegExp(`(?=.*[!@#\$%\^&\*])`);
        const length = new RegExp("(?=.{8,})");
        if (lower.test(value)) {
        setLowerValidated(true);
        } else {
        setLowerValidated(false);
        }
        if (upper.test(value)) {
        setUpperValidated(true);
        } else {
        setUpperValidated(false);
        }
        if (number.test(value)) {
        setNumberValidated(true);
        } else {
        setNumberValidated(false);
        }
        if (special.test(value)) {
        setSpecialValidated(true);
        } else {
        setSpecialValidated(false);
        }
        if (length.test(value)) {
        setLengthValidated(true);
        } else {
        setLengthValidated(false);
        }
    };

    const onChangeValue = (e) => {
        setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
    };

    const onChangeValuePassword = (e) => {
        setuserInfoc({ ...userInfoc, [e.target.name]: e.target.value });
        handleChange(e.target.value);
    };

    const updatePassword = async (e) => {
        e.preventDefault();
        setStatutCode(true);

        if (userInfoc.password != "" && userInfoc.confirmepassword) {
            try {
                await apiUpdatePassword.patch('/', {
                    email : email,
                    newPassword : userInfoc.password,
                }).then((res) => {
                  notify('success', `${res.data.message}`);
                  navigate('/Passwords', { replace: true });
                }).catch((err) => {
                    console.log(err);
                    setStatutCode(false);
                });
            } catch (error) {
                setStatutCode(false);
                if (error.response) {
                    if (error.response.status === 404) {
                        // Gérez spécifiquement l'erreur 404
                        // Affichez un message d'erreur ou effectuez d'autres actions
                        console.log("Erreur 404 : Page non trouvée");
                    } else if (error.response.status === 401) {
                        // Gérez spécifiquement l'erreur 401
                        // Affichez un message d'erreur ou effectuez d'autres actions
                        console.log("Erreur 401 : Requête non autorisée");
                    } else {
                        // Gérez les autres erreurs
                        // Affichez un message d'erreur générique ou effectuez d'autres actions
                        console.log(error);
                    }
                } else {
                    // Gérez les autres erreurs qui n'ont pas de réponse associée
                    console.log(error);
                }
            }
        } else {
            notify('warning', "Renseignez le champ pour modifier votre mot de passe");
            setMsg('Les champs sont obligatoires')
            setStatutCode(false);
        }
    }



    return (
        <SubBodyContainer background={`url(${HomeBackgroundImage})`}>
            <BodyContainer>
                <ToastContainer 
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <LoginCard onSubmit={updatePassword}>
                    {/* ********************Header Login Start******************** */}
                    <BrandLogo>
                        <BrandDarkSvg />
                    </BrandLogo>
                    <LoginHeadWrap>
                        <LoginHeadTitle>Confirmation de l'email réussi </LoginHeadTitle>
                        <LoginHeadDescription>
                            Modifier votre Mot de Passe
                        </LoginHeadDescription>
                    </LoginHeadWrap>
                    {/* ********************Header Login End******************** */}

                    {/* ********************Input and Button Login Start******************** */}

                    <EmailLoginWrap>
                        <InputContainer>
                            <InputLabel>Saisissez votre mot de passe</InputLabel>
                            <Inputs
                                width={"100%"}
                                type="password"
                                placeholder="Entrez le nouveau mot de passe"
                                name = "password"
                                value={userInfoc.password}
                                onChange={onChangeValue}
                                required
                            />
                            {
                                msg != "" && (
                                    <HintContainer>
                                        <HintDescription hintdescriptionerror>
                                            { msg }
                                        </HintDescription>
                                    </HintContainer>
                                )
                            }
                        </InputContainer>

                        <InputContainer>
                            <InputPassword 
                                name = "confirmepassword"
                                value={userInfoc.confirmepassword}
                                handle={onChangeValuePassword}
                                lowerValidated={lowerValidated}
                                upperValidated={upperValidated}
                                specialValidated={specialValidated}
                                lengthValidated={lengthValidated}
                                numberValidated={numberValidated}
                                required
                            />
                            {
                                msg != "" && (
                                    <HintContainer>
                                        <HintDescription hintdescriptionerror>
                                            { msg }
                                        </HintDescription>
                                    </HintContainer>
                                )
                            }
                        </InputContainer>
                        
                        {
                            !statusCode ? (
                                <ButtonStyles
                                    width={"100%"}
                                    primarybuttom
                                    primarymediumbuttom
                                    type='submit'
                                >
                                    Modifier le mot de passe
                                </ButtonStyles>
                            ) : (
                                <ButtonStyles
                                    width={"100%"}
                                    primarybuttom
                                    primarymediumbuttom
                                    type='submit'
                                >
                                    Modification en cours...
                                </ButtonStyles>
                            )
                        }
                    </EmailLoginWrap>

                    {/* ********************Input and Button Login End******************** */}
                </LoginCard>
            </BodyContainer>
        </SubBodyContainer>
    )
}
export default UpdatePassword;