import React from "react";

//Component
import { WelcomeDescriptionCover } from "../Oboarding/Admin/AdminOnboardingStyle";

// style
import { FooterContainer } from "./FootersStyle";
import { SetupLink } from "../Header/HeaderStyle";

const Footers = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <span >
        <WelcomeDescriptionCover description>
          <span style={{textAlign: "justify", color: "#FFFFFF", fontFamily: "Times New Roman"}}>
            &copy; {currentYear} Tout droit réservé, design and
            development by 
              <button> 
                <a 
                  href="https://portfolio-gilchrist.web.app/" 
                  target="_blanck"
                  style={{textDecoration: "none"}}
                >
                  <SetupLink>
                    <span style={{fontFamily: "Times New Roman"}}> YALO Gilchrist</span>
                  </SetupLink>
                </a>
              </button>
            </span>
        </WelcomeDescriptionCover>
      </span>
    </FooterContainer>
  );
};
export default Footers;
