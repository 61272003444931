import React from "react";
import appro from "./2542453.png";

export default function OrgSvg() {
  return (
    <div width="90%" height="100%" viewBox="0 0 433 433" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <img src={appro} alt="approvisionement" />
    </div>

  );
}
