import { createGlobalStyle } from "styled-components";
import "rsuite/dist/rsuite.min.css";

export const GloblaStyles = createGlobalStyle`
    /* Global styles */

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}
body {
    font-family: Helvetica, Arial, sans-serif;
    color: #fff;
}

:root {
    /*------------ COLORS ---------------*/
    /* primary color 500 Base */
    --primary-color: #111439; /* Customizable */
    /* Primary 800 HeaderText */
    --header-text-color: #111439;
    /* Primary 900 Body Text */
    --body-text-color: #425466;
    /* Primary 400 links */
    --link-text-color: #007EFC; /* Customizable */
    /* Brand Hover */
    --brand-hover: #0041B8; /* Customizable */
    --off-background: #FCF7EC;
    /* Transparent Background */
    --transparent-background: transparent;
    /* Profile Background */
    --profile-background: #0388D1;
    /* background gradient */
    --background-section-gradient: linear-gradient(180deg, rgba(251, 246, 235, 0) 0%, #FBF6EB 28.13%);
    /* Basic White */
    --primary-white: #FFFFFF;
    /* Placeholder text */
    --placeholder-text: #90A3B9;
    /* Input border */
    --input-border: #CCD4DC;
    /* Input background */
    --input-background: #EEF2F6;
    /* Success */
    --success-color: #00A85B;
    /* Warning */
    --warning-color: #FFA90B;
    /* Error */
    --error-color: #EB0052;
    /* Brand 10 T */
    --brand-10T: #E5E5E9;
    /* Brand 20 T */
    --brand-20T: #CACBD3;
    /* Brand 25 T */
    --brand-25T: #B0B1BD;
    /* Brand 30 T */
    --brand-30T: #9597A7;
    /* Brand 50 S */
    --brand-50S: #7B7C91;
    /* Brand 100 S */
    --brand-100S: #60627B;
    /* Brand 200 S */
    --brand-200S: #464865;
    /* Brand 300 S */
    --brand-300S: #2B2E4F;
    /* Brand 500 S */
    --brand-500S: #111439;
    /* Brand 700 S */
    --brand-700S: #010104;
    /* SubText */
    --subText: #425466;
    /* Palceholder */
    --palceholder: #778797;
    /* Input */
    --input: #CCD4DC;
    /* Surface 100 */
    --surface-100: #EAEFF2;
    /* Surface 50 */
    --surface-50: #E6E9ED;
    /* Surface 25 */
    --surface-25: #F2F4F6;
    /* System info surface */
    --system-Info-Surface: #F0F1FF;
    /* System error surface */
    --system-Error-Surface: #FFDADA;
    /* System success surface */
    --system-success-surface: #D7F8E4;
    /* System warning surface */
    --system-warning-surface: #FFC795;
    /* Surface 10 */
    --surface-10: #FAFAFB;
    /* Primary bottom text color */
    --primary-bottom-text-color: #FFFFFF;
    /* Secondary bottom text color */
    --secondary-bottom-text-color: var(--primary-color);
    /* Tertiary bottom text color */
    --tertiary-bottom-text-color: var(--primary-color);
    /* Password condition valid */
    --password-condition-valid: #00D649;


    /*------------ TYPOGRAPHY ---------------*/
    /* Primary font family */
    --primary-font-family: Helvetica, Arial, sans-serif;


    /*------------ FONTS ---------------*/
    /* Header WXXL  */
    --header-wxxl: 5rem; /* 80px */
    /* Header WXL  */
    --header-wxl: 4.5rem; /* 72px */
    /* Header XXL */
    --header-xxl: 4rem; /* 64px */
     /* Header XL */
     --header-xl: 3.5rem; /* 56px */
    /* Header LG */
    --header-lg: 3rem;  /* 48px */
    /* Header MD */
    --header-md: 2.5rem; /* 40px */
    /* Header SM */
    --header-sm: 2rem; /* 32px */
    /* Header XS */
    --header-xs: 1.5rem;  /* 24px */
    /* Header XXS */
    --header-xxs: 1.25rem; /* 20px */
    /* Body Regular LG */
    --body-lg: 1.25rem; /* 20px */
    /* Body Regular MD */
    --body-md: 1rem; /* 16px */
    /* Body Regular SM */
    --body-sm: 0.9rem;  /* 14px */
    /* Body Regular XS */
    --body-xs: 0.75rem; /* 12px */
    /* Label MD */
    --label-md: 0.62rem; /* 10px */
    /* Label SM */
    --label-sm: 0.5rem; /* 8px */


    /*------------ WEIGHTS ---------------*/
    /* Header Weight */
    --header-weight: 700;
    /* font Weight 600 */
    --font-weight-600: 600;
    /* Body Semibold Weight */
    --body-semi-bold-weight: 500;
    /* Body Regular Weight */
    --body-regular-weight: 400;


    /*------------ SPACES ---------------*/
    /* Spacing 5 */
    --spacing-5px: 5px;
    /* Spacing 4 */
    --spacing-4px: 4px;
    /* Spacing 8 */
    --spacing-8px: 8px;
    /* Spacing 16 */
    --spacing-16px: 16px;
    /* Spacing 24 */
    --spacing-24px: 24px;
    /* Spacing 32 */
    --spacing-32px: 32px;
    /* Spacing 20 */
    --spacing-Negative-32px: -32px;
    /* Spacing 40 */
    --spacing-40px: 40px;
    /* Spacing 48 */
    --spacing-48px: 48px;
    /* Spacing 50 */
    --spacing-50px: 50px;
    /* Spacing 59 */
    --spacing-59px: 56px;
    /* Spacing 64 */
    --spacing-64px: 64px;
    /* Spacing 72 */
    --spacing-72px: 72px;
    /* Spacing 80 */
    --spacing-80px: 80px;


    /*------------ GAP ---------------*/
    /* gap 4px */
    --gap-4px: 4px;
    /* gap 8px */
    --gap-8px: 8px;
    /* gap 16px */
    --gap-16px: 16px;
    /* gap 24px */
    --gap-24px: 24px;
    /* gap 40px */
    --gap-40px: 40px;


    /*------------ PADDING ---------------*/
    /* Padding 48px 24px*/
    --padding-48px-24px: 48px 24px;
    /* Padding 48px 24px*/
    --padding-48px-16px: 48px 16px;
    /* Padding button md */
    --padding-button-md: 16px 24px;
    /* Padding button sm */
    --padding-button-sm: 8px 16px;
    /* Padding button lg */
    --padding-button-lg: 16px 32px;
    /* Padding button xm */
    --padding-button-xm: 8px 16px;


    /*------------ BORDER RADIUS ---------------*/
    /* Border radius profile */
    --border-radius-profile: 40px;
    /* Border radius LG */
    --border-radius-lg: 12px;
    /* Border radius MD */
    --border-radius-md: 8px;
    /* Border radius SM */
    --border-radius-sm: 4px;


    /*------------ BOX SHADOW ELEVATION ---------------*/
    /* Cards elevation */
    --cards-elevation: 0px 4px 4px rgba(0,23,47,0.16);
    /* Tooltips elevation */
    --tooltips-elevation: 0px 4px 8px rgba(0,23,47,0.24);
    /* Menu elevation */
    --menus-elevation: 0px 4px 16px rgba(0,23,47,0.28);
    /* Modals elevation */
    --modals-elevation: 0px 4px 20px rgba(0,23,47,0.36);
    /* Alerts elevation */
    --alerts-elevation: 0px 4px 24px rgba(0,23,47,0.4);


    /*------------ LINE HEIGHT ---------------*/
    /* Line Height 130 */
    --line-height-130: 130%;
    /* Line Height 48 */
    --line-height-48px: 48px;
    /* Line Height 17 */
    --line-height-17px: 17px;
    /* Line Height 21 */
    --line-height-21px: 21px;
    /* Line Height 24 */
    --line-height-24px: 24px;


    /*------------ WIDTH ---------------*/
    /* Width 30px */
    --width-30px: 30px;
    /* Width 30.27px */
    --width-30_27px: 30.27px;
    /* Width 250px */
    --width-250px: 250px;
    /* Width 280px */
    --width-280px: 280px;
    /* Width 288px */
    --width-288px: 288px;
    /* Width 450px */
    --width-450px: 450px;
    /* Width 348px */
    --width-348px: 348px;
    /* Width 330px */
    --width-330px: 330px;
    /* Width 388px */
    --width-388px: 388px;
    /* Width 265px */
    --width-265px: 265px;
    /* Width 170px */
    --width-417px: 417px;
    /* Width 45% */
    --width-45: 45%;
    /* Width 100% */
    --width-100: 100%;
    /* Width-465px */
    --width-465px: 465px;
    /* Width-420px */
    --width-420px: 420px;
    /* Width-370px */
    --width-370px: 370px;

    
    /*------------ HEIGHT ---------------*/
    /* Height-50px */
    --height-50px: 50px;
    /* Height-48px */
    --height-48px: 48px;
    /* Height-57px */
    --height-57px: 57px;
    /* Height-22px */
    --height-22px: 22px;
    /* Height-417px */
    --height-417px: 417px;
    /* Height-420px */
    --height-420px: 420px;
    /* Height-690px */
    --height-690px: 690px;
    /* Height-1024px */
    --height-1024px: 1024px;
    /* Height-1190px */
    --height-1190px: 1190px;
    /* Height-100vh */
    --height-100vh: 100vh;

}
`;
