import styled, { css } from "styled-components";
import { devices } from "../../Theme/Devices";
import { NavLink } from "react-router-dom";

export const StepContainerOrg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const SubstepContainerOrg = styled.div`
  /*border: 2px solid black;*/
  /*width: 100%;
  @media ${devices.laptop} {
    width: 50%;
  }*/

  ${(props) =>
    props.substepScroll &&
    css`
      overflow-y: auto;
      height: 70vh;
      @media ${devices.laptop} {
        height: 60vh;
        padding: 0px;
        /*border: 2px solid black;*/
      }
    `
  }

  ${(props) =>
    props.substephead &&
    css`
    width: 100%;
    @media ${devices.laptop} {
      width: 50%;
    }
    `
  }
`;