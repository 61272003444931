import React, { useState } from "react";
import {
  InputPasswordBox,
  InputIconEye,
  Inputs,
  SelectHintSpan,
  SelectHint,
  InputLabel,
  Validation,
  ConditionValid,
  Span,
  InputPasswordContainer,
} from "./InputPasswordStyles";

// icon eye show
import { FaEye } from "react-icons/fa";
// icon eye hide
import { AiFillEyeInvisible } from "react-icons/ai";
// icon hint
import { RiErrorWarningFill } from "react-icons/ri";

// icon hint
import { BsDot } from "react-icons/bs";
import { BsCheckAll } from "react-icons/bs";

export const InputPassword = (props) => {
  const [state, setState] = useState(false);

  const toggleBtn = () => {
    setState((prevState) => !prevState);
  };

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);


  return (
    <InputPasswordContainer>
      <InputLabel>Mot de Passe</InputLabel>
      <InputPasswordBox>
        <Inputs
          placeholder="Entez votre mot de passe"
          type={state ? "text" : "password"}
          name={props.name}
          value={props.value}
          onChange={props.handle}
        />
        <InputIconEye onClick={toggleBtn}>
          {state ? (
            <AiFillEyeInvisible color="#130F26" />
          ) : (
            <FaEye color="#130F26" />
          )}
        </InputIconEye>
      </InputPasswordBox>
      {/* validation tracker */}
   
      <Validation className="tracker-box">
        <div>Votre mot de passe doit contenir:</div>
        <ConditionValid
          className={lengthValidated ? "validated" : "not-validated"}
        >
          {props.lengthValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              Au moins 8 caractères
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              Au moins 8 caractères
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={lowerValidated ? "validated" : "not-validated"}
        >
          {props.lowerValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              Au moins une lettre minuscule (a-z)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              Au moins une lettre minuscule (a-z)
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={upperValidated ? "validated" : "not-validated"}
        >
          {props.upperValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              Au moins une lettre majuscule (A-Z)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              Au moins une lettre majuscule (A-Z)
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={numberValidated ? "validated" : "not-validated"}
        >
          {props.numberValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              Au moins un chiffre (0-9)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              Au moins un chiffre (0-9)
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={specialValidated ? "validated" : "not-validated"}
        >
          {props.specialValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              Au moins un caractère spécial (par exemple !@#$%^&*)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              Au moins un caractère spécial (par exemple !@#$%^&*)
            </Span>
          )}
        </ConditionValid>
      </Validation>
    </InputPasswordContainer>
  );
};

export const InputPasswordNew = () => {
  const [state, setState] = useState(false);

  const toggleBtn = () => {
    setState((prevState) => !prevState);
  };

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const handleChange = (value) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp(`(?=.*[!@#\$%\^&\*])`);
    const length = new RegExp("(?=.{8,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  return (
    <InputPasswordContainer>
      <InputLabel> New Password</InputLabel>
      <InputPasswordBox>
        <Inputs
          placeholder="Enter your password"
          type={state ? "text" : "password"}
          onChange={(e) => handleChange(e.target.value)}
        />
        <InputIconEye onClick={toggleBtn}>
          {state ? (
            <AiFillEyeInvisible color="#130F26" />
          ) : (
            <FaEye color="#130F26" />
          )}
        </InputIconEye>
      </InputPasswordBox>
      {/* validation tracker */}
   
      <Validation className="tracker-box">
        <div>Your password must contain:</div>
        <ConditionValid
          className={lengthValidated ? "validated" : "not-validated"}
        >
          {lengthValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              At least 8 characters
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              At least 8 characters
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={lowerValidated ? "validated" : "not-validated"}
        >
          {lowerValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              At least one lowercase letter (a-z)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              At least one lowercase letter (a-z)
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={upperValidated ? "validated" : "not-validated"}
        >
          {upperValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              At least one uppercase letter (A-Z)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              At least one uppercase letter (A-Z)
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={numberValidated ? "validated" : "not-validated"}
        >
          {numberValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              At least one number (0-9)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              At least one number (0-9)
            </Span>
          )}
        </ConditionValid>
        <ConditionValid
          className={specialValidated ? "validated" : "not-validated"}
        >
          {specialValidated ? (
            <Span conditionCheck className="list-icon green">
              <BsCheckAll color="var(--password-condition-valid)" />
              At least one special character (e.g. !@#$%^&*)
            </Span>
          ) : (
            <Span className="list-icon">
              <BsDot color="var(--brand-500S)" />
              At least one special character (e.g. !@#$%^&*)
            </Span>
          )}
        </ConditionValid>
      </Validation>
    </InputPasswordContainer>
  );
};

export const InputPasswordError = () => {
  const [state, setState] = useState(false);
  const toggleBtn = () => {
    setState((prevState) => !prevState);
  };
  return (
    <InputPasswordContainer>
      <InputLabel>Password</InputLabel>
      <InputPasswordBox>
        <Inputs
          placeholder="Enter text here"
          type={state ? "text" : "password"}
          inputerror
        />
        <InputIconEye onClick={toggleBtn}>
          {state ? (
            <AiFillEyeInvisible color="#130F26" />
          ) : (
            <FaEye color="#130F26" />
          )}
        </InputIconEye>
      </InputPasswordBox>
      <SelectHint>
        <RiErrorWarningFill color="var(--error-color)" />
        <SelectHintSpan inputerrorhint>Incorrect password</SelectHintSpan>
      </SelectHint>
    </InputPasswordContainer>
  );
};

export const InputPasswordHint = (props) => {
  const [state, setState] = useState(false);
  const [password, setPassword] = useState("");

  const toggleBtn = () => {
    setState((prevState) => !prevState);
  };

  /*const handleOnchange = (e) => {
    setPassword(e.target.value);
  };*/
  return (
    <>
      {password !== "" ? (
        <InputPasswordError />
      ) : (
        <InputPasswordContainer>
          <InputLabel>Password</InputLabel>
          <InputPasswordBox>
            <Inputs
              placeholder="Enter your password"
              type={state ? "text" : "password"}
              name="password"
              value={props.password}
              onChange={props.handleOnchange}
            />
            <InputIconEye onClick={toggleBtn}>
              {state ? (
                <AiFillEyeInvisible color="#130F26" />
              ) : (
                <FaEye color="#130F26" />
              )}
            </InputIconEye>
          </InputPasswordBox>
        </InputPasswordContainer>
      )}
    </>
  );
};
